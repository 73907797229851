import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Container, TextField, Alert, Grid, Divider, Autocomplete, Accordion, AccordionSummary, AccordionDetails, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, LinearProgress, Tooltip, InputLabel, FormControl, Select, MenuItem, CircularProgress, Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import PercentageProgressBar from "../common/PercentageProgressBar";

export default function ProjectEditorTemplate(props) {

	const { id, onChange, onBudgetChange, onLogbookHandlerChange, create, update, updateBudget, message, users, handleExtension, expanded, onChangeCheckbox } = props.data

	let { contractPrice, totalProgressLevel, budgetCategoryList, planningAndProgrammingHourlyRate, electricInstallationHourlyRate, otherTaskHourlyRate,
		onsiteTravelCost, materialsPrice, totalCost, profitAmount, materialsCost, costCompensationPercentage, costCompensationAmount, taskItems,
		totalTravelDuration, totalTravelDistance, travelCost, planningAndProgrammingProgressLevel, electricInstallationProgressLevel, otherTaskProgressLevel,
		travelProgressLevel, accommodationProgressLevel, materialsProgressLevel, estimatedPlanningAndProgrammingHours, estimatedElectricInstallationHours,
		estimatedOtherHours, estimatedOnsiteTravelCount, estimatedAccommodationCount, estimatedMaterialsCost, totalPrice, task4HourlyRate, task5HourlyRate,
		task4ProgressLevel, task5ProgressLevel, estimatedTask4Hours, estimatedTask5Hours } = props.data.budget

	let { name, customerName, location, secondaryLocation, companyRegistrationNumber, bankAccountNumber, mkikNumber, phoneNumber, email, representative, phoneNumberOfRepresentative,
		fmv, status, priority, logbookHandler, nujNumber, travelDistance, otherInformation, guarantee, deadline, createdAt, updatedAt, shouldCreateTemplate } = props.data.project

	const navigate = useNavigate();

	if (totalProgressLevel == null || totalProgressLevel <= 0) totalProgressLevel = 100

	const estimatedPlanningAndProgrammingPrice = (estimatedPlanningAndProgrammingHours * planningAndProgrammingHourlyRate)
	const estimatedElectricInstallationPrice = (estimatedElectricInstallationHours * electricInstallationHourlyRate)
	const estimatedOtherPrice = (estimatedOtherHours * otherTaskHourlyRate)
	const estimatedTask4Price = (estimatedTask4Hours * task4HourlyRate)
	const estimatedTask5Price = (estimatedTask5Hours * task5HourlyRate)
	const estimatedOnsiteTravelPrice = (2 * estimatedOnsiteTravelCount * onsiteTravelCost * travelDistance)
	const estimatedAccommodationPrice = (estimatedAccommodationCount * 8000)

	const estimatedTotal = estimatedPlanningAndProgrammingPrice + estimatedElectricInstallationPrice + estimatedOtherPrice + estimatedTask4Price + estimatedTask5Price +
		estimatedOnsiteTravelPrice + estimatedAccommodationPrice + estimatedMaterialsCost

	const valueFormatter = (value) => `${parseInt(value).toLocaleString()} Ft`;

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : create}>
						{(id == null || name !== "") ?
							<Grid container spacing={2} columns={20}>
								{createTextfield("name", "Projekt Neve", name, onChange, true, true, false, "text", 10)}
								{createTextfield("secondaryLocation", "Helyszín", secondaryLocation, onChange, false, false, false, "text", 10)}

								<Grid item lg={20} md={20} xs={20}>
									<Divider />
								</Grid>
								{createTextfield("customerName", "Megrendelő neve", customerName, onChange)}
								{createTextfield("location", "Székhely", location, onChange)}
								{createTextfield("companyRegistrationNumber", "Cégjegyzék Száma", companyRegistrationNumber, onChange)}
								{createTextfield("bankAccountNumber", "Bank Fiók", bankAccountNumber, onChange)}
								{createTextfield("mkikNumber", "MKIK Szám", mkikNumber, onChange)}
								{createTextfield("phoneNumber", "Telefonszám", phoneNumber, onChange)}
								{createTextfield("email", "Email", email, onChange)}
								{createTextfield("representative", "Képviselő", representative, onChange)}
								{createTextfield("phoneNumberOfRepresentative", "Képviselő Telefonszáma", phoneNumberOfRepresentative, onChange)}

								<Grid item lg={5} md={10} xs={20}>
									<FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
										<InputLabel id="status-label">Státusz</InputLabel>
										<Select
											variant="outlined"
											fullWidth
											labelId="status-label"
											name="status"
											label="Státusz"
											value={status}
											onChange={onChange}
											sx={{ textAlign: "left" }}
										>
											<MenuItem value={"UNDER_DISCUSSION"}>Megbeszélés tárgya</MenuItem>
											<MenuItem value={"PLANNING"}>Tervezés alatt</MenuItem>
											<MenuItem value={"UNDER_EXECUTION"}>Kivetelezés alatt</MenuItem>
											<MenuItem value={"OVERDUE"}>Csúszásban</MenuItem>
											<MenuItem value={"MAINTENANCE"}>Karbantartás</MenuItem>
											<MenuItem value={"DONE"}>Befejezve</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item lg={5} md={10} xs={20}>
									<FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
										<InputLabel id="priority-label">Prioritás</InputLabel>
										<Select
											variant="outlined"
											fullWidth
											labelId="priority-label"
											name="priority"
											label="Prioritás"
											value={priority}
											defaultValue={"NORMAL"}
											onChange={onChange}
											sx={{ textAlign: "left" }}
										>
											<MenuItem value={"EXTRA_IMPORTANT"}>Extra fontos</MenuItem>
											<MenuItem value={"IMPORTANT"}>Fontos</MenuItem>
											<MenuItem value={"NORMAL"}>Normál</MenuItem>
											<MenuItem value={"NOT_IMPORTANT"}>Nem fontos</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								{createTextfield("fmv", "FMV", fmv, onChange)}

								<Grid item lg={5} md={10} xs={20}>
									<Autocomplete
										value={logbookHandler}
										margin="normal"
										id="logbookHandler"
										label="logbookHandler"
										name="logbookHandler"
										onChange={(event, newValue) => onLogbookHandlerChange(newValue)}
										options={users}
										getOptionLabel={(logbookHandler) => logbookHandler.name}
										style={{ marginTop: "16px" }}
										renderInput={(params) => (
											<TextField {...params} label="E-napló Felelős" />
										)}
									/>
								</Grid>

								{createTextfield("nujNumber", "NUJ Szám", nujNumber, onChange)}
								{createTextfield("travelDistance", "Távolság (km)", travelDistance, onChange)}
								{createTextfield("deadline", "Határidő", deadline ? deadline : "-1", onChange, false, false, false, "date")}
								{createTextfield("guarantee", "Garancia Lejárta", guarantee ? guarantee : "-1", onChange, false, false, false, "date")}
								{createTextfield("otherInformation", "Egyéb Információ", otherInformation, onChange)}

								<Grid item md={5} xs={20} sx={{ padding: "0px", textAlign: "left" }}>
									<FormControlLabel control={<Checkbox
										variant="outlined"
										margin="normal"
										size="large"
										name="shouldCreateTemplate"
										onChange={onChangeCheckbox}
										checked={shouldCreateTemplate}
										value={shouldCreateTemplate}
									/>} label="Sablon Feladatok Létrehozása" sx={{marginTop: "16px"}}/>
								</Grid>

								{id !== undefined &&
									<>
										<Grid item lg={20} md={20} xs={20}>
											<Divider />
										</Grid>

										{createTextfield("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
										{createTextfield("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
										<Grid item lg={10} xs={20}></Grid>
									</>

								}



								{message &&
									<Grid item lg={20} xs={20}>
										<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
									</Grid>
								}

								<Grid item xs={20}></Grid>


								<Grid item lg={2} md={5} xs={9}>
									<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
										Vissza
									</Button>
								</Grid>

								<Grid item lg={14} md={10} xs={2}></Grid>

								<Grid item lg={2} md={5} xs={9}>
									<Button type="button" fullWidth variant="contained" onClick={(e) => create(e)}>
										Mentés Újként
									</Button>
								</Grid>

								<Grid item lg={2} md={5} xs={9}>
									<Button type="submit" fullWidth variant="contained" color="success">
										Mentés
									</Button>
								</Grid>

							</Grid>
							:
							<CircularProgress sx={{ margin: "300px" }} />
						}
					</form>
				</div>

			</Container>
			{id && <Accordion style={{ marginBottom: "10px", marginTop: "20px" }} disableGutters expanded={expanded} onChange={handleExtension()} >
				<AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
					Pénzügyi Részletek
				</AccordionSummary>
				<AccordionDetails>
					<form id="budget" onSubmit={updateBudget}>
						<Grid container columns={30}>
							<Grid item xs={10}>
								<Grid container columns={2} padding={'20px'} spacing={2}>
									<Grid item xs={2} textAlign={"center"}>
										Projekt Vállalási Költsége
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Össz Vállalási Költség (Ft)", totalPrice, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("contractPrice", "Szerződéses Ár (Ft)", contractPrice, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Programozás és Tervezés Össz Ár (Ft)", budgetCategoryList[0] ? budgetCategoryList[0].price : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("planningAndProgrammingHourlyRate", "Programozás és Tervezés Óradíja (Ft)", planningAndProgrammingHourlyRate, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Villanyszerelés Össz Ár (Ft)", budgetCategoryList[1] ? budgetCategoryList[1].price : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("electricInstallationHourlyRate", "Villanyszerelés Óradíja (Ft)", electricInstallationHourlyRate, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Egyéb Munkadíj Össz Ár (Ft)", budgetCategoryList[4] ? budgetCategoryList[4].price : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("otherTaskHourlyRate", "Egyéb Munka Óradíja (Ft)", otherTaskHourlyRate, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Kategória 4 Össz Ár (Ft)", budgetCategoryList[5] ? budgetCategoryList[5].price : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("task4HourlyRate", "Kategória 4 Óradíja (Ft)", task4HourlyRate, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Kategória 5 Össz Ár (Ft)", budgetCategoryList[6] ? budgetCategoryList[6].price : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("task5HourlyRate", "Kategória 5 Óradíja (Ft)", task5HourlyRate, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Utazás Össz Ár (Ft)", budgetCategoryList[3] ? (budgetCategoryList[3].price + " (" + (budgetCategoryList[3].price / onsiteTravelCost / travelDistance / 2)) + " kiszállás) " : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("onsiteTravelCost", "Kiszállás Költsége (Ft/km)", onsiteTravelCost, onBudgetChange, false, false, false, "number")}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Szállás Össz Ár (Ft)", budgetCategoryList[2] ? budgetCategoryList[2].price : "", onBudgetChange, false, false, false, "text", 5, true)}
										<span id="financial-details"></span>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("materialsPrice", "Anyag Össz Ár (Ft)", materialsPrice, onBudgetChange, false, false, false, "number")}
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={5} sx={{ borderLeft: "solid 1px darkgray" }}>
								<Grid container columns={1} padding={'20px'} spacing={2}>
									<Grid item xs={1} textAlign={"center"}>
										Készültségi Szint (%)
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth color={"rgba(0,255,0,0.4)"} percentage={totalProgressLevel} onChange={undefined} title="Összesített" readonly={true}></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={planningAndProgrammingProgressLevel} onChange={onBudgetChange} title="Programozás és Tervezés" name="planningAndProgrammingProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={electricInstallationProgressLevel} onChange={onBudgetChange} title="Villanyszerelés" name="electricInstallationProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={otherTaskProgressLevel} onChange={onBudgetChange} title="Egyéb" name="otherTaskProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={task4ProgressLevel} onChange={onBudgetChange} title="Kategória 4" name="task4ProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={task5ProgressLevel} onChange={onBudgetChange} title="Kategória 5" name="task5ProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={travelProgressLevel} onChange={onBudgetChange} title="Utazás" name="travelProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={accommodationProgressLevel} onChange={onBudgetChange} title="Szállás" name="accommodationProgressLevel"></PercentageProgressBar>
									</Grid>
									<Grid item xs={1}>
										<PercentageProgressBar fullWidth percentage={materialsProgressLevel} onChange={onBudgetChange} title="Anyag" name="materialsProgressLevel"></PercentageProgressBar>
									</Grid>

								</Grid>
							</Grid>
							<Grid item xs={5} sx={{ borderLeft: "solid 2px darkgray" }}>
								<Grid container columns={1} padding={'20px'} spacing={2}>
									<Grid item xs={1} textAlign={"center"}>
										Adatok
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Nyereség (Ft)", profitAmount, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Programozás és Tervezés Össz Óra", budgetCategoryList[0] ? formatTimeValue(budgetCategoryList[0].duration) : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Villanyszerelés Össz Óra", budgetCategoryList[1] ? formatTimeValue(budgetCategoryList[1].duration) : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Egyéb Össz Óra", budgetCategoryList[4] ? formatTimeValue(budgetCategoryList[4].duration) : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Kategória 4 Össz Óra", budgetCategoryList[5] ? formatTimeValue(budgetCategoryList[5].duration) : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Kategória 5 Össz Óra", budgetCategoryList[6] ? formatTimeValue(budgetCategoryList[6].duration) : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Vezető Össz Idő", formatTimeValue(totalTravelDuration), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Utas Idő", budgetCategoryList[3] ? formatTimeValue(budgetCategoryList[3].duration - totalTravelDuration) : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Megtett Távolság ", totalTravelDistance + " km", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

								</Grid>
							</Grid>
							<Grid item xs={10} sx={{ borderLeft: "solid 2px darkgray" }}>
								<Grid container columns={2} padding={'20px'} spacing={2}>
									<Grid item xs={2} textAlign={"center"}>
										Projekt Önköltsége
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Teljes Költség (Ft)", totalCost, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Programozás és Tervezés Össz Költség (Ft)", budgetCategoryList[0] ? budgetCategoryList[0].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Villanyszerelés Össz Költség (Ft)", budgetCategoryList[1] ? budgetCategoryList[1].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Egyéb Munkadíj Össz Költség (Ft)", budgetCategoryList[4] ? budgetCategoryList[4].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Kategória 4 Össz Költség (Ft)", budgetCategoryList[5] ? budgetCategoryList[5].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Kategória 5 Össz Költség (Ft)", budgetCategoryList[6] ? budgetCategoryList[6].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Utazás Munkadíj Költség (Ft)", budgetCategoryList[3] ? budgetCategoryList[3].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Utazás Kilométer Költség (Ft)", travelCost, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Szállás Össz Költség (Ft)", budgetCategoryList[2] ? budgetCategoryList[2].cost : "", onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("costCompensationPercentage", "Kompenzáció Aránya (%)", costCompensationPercentage, onBudgetChange)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("materialsCost", "Össz Anyagköltség (Ft)", materialsCost, onBudgetChange)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("costCompensationAmount", "Kompenzáció Mértéke (Ft)", costCompensationAmount, onBudgetChange)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={15}>
								<Grid container columns={3} padding={'20px'} spacing={2}>
									<Grid item xs={3} textAlign={"center"}>
										Projekt Ár Becslése
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedPlanningAndProgrammingHours", "Programozás és Tervezés (óra)", estimatedPlanningAndProgrammingHours, onBudgetChange)}
									</Grid>
									<Grid item xs={1} >
										{createTextfield("", "Óradíj (Ft)", planningAndProgrammingHourlyRate, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedPlanningAndProgrammingPrice, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedElectricInstallationHours", "Villanyszerelés (óra)", estimatedElectricInstallationHours, onBudgetChange)}
									</Grid>
									<Grid item xs={1} >
										{createTextfield("", "Óradíj (Ft)", electricInstallationHourlyRate, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedElectricInstallationPrice, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedOtherHours", "Egyéb (óra)", estimatedOtherHours, onBudgetChange)}
									</Grid>
									<Grid item xs={1} >
										{createTextfield("", "Óradíj (Ft)", otherTaskHourlyRate, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedOtherPrice, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedTask4Hours", "Kategória 4 (óra)", estimatedTask4Hours, onBudgetChange)}
									</Grid>
									<Grid item xs={1} >
										{createTextfield("", "Óradíj (Ft)", task4HourlyRate, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedTask4Price, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedTask5Hours", "Kategória 5 (óra)", estimatedTask5Hours, onBudgetChange)}
									</Grid>
									<Grid item xs={1} >
										{createTextfield("", "Óradíj (Ft)", task5HourlyRate, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedTask5Price, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedOnsiteTravelCount", "Kiszállás (alkalom)", estimatedOnsiteTravelCount, onBudgetChange)}
									</Grid>
									<Grid item xs={1} >
										{createTextfield("", "Oda-Vissza (Ft)", 2 * onsiteTravelCost * travelDistance, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedOnsiteTravelPrice, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedAccommodationCount", "Szállás (Ft)", estimatedAccommodationCount, onBudgetChange)}
									</Grid>
									<Grid item xs={1} ></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", estimatedAccommodationPrice, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("estimatedMaterialsCost", "Anyag (Ft)", estimatedMaterialsCost, onBudgetChange)}
									</Grid>
									<Grid item xs={1} ></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Ár (Ft)", parseInt(estimatedMaterialsCost), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>

									<Grid item xs={1}>
										{createTextfield("", "Össz (óra)", (parseInt(estimatedPlanningAndProgrammingHours) + parseInt(estimatedElectricInstallationHours) + parseInt(estimatedOtherHours)) || 0, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1} ></Grid>
									<Grid item xs={1}>
										{createTextfield("", "Becsült Teljes Ár (Ft)", estimatedTotal, onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={15} sx={{ borderLeft: "solid 2px darkgray" }}>
								<Grid container columns={3} padding={'20px'} spacing={2} height="100%">
									<Grid item xs={3}>
										Előrevetített Árak 100%-os Készültségi Szintig
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Vállalási Ár (Ft)", "" + parseInt((100 / totalProgressLevel) * totalPrice).toLocaleString(), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Önköltség (Ft)", "" + parseInt((100 / totalProgressLevel) * totalCost).toLocaleString(), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Becsült Ár (Ft)", "" + parseInt(estimatedTotal).toLocaleString(), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={3}  >
										<Tooltip title={"Arányok a szerződéses árhoz viszonyítva (" + contractPrice.toLocaleString() + " Ft)"} arrow >
											<span>
												<ProgressBarWithLines percentage={totalProgressLevel} values={[
													{ value: (100 / totalProgressLevel) * totalPrice / contractPrice * 100, label: "Vállalt", tooltip: "" },
													{ value: estimatedTotal / contractPrice * 100, label: "Becsült" },
													{ value: (100 / totalProgressLevel) * totalCost / contractPrice * 100, label: "Önköltség" },
													{ value: totalProgressLevel, label: "Készültség" }
												]} />
											</span>
										</Tooltip>
									</Grid>
									<Grid item xs={3}>
										Várható Nyereség
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Vállalási Árhoz képest (Ft)", "" + parseInt(contractPrice - (100 / totalProgressLevel) * totalPrice).toLocaleString(), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Önköltséghez képest (Ft)", "" + parseInt(contractPrice - (100 / totalProgressLevel) * totalCost).toLocaleString(), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={1}>
										{createTextfield("", "Becsülthöz képest (Ft)", "" + parseInt(contractPrice - estimatedTotal).toLocaleString(), onBudgetChange, false, false, false, "text", 5, true)}
									</Grid>
									<Grid item xs={3}>
										<BarChart
											dataset={[
												{
													group: "programming",
													self: budgetCategoryList[0].cost,
													selfExpected: budgetCategoryList[0].cost * (100 / (planningAndProgrammingProgressLevel === 0 ? 100 : planningAndProgrammingProgressLevel) - 1),
													contract: budgetCategoryList[0].price,
													contractExpected: budgetCategoryList[0].price * (100 / (planningAndProgrammingProgressLevel === 0 ? 100 : planningAndProgrammingProgressLevel) - 1),
													estimated: estimatedPlanningAndProgrammingPrice
												},
												{
													group: "electrical",
													self: budgetCategoryList[1].cost,
													selfExpected: budgetCategoryList[1].cost * (100 / (electricInstallationProgressLevel === 0 ? 100 : electricInstallationProgressLevel) - 1),
													contract: budgetCategoryList[1].price,
													contractExpected: budgetCategoryList[1].price * (100 / (electricInstallationProgressLevel === 0 ? 100 : electricInstallationProgressLevel) - 1),
													estimated: estimatedElectricInstallationPrice
												},
												{
													group: "other",
													self: budgetCategoryList[4].cost,
													selfExpected: budgetCategoryList[4].cost * (100 / (otherTaskProgressLevel === 0 ? 100 : otherTaskProgressLevel) - 1),
													contract: budgetCategoryList[4].price,
													contractExpected: budgetCategoryList[4].price * (100 / (otherTaskProgressLevel === 0 ? 100 : otherTaskProgressLevel) - 1),
													estimated: estimatedOtherPrice
												},
												{
													group: "category4",
													self: budgetCategoryList[5].cost,
													selfExpected: budgetCategoryList[5].cost * (100 / (task4ProgressLevel === 0 ? 100 : task4ProgressLevel) - 1),
													contract: budgetCategoryList[5].price,
													contractExpected: budgetCategoryList[5].price * (100 / (task4ProgressLevel === 0 ? 100 : task4ProgressLevel) - 1),
													estimated: estimatedTask4Price
												},
												{
													group: "category5",
													self: budgetCategoryList[6].cost,
													selfExpected: budgetCategoryList[6].cost * (100 / (task5ProgressLevel === 0 ? 100 : task5ProgressLevel) - 1),
													contract: budgetCategoryList[6].price,
													contractExpected: budgetCategoryList[6].price * (100 / (task5ProgressLevel === 0 ? 100 : task5ProgressLevel) - 1),
													estimated: estimatedTask5Price
												},
												{
													group: "travel",
													self: budgetCategoryList[3].cost,
													selfExpected: budgetCategoryList[3].cost * (100 / (travelProgressLevel === 0 ? 100 : travelProgressLevel) - 1),
													contract: budgetCategoryList[3].price,
													contractExpected: budgetCategoryList[3].price * (100 / (travelProgressLevel === 0 ? 100 : travelProgressLevel) - 1),
													estimated: estimatedOnsiteTravelPrice
												},
												{
													group: "accommodation",
													self: budgetCategoryList[2].cost,
													selfExpected: budgetCategoryList[2].cost * (100 / (accommodationProgressLevel === 0 ? 100 : accommodationProgressLevel) - 1),
													contract: budgetCategoryList[2].price,
													contractExpected: budgetCategoryList[2].price * (100 / (accommodationProgressLevel === 0 ? 100 : accommodationProgressLevel) - 1),
													estimated: estimatedAccommodationPrice
												},
												{
													group: "materials",
													self: materialsCost,
													selfExpected: materialsCost * (100 / (materialsProgressLevel === 0 ? 100 : materialsProgressLevel) - 1),
													contract: materialsPrice,
													contractExpected: materialsPrice * (100 / (materialsProgressLevel === 0 ? 100 : materialsProgressLevel) - 1),
													estimated: estimatedMaterialsCost
												}

											]}
											series={[
												{ dataKey: "self", label: "Önköltség", valueFormatter, color: "#d32f2f", stack: 'A' },
												{ dataKey: "selfExpected", label: "Még Várható Önköltség", valueFormatter, color: "#E99797", stack: 'A' },
												{ dataKey: "contract", label: "Vállalt", valueFormatter, color: "#2e7d32", stack: 'B' },
												{ dataKey: "contractExpected", label: "Még Várható Vállalási Költség", valueFormatter, color: "#96BE98", stack: 'B' },
												{ dataKey: "estimated", label: "Becsült", valueFormatter, color: "#1976d2" },
											]}
											xAxis={[{ data: ['Programozás', 'Villanysz.', 'Egyéb', 'Kategória 4', 'Kategória 5', 'Utazás', 'Szállás', 'Anyagköltség'], scaleType: 'band', dataKey: "group" }]}
											yAxis={[{ label: "Millió Ft", position: "right", valueFormatter: (value) => value / 1000000 }]}
											margin={{ top: 50, bottom: 30, left: 50, right: 10 }}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={28}></Grid>
							<Grid item>
								<Button type="submit" fullWidth variant="contained" color="success">
									Mentés
								</Button>
							</Grid>
						</Grid>
					</form>
					<TableContainer sx={{ marginTop: "8px" }}>
						<Table stickyHeader aria-label="sticky table" >
							<TableHead>
								<TableRow>
									<TableCell style={{ fontWeight: 700, background: "darkgray" }}>
										<>Dolgozó</>
									</TableCell>
									<TableCell style={{ fontWeight: 700, background: "darkgray" }}>
										<>Feladat</>
									</TableCell>
									<TableCell style={{ fontWeight: 700, background: "darkgray" }}>
										<>Dátum</>
									</TableCell>
									<TableCell style={{ fontWeight: 700, background: "darkgray" }}>
										<>Idő</>
									</TableCell>
									<TableCell style={{ fontWeight: 700, background: "darkgray" }}>
										<>Költség</>
									</TableCell>
									<TableCell style={{ fontWeight: 700, background: "darkgray" }}>
										<>Ár</>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{taskItems.map(item => {
									return (<>
										<TableRow>
											<TableCell>{item.username}</TableCell>
											<TableCell>{item.taskTypeName}</TableCell>
											<TableCell>{item.date}</TableCell>
											<TableCell>{formatTime(item.duration)}</TableCell>
											<TableCell>{item.cost.toLocaleString()} Ft</TableCell>
											<TableCell>{item.price.toLocaleString()} Ft</TableCell>
										</TableRow>
									</>)
								})}
							</TableBody>
						</Table>
					</TableContainer>

				</AccordionDetails>
			</Accordion>}
		</div>
	)
}

function createTextfield(name, label, value, onChange, required = false, autoFocus = false, disabled = false, type = "text", lg_width = 5, readonly = false) {
	return (<Grid item lg={lg_width} md={10} xs={20}>
		<TextField
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={label}
			name={name}
			type={type}
			autoFocus={autoFocus}
			autoComplete="off"
			onChange={onChange}
			value={type === "text" ? (value === undefined || value === null ? "" : value.toLocaleString()) : value}
			disabled={disabled}
			InputProps={readonly && { style: { pointerEvents: 'none', backgroundColor: "Gainsboro" } }}
			inputProps={{
				style: {
					WebkitAppearance: 'none',
					MozAppearance: 'textfield',
					appearance: 'textfield'
				}
			}}
		> </TextField>
	</Grid>)
}

function formatTime(seconds) {
	if (seconds === 0) return '-'
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);

	const formattedTime = (
		<span>
			{hours > 0 && <>
				<span>{hours}</span>{' óra '}
			</>}
			{minutes > 0 && (
				<span>{minutes}</span>
			)}
			{minutes > 0 && ' perc'}
		</span>
	);
	return formattedTime;
}

function formatTimeValue(seconds) {
	if (seconds === 0) return '-'
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	return hours + " óra " + minutes + " perc";
}

function ProgressBarWithLines({ percentage, values }) {
	values.sort((a, b) => a.value - b.value);
	return (
		<div style={{ position: 'relative', height: '56px', marginTop: "16px", marginBottom: "16px" }}>
			<LinearProgress variant="determinate" value={percentage} sx={{ height: '100%', borderRadius: "4px" }} color="success" />
			{values.map((value, index) => (
				<div
					key={index}
					style={{
						position: 'absolute',
						height: 'calc(100% - 6px)',
						width: `${value.value >= 100 || value.value <= 0 ? '0px' : '2px'}`,
						backgroundColor: 'white',
						color: `${value.value > 100 ? 'red' : 'black'}`,
						left: `${value.value <= 0 ? 6 : (value.value >= 100 ? 94 : value.value)}%`,
						top: 3,
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: index % 2 === 0 ? '100%' : '-75%',
							left: '-100px',
							fontSize: '16px',
							fontStyle: "italic",
							textAlign: 'center',
							width: '200px',
							paddingTop: "5px"
						}}
					>
						{isNaN(value.value) ? "" : value.label + " (" + parseInt(value.value) + "%)"}
					</div>
				</div>
			))}
		</div>
	);
}
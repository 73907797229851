import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleInvoiceEditorTemplate from './SimpleInvoiceEditorTemplate';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';
import simpleInvoiceService from '../../services/SimpleInvoiceService';
import authService from '../../services/AuthService';
import axios from 'axios';
import { API_URL } from '../../App';
import { saveAs } from 'file-saver';
import userService from '../../services/UserService';
import projectService from '../../services/ProjectService';
import vehicleService from '../../services/VehicleService';
import simpleInvoicePaymentPartService from '../../services/SimpleInvoicePaymentPartService';
import { useConfirmationDialog } from '../../util/ConfirmationDialogProvider';

const SimpleInvoiceEditor = () => {

    const urlParts = window.location.pathname.split('/')
    const id = urlParts[3] === 'create' ? undefined : urlParts[3]

    useTitle([
        { name: 'Számla Lista', url: '/dashboard/simple-invoices' },
        { name: 'Számla', url: '/dashboard/simple-invoices/' + (id || 'create') },
    ])

    const [simpleInvoice, setSimpleInvoice] = useState({
        invoiceType: 'INVOICE',
        paymentType: 'TRANSFER',
        vat: '27',
        category: 'GENERAL'
    });
    const { sendAlertMessage } = useSnackbar()
    const { openConfirmationDialog } = useConfirmationDialog()
    const [waitingForServer, setWaitingForServer] = useState(false)
    const [message, setMessage] = useState('')
    const [files, setFiles] = useState([]);
    const [senderOptions, setSenderOptions] = useState([])
    const [receiverOptions, setReceiverOptions] = useState([])
    const [currentCompany, setCurrentCompany] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        retrieveSimpleInvoice()
    }, [id]);

    const retrieveSimpleInvoice = () => {
        if (id) {
            simpleInvoiceService.findById(id, (response, error) => {
                if (response) {
                    setSimpleInvoice((prev) => ({
                        ...prev,
                        ...response,
                        vat: response.vat?.toLocaleString()
                    }));
                } else sendAlertMessage('Számla lekérése sikertelen', 'error')
            })
        }
    }

    const [users, setUsers] = React.useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else console.log(error);
            },
        );
    }, []);

    const [projects, setProjects] = useState([]);
    useEffect(() => {
        projectService.getSelectors(
            (response, error) => {
                if (response) setProjects(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    const [vehicles, setVehicles] = useState([]);
    useEffect(() => {
        vehicleService.search(
            (response, error) => {
                if (response) setVehicles(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    useEffect(() => {
        simpleInvoiceService.getSenderOptions((response, error) => {
            if (response) {
                setSenderOptions(response);
                setCurrentCompany(response.find(s => s.name === 'Fay Automatika Kft.'))
            } else sendAlertMessage('Feladók listájának lekérése sikertelen', 'error')
        })
    }, []);

    useEffect(() => {
        simpleInvoiceService.getReceiverOptions((response, error) => {
            if (response) {
                setReceiverOptions(response);
            } else sendAlertMessage('Címzettek listájának lekérése sikertelen', 'error')
        })
    }, []);

    const onChange = (event) => {
        let { name, value } = event.target;
        setSimpleInvoice((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onTransactionTypeChange = (event) => {
        let { value } = event.target;
        if (value === 'EXPENSE')
            setSimpleInvoice((prev) => ({
                ...prev,
                transactionType: value,
                receiver: currentCompany.name,
                receiverTaxNumber: currentCompany?.taxNumber,
                sender: null,
                senderAddress: null,
                senderBankAccountNumber: null,
                senderTaxNumber: null
            }));
        else setSimpleInvoice((prev) => ({
            ...prev,
            transactionType: value,
            sender: currentCompany.name,
            senderAddress: currentCompany?.address,
            senderBankAccountNumber: currentCompany?.bankAccountNumber,
            senderTaxNumber: currentCompany?.taxNumber,
            receiver: null,
            receiverTaxNumber: null
        }));
    };

    const onSelectChange = (value, name) => {
        setSimpleInvoice((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onChangeCheckbox = (event) => {
        const { checked, name } = event.target;
        setSimpleInvoice((prev) => ({
            ...prev,
            [name]: checked
        }));
    };

    const onSenderChange = (event, newValue) => {
        const sender = senderOptions.find(s => s.name === newValue)
        setSimpleInvoice((prev) => ({
            ...prev,
            sender: newValue,
            senderAddress: sender?.address,
            senderBankAccountNumber: sender?.bankAccountNumber,
            senderTaxNumber: sender?.taxNumber
        }));
    }

    const onReceiverChange = (event, newValue) => {
        const receiver = receiverOptions.find(s => s.name === newValue)
        setSimpleInvoice((prev) => ({
            ...prev,
            receiver: newValue,
            receiverTaxNumber: receiver?.taxNumber
        }));
    }

    const onPaidByChange = (event, newValue) => {
        setSimpleInvoice((prev) => ({
            ...prev,
            paidBy: newValue
        }));
    }

    const create = async (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        const formData = new FormData();
        formData.append('request', new Blob([JSON.stringify(prepareRequest())], { type: "application/json" }));
        files.forEach((file) => {
            formData.append('files', file);
        });

        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        try {
            const response = await axios.post(API_URL + '/simple-invoices', formData, { headers: headers });
            if (response) {
                navigate(-1);
                sendAlertMessage('Számla sikeresen hozzáadva')
            }
        } catch (error) {
            sendAlertMessage('Számla létrehozása sikertelen', 'error')
            setMessage(error === undefined ? 'Szerver nem elérhető' : error)
        }
        setWaitingForServer(false)
    };

    const update = async (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        const formData = new FormData();
        formData.append('request', new Blob([JSON.stringify(prepareRequest())], { type: "application/json" }));
        files.forEach((file) => {
            formData.append('files', file);
        });

        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        try {
            const response = await axios.patch(API_URL + '/simple-invoices/' + id, formData, { headers: headers });
            if (response) {
                navigate(-1);
                sendAlertMessage('Számla sikeresen mentve')
            }
        } catch (error) {
            sendAlertMessage('Számla mentése sikertelen', 'error')
            setMessage(error === undefined ? 'Szerver nem elérhető' : error)
        }
        setWaitingForServer(false)
    };

    const prepareRequest = () => {
        const copy = structuredClone(simpleInvoice)
        copy.paidBy = simpleInvoice.paidBy?.id
        copy.projectId = simpleInvoice.project?.id
        copy.vehicleId = simpleInvoice.vehicle?.id
        delete copy.project
        delete copy.vehicle
        return copy
    }

    const download = async (fileName) => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/simple-invoices/" + id + "/document",
            params: { fileName: fileName },
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const blob = new Blob([response.data], { type: contentType });
                const url = window.URL.createObjectURL(blob);
                console.log(contentType)

                if (contentType === 'application/pdf' || contentType.startsWith('image/')) {
                    window.open(url)
                } else {
                    saveAs(blob, fileName);
                    sendAlertMessage('Fájl sikeresen letöltve')
                }
            })
            .catch(error => {
                console.error(error)
                sendAlertMessage('Fájl letöltése sikertelen', 'error')
            });
    }

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const removeDocumentName = (nameToRemove) => {
        setSimpleInvoice((prev) => ({
            ...prev,
            documentNames: prev.documentNames.filter(name => name !== nameToRemove)
        }));
    };

    return (
        <SimpleInvoiceEditorTemplate
            {...{
                id, simpleInvoice, message, onChange, create, update, navigate, waitingForServer, files, handleFileChange, handleRemoveFile,
                removeDocumentName, download, senderOptions, receiverOptions, onSenderChange, onReceiverChange, users, onChangeCheckbox, onPaidByChange,
                onSelectChange, vehicles, projects, onTransactionTypeChange, retrieveSimpleInvoice, sendAlertMessage, openConfirmationDialog
            }}
        />
    );
};

export default SimpleInvoiceEditor;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Button } from '@mui/material';

const ConfirmationDialog = ({ title, content, open, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="false">
            <DialogTitle sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', bgcolor: '#c62828;', color: '#fff' }}>
                <Typography variant="h6">{title}</Typography>
            </DialogTitle>
            <DialogContent sx={{ bgcolor: '#ededed' }}>
                <DialogContentText sx={{ mb: 1, paddingTop: "15px", color: "black" }}>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 2, bgcolor: '#ededed', justifyContent: 'space-between' }}>
                <Button
                    onClick={() => {
                        onClose();
                        onConfirm();
                    }}
                    color="error"
                    variant="contained"
                >
                    {"Igen"}
                </Button>
                <Button
                    onClick={onClose}
                    color="error"
                    variant="outlined"
                    autoFocus
                >
                    Mégse
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;

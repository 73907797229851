import Service from "./Service"

class SimpleInvoicePaymentPartService extends Service {

    findById = (id, callback) => this.get("/simple-invoices/payment-parts/" + id, callback)

    findAllBySimpleInvoice = (simpleInvoiceId, callback) => this.get("/simple-invoices/" + simpleInvoiceId + "/payment-parts", callback)

    createPaymentPart = (simpleInvoiceId, paymentPartRequest, callback) => this.post("/simple-invoices/" + simpleInvoiceId + "/payment-parts", paymentPartRequest, callback)

    updatePaymentPart = (paymentPartId, paymentPartRequest, callback) => this.patch("/simple-invoices/payment-parts/" + paymentPartId, paymentPartRequest, callback)

    deletePaymentPart = (id, callback) => this.del("/simple-invoices/payment-parts/" + id, callback)
}
const simpleInvoicePaymentPartService = new SimpleInvoicePaymentPartService()
export default simpleInvoicePaymentPartService
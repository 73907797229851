import Service from "./Service"

class ProjectTaskService extends Service {

    findById = (id, nonFinishedOnly, callback) => this.getWithParams("/project-tasks/" + id, { nonFinishedOnly }, callback)

    search = (projectId, searchParams, callback) => this.getWithParams("/projects/" + projectId + "/project-tasks", searchParams, callback)

    createProjectTask = (projectId, projectTaskRequest, callback) => this.post("/projects/" + projectId + "/project-tasks", projectTaskRequest, callback)

    copyProjectTask = (copyRequest, callback) => this.put("/project-tasks", copyRequest, callback)

    updateProjectTask = (projectTaskId, projectTaskRequest, callback) => this.patch("/project-tasks/" + projectTaskId, projectTaskRequest, callback)

    deleteProjectTask = (projectTaskId, callback) => this.del("/project-tasks/" + projectTaskId, callback)
}
const projectTaskService = new ProjectTaskService()
export default projectTaskService
import React, { useState, useEffect } from 'react';
import TaskTypeEditorTemplate from './TaskTypeEditorTemplate';
import taskTypeService from '../../services/TaskTypeService';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../dashboard/TitleContext';

const TaskTypeEditor = () => {

  const id = window.location.pathname.split('/')[3] === 'create' ? undefined : window.location.pathname.split('/')[3]

  useTitle([
    { name: 'Feladatkör Lista', url: '/dashboard/task-types' },
    { name: 'Feladatkör', url: '/dashboard/task-types/' + (id || 'create') }
  ])

  const [tasktype, setTaskType] = useState({
    name: "",
    paymentModifier: "",
    paymentCategory: "",
    createdAt: "",
    updatedAt: ""
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      taskTypeService.findById(
        id,
        (response, error) => {
          if (response) {
            console.log(response)
            setTaskType((prevTaskType) => ({
              ...prevTaskType,
              ...response,
            }));
          } else {
            console.log(error);
          }
        },
      );
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setTaskType((prevTaskType) => ({
      ...prevTaskType,
      [name]: value,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    taskTypeService.createTaskType(tasktype, (response, error) => {
      if (!error) {
        navigate('/dashboard/task-types');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    taskTypeService.updateTaskType(id, tasktype, (response, error) => {
      if (!error) {
        navigate('/dashboard/task-types');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <TaskTypeEditorTemplate
      data={{
        id,
        tasktype,
        message,
        onChange,
        create,
        update,
      }}
    />
  );
};

export default TaskTypeEditor;

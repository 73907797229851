import { Button, Container, TextField, Alert, Grid, Divider, Autocomplete, CircularProgress } from "@mui/material"
import React from "react"

export default function ProductEditorTemplate(props) {

    const { id, update, message, productItem, onChangeSelectItem, waitingForServer, navigate } = props
    const { product = {}, subStorage = {} } = props.productItem

    const createInputField = ({ name, label, required = false, autoFocus = false, disabled = false, value, type = "text" }) =>
        <>
            <Grid item lg={5} md={10} xs={20}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required={required}
                    fullWidth
                    id={name}
                    label={label}
                    name={name}
                    type={type}
                    autoFocus={autoFocus}
                    autoComplete="off"
                    onChange={props.onChange}
                    value={value || (type === 'date' && props.productItem[name] == null ? -1 : props.productItem[name]) || ""}
                    disabled={disabled}
                />
            </Grid>
        </>

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={update}>
                        <Grid container spacing={2} columns={20}>
                            {id != null && props.productItem?.product?.name == null ? <Grid item xs={20}><CircularProgress sx={{ margin: "100px" }} /> </Grid> :
                                <>
                                    {createInputField({
                                        name: "productName",
                                        label: "Termék Neve",
                                        value: product.name,
                                        disabled: true
                                    })}
                                    {createInputField({
                                        name: "productManufacturer",
                                        label: "Termék Gyártója",
                                        value: product.manufacturer,
                                        disabled: true
                                    })}
                                    {createInputField({
                                        name: "subStorageName",
                                        label: "Alraktár",
                                        value: subStorage.name,
                                        disabled: true
                                    })}
                                    {createInputField({
                                        name: "amount",
                                        label: "Mennyiség",
                                        value: productItem.amount,
                                        disabled: true
                                    })}

                                    <Grid item xs={20}>
                                        <Divider></Divider>
                                    </Grid>

                                    {createInputField({
                                        name: "purchasePrice",
                                        label: "Vásárlási Ár"
                                    })}

                                    {createInputField({
                                        name: "sellingPrice",
                                        label: "Eladási Ár"
                                    })}


                                    <Grid item md={5} xs={20} sx={{ padding: "0px" }}>
                                        <Autocomplete
                                            value={productItem.vat || ""}
                                            margin="normal"
                                            id="vat"
                                            label="ÁFA"
                                            name="vat"
                                            freeSolo
                                            autoSelect
                                            onChange={(event, newValue) => onChangeSelectItem(newValue, "vat")}
                                            options={["27%", "5%", "AAM"]}
                                            sx={{ marginTop: "16px" }}
                                            getOptionLabel={(vat) => vat}
                                            renderInput={(params) => (
                                                <TextField {...params} label="ÁFA" />
                                            )}
                                        />
                                    </Grid>

                                    {createInputField({ name: "minimumStockInStorage", label: "Raktárankénti Minimum Készlet" })}
                                    {createInputField({ name: "packageManagement", label: "Kiszerelés kezelése" })}
                                    {createInputField({ name: "guarantee", label: "Garancia", type: "date" })}
                                    {createInputField({ name: "expiryDate", label: "Szavatosság Lejárta", type: "date" })}
                                    {createInputField({ name: "factoryNumber", label: "Egyedi Gyári Szám" })}

                                    {id !== undefined &&
                                        <>
                                            <Grid item lg={20} md={20} xs={20}>
                                                <Divider />
                                            </Grid>
                                            {createInputField({ name: "createdAt", label: "Létrehozva", disabled: true })}
                                            {createInputField({ name: "updatedAt", label: "Módosítva", disabled: true })}
                                            <Grid item lg={10} xs={20}></Grid>
                                        </>

                                    }

                                    {message &&
                                        <Grid item lg={20} xs={20}>
                                            <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                        </Grid>
                                    }

                                    <Grid item lg={2} md={5} xs={9}>
                                        <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                            Vissza
                                        </Button>
                                    </Grid>

                                    <Grid item lg={16} md={10} xs={2}></Grid>

                                    <Grid item lg={2} md={5} xs={9}>
                                        {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                            Mentés
                                        </Button>}
                                    </Grid>
                                </>}
                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    )
}
import Service from "./Service"

class VehicleService extends Service {

    findById = (id, callback) => this.get("/vehicles/" + id, callback)

    getInvoices = (id, callback) => this.get("/vehicles/" + id + "/invoices", callback)

    search = (callback) => this.get("/vehicles", callback)

    createVehicle = (vehicleRequest, callback) => this.post("/vehicles", vehicleRequest, callback)

    updateVehicle = (vehicleId, vehicleRequest, callback) => this.patch("/vehicles/" + vehicleId, vehicleRequest, callback)

    deleteVehicle = (vehicleId, callback) => this.del("/vehicles/" + vehicleId, callback)
}
const vehicleService = new VehicleService()
export default vehicleService
import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {

    const [snackbar, setSnackbar] = useState({
        open: false,
        feedbackMessage: '',
        alertType: 'success',
    });

    const sendAlertMessage = (text, type = 'success') => {
        setSnackbar({ open: true, feedbackMessage: text, alertType: type });
    };

    const handleClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    return (
        <SnackbarContext.Provider value={{ sendAlertMessage }}>
            {children}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{marginTop: "50px"}}
            >
                <Alert
                    elevation={10}
                    severity={snackbar.alertType}
                    sx={{ width: '100%' }}
                    variant='filled'
                >
                    {snackbar.feedbackMessage}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

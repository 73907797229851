import React, { createContext, useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmationDialogContext = createContext();

export const useConfirmationDialog = () => useContext(ConfirmationDialogContext);

export const ConfirmationDialogProvider = ({ children }) => {
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false,
        title: '',
        content: null,
        onConfirm: () => { },
    });

    const openConfirmationDialog = (title, content, onConfirm) => {
        setConfirmationDialog({
            open: true,
            title,
            content,
            onConfirm,
        });
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialog((prev) => ({ ...prev, open: false }));
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openConfirmationDialog }}>
            {children}

            <Dialog open={confirmationDialog.open} onClose={closeConfirmationDialog} maxWidth="false">
                <DialogTitle sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', bgcolor: '#c62828;', color: '#fff' }}>
                    <Typography sx={{fontSize: "20px"}}>{confirmationDialog.title}</Typography>
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#ededed' }}>
                    <DialogContentText sx={{ mb: 1, paddingTop: "15px", color: "black" }}>
                        {confirmationDialog.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2, bgcolor: '#ededed', justifyContent: 'space-between' }}>
                    <Button
                        onClick={() => {
                            closeConfirmationDialog();
                            confirmationDialog.onConfirm();
                        }}
                        color="error"
                        variant="contained"
                    >
                        Igen
                    </Button>
                    <Button
                        onClick={closeConfirmationDialog}
                        color="error"
                        variant="outlined"
                        autoFocus
                    >
                        Mégse
                    </Button>
                </DialogActions>
            </Dialog>
        </ConfirmationDialogContext.Provider>
    );
};

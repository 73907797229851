import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import globalPaymentModifierService from '../../services/GlobalPaymentModifierService';
import { useTitle } from '../dashboard/TitleContext';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';

const columns = [
  { id: 'label', label: 'Megnevezés' },
  { id: 'value', label: 'Érték' }
];

const LOCAL_STORAGE_ITEM_NAME = "global-payment-modifiers-rows-per-page"

export default function GlobalPaymentModifierTable() {

  useTitle([{ name: 'Globális Fizetési Értékek' }])

  const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })

  useEffect(() => {
    globalPaymentModifierService.findAll(buildQueryParams(), (response, error) => {
      if (response) {
        setTableData((prev) => ({ ...prev, totalCount: response.totalElements, rows: response.content }))
      } else console.log(error)
    })
  }, [tableData.page, tableData.rowsPerPage]);

  function buildQueryParams() {
    return {
      page: tableData.page,
      size: tableData.rowsPerPage
    }
  }

  const navigate = useNavigate();

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                tableData.rows.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/global-payment-modifiers/" + row.id)} sx={{ cursor: "pointer", padding: "10px" }}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25, 50]}
          component="div"
          count={tableData.totalCount}
          rowsPerPage={parseInt(tableData.rowsPerPage)}
          page={tableData.page}
          onPageChange={(event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))}
          onRowsPerPageChange={(event) => {
            setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
            localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
          }}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}

import Service from "./Service"

class PayrollService extends Service {

    getPayrolls = (queryParams, year, month, callback) => this.getWithParams("/payrolls/year/" + year + "/month/" + month, queryParams, callback)

    getPayrollOfUser = (userId, year, month, callback) => this.get("/payrolls/" + userId + "/year/" + year + "/month/" + month, callback)

    getSalaryDetails = (userId, year, month, callback) => this.get("/salary-details?userId=" + userId + "&year=" + year + "&month=" + month, callback)

    getUnpaidSalariesCount = (callback) => this.get("/salary-details/unpaid", callback)

    saveSalaryDetails = (salaryDetails, callback) => this.post("/salary-details", salaryDetails, callback)
}
const payrollService = new PayrollService()
export default payrollService
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Add, Delete, Edit, GetApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import subStorageService from '../../services/SubStorageService';
import { useTitle } from '../dashboard/TitleContext';

const columns = [
  { id: 'name', label: 'Név' },
  { id: 'createdAt', label: 'Létrehozva' }
];

export default function SubStorageTable() {

  useTitle([{ name: 'Alraktárak' }])

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  useEffect(() => {
    subStorageService.search({},
      (response, error) => {
        if (response) {
          setRows(response);
        }
      }
    );
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray"}}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                  <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                    <GetApp fontSize="large" />
                  </Button>
                  <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/storage/substorages/create")}>
                    <Add fontSize="large" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/storage/substorages/" + row.id)} sx={{cursor: "pointer", padding: "10px"}}>
                            {value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="actions" padding='none' align='right'>
                        <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                          onClick={() => subStorageService.deleteSubStorage(row.id, (response, error) => {
                            if (!error) {
                              navigate(0)
                            }
                          })}>
                          <Delete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}

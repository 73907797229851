import Service from "./Service"

class VehicleLogService extends Service {

    getVehicleLogs = (page, size, vehicleId, callback) => this.get("/vehicle/" + vehicleId + "/logs?page=" + page + "&size=" + size, callback)

    getVehicleLog = (id, callback) => this.get("/vehicle-logs/" + id, callback)

    downloadImage = (id, callback) => this.get("/vehicle-logs/" + id, callback)

    createVehicleLog = (vehicleLogRequest, callback) => this.postFile("/vehicle-logs", vehicleLogRequest, callback)

    updateVehicleLog = (id, vehicleLogRequest, callback) => this.patch("/vehicle-logs/" + id, vehicleLogRequest, callback)

    delete = (id, callback) => this.del("/vehicle-logs/" + id, callback)
}
const vehicleLogService = new VehicleLogService()
export default vehicleLogService
import Service from "./Service"

class AuthService extends Service {

	login = (email, password, callback) => this.postWithoutToken("/sessions", {email, password}, callback)

	logout = () => {
		localStorage.removeItem("user")
	}

	getCurrentUser = () => JSON.parse(localStorage.getItem("user"))

	hasRole = (role) => this.getCurrentUser().roles.includes(role)
}

const authService = new AuthService()
export default authService
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import noteService from '../../services/NoteService';
import NoteEditorTemplate from './NoteEditorTemplate';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';

const NoteEditor = () => {

  const urlParts = window.location.pathname.split('/')
  const id = urlParts[3] === 'create' ? undefined : urlParts[3]

  useTitle([
    { name: 'Jegyzet Lista', url: '/dashboard/notes' },
    { name: 'Jegyzet', url: '/dashboard/notes/' + (id || 'create') },
  ])

  const [note, setNote] = useState();
  const { sendAlertMessage } = useSnackbar()
  const [waitingForServer, setWaitingForServer] = useState(false)
  const [message, setMessage] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      noteService.findById(id, (response, error) => {
        if (response) {
          setNote((prev) => ({
            ...prev,
            ...response,
          }));
        } else sendAlertMessage('Jegyzet lekérése sikertelen', 'error')
      })
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setNote((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    setWaitingForServer(true)
    noteService.createNote(note, (response, error) => {
      if (!error) {
        navigate('/dashboard/notes');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
      setWaitingForServer(false)
    });
  };

  const update = (e) => {
    e.preventDefault();
    setWaitingForServer(true)
    noteService.updateNote(id, note, (response, error) => {
      if (!error) navigate('/dashboard/notes');
      else setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      setWaitingForServer(false)
    });
  };

  return (
    <NoteEditorTemplate
      {...{ id, note, message, onChange, create, update, navigate, waitingForServer }}
    />
  );
};

export default NoteEditor;

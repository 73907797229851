import { Button, Container, TextField, Alert, Typography, CircularProgress } from "@mui/material"
import React from "react"

import '@fontsource/poppins';

export default function LoginTemplate(props) {

	const { email, password, onChange, login, message, waitingForServer } = props

	return (
		<div className="card">
			<Container component="main" maxWidth="xl">
				<div className="paper">
					<form id="login" onSubmit={login}>
						<Typography variant="h4" color={"rgba(0, 0, 0, 0.4)"}>Bejelentkezés</Typography>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="E-mail"
							name="email"
							autoComplete="off"
							autoFocus
							onChange={onChange}
							value={email}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Jelszó"
							type="password"
							id="password"
							autoComplete="off"
							onChange={onChange}
							value={password}
						/>
						{message && (
							<Alert severity="error" variant="standard" style={{ color: "red", marginBottom: "8px" }}>
								{message}
							</Alert>
						)}
						{waitingForServer ? <CircularProgress sx={{ margin: "5px 0px 0px 0px" }} size={30} /> : <Button
							type="submit"
							fullWidth
							variant="contained"
							className="submit">Belépés</Button>}
					</form>
				</div>
			</Container>
		</div>
	);
};

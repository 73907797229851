import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import globalPaymentModifierService from '../../services/GlobalPaymentModifierService';
import GlobalPaymentModifierEditorTemplate from './GlobalPaymentModifierEditorTemplate';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';

const GlobalPaymentModifierEditor = () => {

  const urlParts = window.location.pathname.split('/')
  const id = urlParts[3] === 'create' ? undefined : urlParts[3]

  useTitle([
    { name: 'Globális Fizetési Érték Lista', url: '/dashboard/global-payment-modifiers' },
    { name: 'Érték', url: '/dashboard/global-payment-modifiers/' + id },
  ])

  const { sendAlertMessage } = useSnackbar()
  const [globalPaymentModifier, setGlobalPaymentModifier] = useState(null);
  const [waitingForServer, setWaitingForServer] = useState(false)
  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      globalPaymentModifierService.findById(id, (response, error) => {
        if (response) {
          setGlobalPaymentModifier((prev) => ({
            ...prev,
            ...response,
          }));
        } else sendAlertMessage('Fizetési érték lekérése sikertelen', 'error')
      });
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setGlobalPaymentModifier((prevGlobalPaymentModifier) => ({
      ...prevGlobalPaymentModifier,
      [name]: value,
    }));
  };

  const update = (e) => {
    e.preventDefault();
    setWaitingForServer(true)
    globalPaymentModifierService.updateGlobalPaymentModifier(id, globalPaymentModifier, (response, error) => {
      if (!error) {
        navigate('/dashboard/global-payment-modifiers');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
      setWaitingForServer(false)
    });
  };

  return (
    <GlobalPaymentModifierEditorTemplate
      {...{ id, globalPaymentModifier, message, onChange, update, waitingForServer, navigate }}
    />
  );
};

export default GlobalPaymentModifierEditor;

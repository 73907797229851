import { Button, Container, TextField, Alert, Grid, Divider, MenuItem, Select, InputLabel, FormControl, Typography, Paper, TableContainer, Table, TableHead, TableRow, Link, TableCell, Tooltip, TableBody, CircularProgress, TablePagination } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';
import { parseToMoney } from "../../util/StringUtils";
import { Add, Done } from "@mui/icons-material";

const columns = [
    { id: 'serialNumber', label: 'Azonosító' },
    { id: 'name', label: 'Név' },
    { id: 'sender', label: 'Feladó' },
    { id: 'receiver', label: 'Címzett' },
    { id: 'amount', label: 'Összeg' },
    { id: 'deadline', label: 'Határidő' },
    { id: 'paymentType', label: 'Fizetés Módja' },
    { id: 'invoiceType', label: 'Számla Típusa' },
    { id: 'paid', label: 'Teljesítve', align: "right" },
    { id: 'paidBy', label: 'Teljesítette', align: "center" },
    { id: 'paidAt', label: 'Teljesítés Dátuma', align: "center" },
    { id: 'documentNames', label: 'Dokumentumok' },
	{ id: 'createdAt', label: 'Hozzáadva' }
];

export default function VehicleEditorTemplate(props) {

	const { id, onChange, create, update, message, tableData, download } = props

	const { licensePlate, technicalValidity, odometer, primaryHighwayVignetteCategory, primaryHighwayVignetteValidity, primaryHighwayVignetteExpiryDate,
		secondaryHighwayVignetteCategory, secondaryHighwayVignetteValidity, secondaryHighwayVignetteExpiryDate, insuranceCompanyName, insuranceValidity,
		lastEngineOilChange, createdAt, updatedAt} = props.vehicle

	const navigate = useNavigate();

	const renderCellValue = (column, value, row) => {
		switch (column) {
			case 'amount': return parseToMoney(value)
			case 'paid': return value === true ? <Done color='success' /> : value;
			case 'paidBy': return value?.name;
			case 'documentNames': return (
				<Link onClick={(e) => {
					e.stopPropagation()
					download(row.id, value[0])
				}}
					sx={{ textDecoration: "none", cursor: "pointer", fontWeight: "bold" }}>
					{value[0]}
				</Link>)
			default: return value
		}
	}

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : create}>
						<Grid container spacing={2} columns={20}>
							{createTextField("licensePlate", "Rendszám", licensePlate, onChange, true, true)}
							{createTextField("lastEngineOilChange", "Utolsó Olajcsere", lastEngineOilChange, onChange)}
							{createTextField("odometer", "Kilométer óra állása", odometer, onChange)}


							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"technicalValidity"}
									label={"Műszaki Érvényesség Lejárta"}
									name={"technicalValidity"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={technicalValidity || "-1"}
								/>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<Typography style={{ marginTop: "30px", marginRight: "30px", textAlign: "right" }}>Elsődleges Autópálya Matrica</Typography>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="category">Kategória</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										id="primaryHighwayVignetteCategory"
										labelId="category"
										name="primaryHighwayVignetteCategory"
										label="Kategória"
										value={primaryHighwayVignetteCategory}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"D1"}>D1</MenuItem>
										<MenuItem value={"D2"}>D2</MenuItem>
										<MenuItem value={"U"}>U</MenuItem>
										<MenuItem value={"D1M"}>D1M</MenuItem>
										<MenuItem value={"B2"}>B2</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="validity">Érvényesség</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										id="primaryHighwayVignetteValidity"
										labelId="validity"
										name="primaryHighwayVignetteValidity"
										label="Érvényesség"
										value={primaryHighwayVignetteValidity}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"Heti"}>Heti</MenuItem>
										<MenuItem value={"Havi"}>Havi</MenuItem>
										<MenuItem value={"Éves Vármegyei"}>Éves Vármegyei</MenuItem>
										<MenuItem value={"Éves Országos"}>Éves Országos</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"primaryHighwayVignetteExpiryDate"}
									label={"Lejárati Dátum"}
									name={"primaryHighwayVignetteExpiryDate"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={primaryHighwayVignetteExpiryDate ? primaryHighwayVignetteExpiryDate : "-1"}
								/>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<Typography style={{ marginTop: "30px", marginRight: "30px", textAlign: "right" }}>Másodlagos Autópálya Matrica</Typography>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="category">Kategória</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										labelId="category"
										id="secondaryHighwayVignetteCategory"
										name="secondaryHighwayVignetteCategory"
										label="Kategória"
										value={secondaryHighwayVignetteCategory}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"D1"}>D1</MenuItem>
										<MenuItem value={"D2"}>D2</MenuItem>
										<MenuItem value={"U"}>U</MenuItem>
										<MenuItem value={"D1M"}>D1M</MenuItem>
										<MenuItem value={"B2"}>B2</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="validity">Érvényesség</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										id="secondaryHighwayVignetteValidity"
										labelId="validity"
										name="secondaryHighwayVignetteValidity"
										label="Érvényesség"
										value={secondaryHighwayVignetteValidity || ""}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"Heti"}>Heti</MenuItem>
										<MenuItem value={"Havi"}>Havi</MenuItem>
										<MenuItem value={"Éves Vármegyei"}>Éves Vármegyei</MenuItem>
										<MenuItem value={"Éves Országos"}>Éves Országos</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"secondaryHighwayVignetteExpiryDate"}
									label={"Lejárati Dátum"}
									name={"secondaryHighwayVignetteExpiryDate"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={secondaryHighwayVignetteExpiryDate ? secondaryHighwayVignetteExpiryDate : "-1"}
								/>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"insuranceCompanyName"}
									label={"Biztosító Cég Neve"}
									name={"insuranceCompanyName"}
									type="text"
									autoComplete="off"
									onChange={onChange}
									value={insuranceCompanyName || ""}
								/>
							</Grid>


							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"insuranceValidity"}
									label={"Biztosítás Lejárati Dátuma"}
									name={"insuranceValidity"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={insuranceValidity ? insuranceValidity : "-1"}
								/>
							</Grid>

							{id !== undefined &&
								<>
									<Grid item lg={20} md={20} xs={20}>
										<Divider />
									</Grid>

									{createTextField("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
									{createTextField("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
								</>

							}



							{message &&
								<Grid item lg={20} xs={20}>
									<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
								</Grid>
							}

							<Grid item lg={20} md={20} xs={20}></Grid>
							<Grid item lg={2} md={5} xs={9}>
								<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/vehicles")}>
									Vissza
								</Button>
							</Grid>

							<Grid item lg={16} md={10} xs={2}></Grid>

							<Grid item lg={2} md={5} xs={9}>
								<Button type="submit" fullWidth variant="contained" color="success">
									Mentés
								</Button>
							</Grid>

						</Grid>
					</form>
				</div>

				<Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '16px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray", width: column.width }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                tableData.rows.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = renderCellValue(column.id, row[column.id], row);
                                                return (
                                                    <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/simple-invoices/" + row.id)} sx={{ cursor: "pointer", padding: "0px 10px" }}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={tableData.totalCount}
                    rowsPerPage={parseInt(tableData.rowsPerPage)}
                    page={tableData.page}
                    onPageChange={props.onPageChange}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>

			</Container>
		</div>
	)
}

function createTextField(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
	return (<Grid item lg={5} md={10} xs={20}>
		<TextField
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={label}
			name={name}
			type="text"
			autoFocus={autoFocus}
			autoComplete="off"
			onChange={onChange}
			value={value || ""}
			disabled={disabled}
		/>
	</Grid>)
}
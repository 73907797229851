import Service from "./Service"

class ToolService extends Service {

    findById = (id, callback) => this.get("/tools/" + id, callback)

    search = (queryParams, callback) => this.getWithParams("/tools", queryParams, callback)

    createTool = (toolRequest, callback) => this.post("/tools", toolRequest, callback)

    updateTool = (toolId, toolRequest, callback) => this.patch("/tools/" + toolId, toolRequest, callback)

    deleteTool = (toolId, callback) => this.del("/tools/" + toolId, callback)
}
const toolService = new ToolService()
export default toolService
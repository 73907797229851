import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { Add, AttachFile, Delete, Edit, GetApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import vehicleService from '../../services/VehicleService';
import { useTitle } from '../dashboard/TitleContext';

const columns = [
  { id: 'licensePlate', label: 'Rendszám' },
  { id: 'technicalValidity', label: 'Műszaki Érvényesség Lejárta' },
  { id: 'lastEngineOilChange', label: 'Utolsó olajcsere (km)' },
  { id: 'primaryHighwayVignetteCategory', label: 'Autópálya Matrica Típus' },
  { id: 'primaryHighwayVignetteValidity', label: 'Matrica Érvényessége' },
  { id: 'insuranceValidity', label: 'Biztosítás Érvényessége' },
  { id: 'odometer', label: 'Kilométer óra állása' }
];

export default function VehicleTable() {

  useTitle([{ name: 'Járművek' }])

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dialogState, setDialogState] = React.useState({ open: false, object: null });

  const handleClickOpen = (object) => {
    setDialogState({ open: true, object: object });
  };

  const handleClose = () => {
    setDialogState({ open: false, object: null });
  };

  useEffect(() => {
    vehicleService.search(
      (response, error) => {
        if (response) {
          setRows(response);
        }
      }
    );
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                  <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                    <GetApp fontSize="large" />
                  </Button>
                  <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/vehicles/create")}>
                    <Add fontSize="large" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id] ? row[column.id].toLocaleString() : row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/vehicles/" + row.id)} sx={{cursor: "pointer", padding: "10px"}}>
                            {value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="actions" padding='none' align='right'>
                        <Tooltip title="Bejegyzések">
                          <Button color='primary' variant='contained' padding='30px'
                            sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/vehicles/" + row.id + "/logs")}>
                            <AttachFile />
                          </Button>
                        </Tooltip>
                        <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                          onClick={() => handleClickOpen(row)}>
                          <Delete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>

      <React.Fragment>
        <Dialog open={dialogState.open} onClose={handleClose} >
          <DialogTitle>
            {"Biztosan törölni szeretnéd az alábbi járművet?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogState.object?.licensePlate}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClose()
              vehicleService.deleteVehicle(dialogState.object?.id, (response, error) => { if (!error) navigate(0) })
            }}>
              Igen</Button>
            <Button onClick={handleClose} autoFocus>Mégse</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}

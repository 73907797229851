import { Button, Container, TextField, Alert, Grid, Select, MenuItem, InputLabel, FormControl } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function UserEditorTemplate(props) {

	const { id, onChange, register, update, message } = props.data

	const { name, email, password, passwordCheck, hourlyRate, role, salaryTemplate, active, monthlySzja, yearlyDayOffs, monthlyFuelCost, dailySicknessBenefitAmount,
		transferredSalaryAmount, availableDayOffs } = props.data.user

	const navigate = useNavigate()

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : register}>
						<Grid container columns={4}>

							<Grid item md={1} xs={4}>
								<Grid container columns={1} padding="20px" spacing={2}>

									<Grid item md={5} xs={20}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="name"
											label="Név"
											name="name"
											type="text"
											autoComplete="off"
											autoFocus
											onChange={onChange}
											value={name}
										/>
									</Grid>

									<Grid item md={5} xs={20}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="email"
											label="E-mail"
											name="email"
											type="email"
											autoComplete="off"
											onChange={onChange}
											value={email}
										/>
									</Grid>

									{id === undefined && <><Grid item md={5} xs={20}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="password"
											label="Jelszó"
											type="password"
											id="password"
											autoComplete="off"
											onChange={onChange}
											value={password}
										/>
									</Grid>

										<Grid item md={5} xs={20}>
											<TextField
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="passwordCheck"
												label="Jelszó megerősítés"
												type="password"
												id="passwordCheck"
												autoComplete="off"
												onChange={onChange}
												value={passwordCheck}
											/>
										</Grid>
									</>}
								</Grid>
							</Grid>

							<Grid item md={1} xs={4} style={{ borderLeft: "2px solid darkgray" }}>
								<Grid container columns={1} padding="20px" spacing={2}>

									<Grid item md={8} xs={20}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="hourlyRate"
											label="Óradíj"
											type="text"
											id="hourlyRate"
											autoComplete="off"
											onChange={onChange}
											value={hourlyRate}
										/>
									</Grid>


									<Grid item md={4} xs={20}>
										<FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
											<InputLabel id="roleLabel">Szerepkör</InputLabel>
											<Select
												variant="outlined"
												required
												fullWidth
												id="role"
												name="role"
												label="Szerepkör"
												labelId="roleLabel"
												value={role}
												onChange={onChange}
												sx={{ textAlign: "left" }}
												defaultValue={"ROLE_EMPLOYEE"}
											>
												<MenuItem value={"ROLE_EMPLOYEE"}>Dolgozó</MenuItem>
												<MenuItem value={"ROLE_CEO"}>Cégvezető</MenuItem>
												<MenuItem value={"ROLE_ADMIN"}>Adminisztrátor</MenuItem>
												<MenuItem value={"ROLE_EXTERNAL_SUBCONTRACTOR"}>Külsős Alvállalkozó</MenuItem>
												<MenuItem value={"ROLE_STATISTICIAN"}>Statisztikus</MenuItem>
											</Select>
										</FormControl>
									</Grid>

									<Grid item md={4} xs={20}>
										<FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
											<InputLabel id="salaryTemplateLabel">Fizetési Kategória</InputLabel>
											<Select
												variant="outlined"
												required
												fullWidth
												id="salaryTemplate"
												name="salaryTemplate"
												label="Fizetési Kategória"
												labelId="salaryTemplateLabel"
												value={salaryTemplate}
												onChange={onChange}
												sx={{ textAlign: "left" }}
												defaultValue={"NORMAL"}
											>
												<MenuItem value={"NORMAL"}>Normál</MenuItem>
												<MenuItem value={"ONLY_HOUR_BASED"}>Csak Óra Alapú</MenuItem>
												<MenuItem value={"EXTERNAL_SUBCONTRACTOR"}>Külsős Alvállalkozó</MenuItem>
											</Select>
										</FormControl>
									</Grid>

									<Grid item md={4} xs={20}>
										<FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
											<InputLabel id="activeTemplateLabel">Státusz</InputLabel>
											<Select
												variant="outlined"
												required
												fullWidth
												id="active"
												name="active"
												label="Státusz"
												labelId="activeTemplateLabel"
												value={active}
												onChange={onChange}
												sx={{ textAlign: "left" }}
												defaultValue={true}
											>
												<MenuItem value={true}>Aktív</MenuItem>
												<MenuItem value={false}>Inaktív</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>

							<Grid item md={2} xs={4} style={{ borderLeft: "2px solid darkgray" }}>
								<Grid container columns={2} padding="20px" spacing={2}>

									<Grid item md={1} xs={2}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="monthlySzja"
											label="SZJA Összege (havonta)"
											type="text"
											id="monthlySzja"
											autoComplete="off"
											onChange={onChange}
											value={monthlySzja}
										/>
									</Grid>

									<Grid item md={1} xs={2}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="monthlyFuelCost"
											label="Benzinpénz (Ft/km)"
											type="text"
											id="monthlyFuelCost"
											autoComplete="off"
											onChange={onChange}
											value={monthlyFuelCost}
										/>
									</Grid>

									<Grid item md={1} xs={2}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="dailySicknessBenefitAmount"
											label="Táppénz Összege (naponta)"
											type="text"
											id="dailySicknessBenefitAmount"
											autoComplete="off"
											onChange={onChange}
											value={dailySicknessBenefitAmount}
										/>
									</Grid>

									<Grid item md={1} xs={2}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="transferredSalaryAmount"
											label="Átutalt Bér (havonta)"
											type="text"
											id="transferredSalaryAmount"
											autoComplete="off"
											onChange={onChange}
											value={transferredSalaryAmount}
										/>
									</Grid>

									<Grid item md={1} xs={2}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="yearlyDayOffs"
											label="Szabadságok Száma (Évente)"
											type="text"
											id="yearlyDayOffs"
											autoComplete="off"
											onChange={onChange}
											value={yearlyDayOffs}
										/>
									</Grid>

									<Grid item md={1} xs={2}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="availableDayOffs"
											label="Maradék Szabadság"
											type="text"
											id="availableDayOffs"
											autoComplete="off"
											onChange={onChange}
											value={availableDayOffs || ""}
										/>
									</Grid>

								</Grid>
							</Grid>

							<Grid container columns={20}>
								{message &&
									<Grid item md={20} xs={20}>
										<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
									</Grid>
								}

								<Grid item lg={2} md={5} xs={9}>
									<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/users")}>
										Vissza
									</Button>
								</Grid>

								<Grid item lg={16} md={10} xs={2}></Grid>

								<Grid item lg={2} md={5} xs={9}>
									<Button type="submit" fullWidth variant="contained" color="success">
										Mentés
									</Button>
								</Grid>
							</Grid>

						</Grid>
					</form>
				</div>

			</Container>
		</div>
	)
}
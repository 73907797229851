import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { GetApp, KeyboardArrowDown, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import productService from '../../services/ProductService';
import { useTitle } from '../dashboard/TitleContext';
import { useState } from 'react';
import { useSnackbar } from '../../util/SnackbarProvider';

const columns = [
  { id: 'name', label: 'Termék' },
  { id: 'manufacturer', label: 'Gyártó' },
  { id: 'articleNumber', label: 'Cikkszám' },
  { id: 'totalAmount', label: 'Összes Példány' }
];

const LOCAL_STORAGE_ITEM_NAME = "product-rows-per-page"
const LOCAL_STORAGE_SEARCH_PARAMS = "product-search-params"

export default function ProductTable() {

  useTitle([{ name: 'Raktár' }])

  const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })
  const [expanded, setExpanded] = useState(true);
  const [searchParams, setSearchParams] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_PARAMS)))
  const { sendAlertMessage } = useSnackbar()

  useEffect(() => {
    productService.searchProducts(buildQueryParams(), (response, error) => {
      if (response) {
        setTableData((prev) => ({ ...prev, totalCount: response.totalElements, rows: response.content }))
      } else sendAlertMessage('Termékek lekérése sikertelen!', 'error')
    })
  }, [tableData.page, tableData.rowsPerPage, searchParams]);

  function buildQueryParams() {
    return {
      page: tableData.page,
      size: tableData.rowsPerPage,
      ...searchParams
    }
  }

  const onSearchParamsChange = (event) => {
    const { name, value } = event.target
    setSearchParams((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const clear = () => {
    if (searchParams != null) sendAlertMessage('Szűrők alaphelyzetbe állítva', 'info')
    setSearchParams(undefined)
  }

  useEffect(() => {
    if (searchParams) localStorage.setItem(LOCAL_STORAGE_SEARCH_PARAMS, JSON.stringify(searchParams));
    else localStorage.removeItem(LOCAL_STORAGE_SEARCH_PARAMS)
  }, [searchParams]);

  const navigate = useNavigate();

  return (
    <>
      <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
          Keresési Feltételek
        </AccordionSummary>
        <AccordionDetails className="filter-panel">
          <Grid container columns={20} spacing={2} padding={"10px"}>
            <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Termék Neve"
                name="name"
                autoComplete="off"
                onChange={onSearchParamsChange}
                sx={{ margin: "0px" }}
                value={(searchParams && searchParams.name) || ""}
              />
            </Grid>

            <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Gyártó"
                name="manufacturer"
                autoComplete="off"
                onChange={onSearchParamsChange}
                sx={{ margin: "0px" }}
                value={(searchParams && searchParams.manufacturer) || ""}
              />
            </Grid>

            <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Cikkszám"
                name="articleNumber"
                autoComplete="off"
                onChange={onSearchParamsChange}
                sx={{ margin: "0px" }}
                value={(searchParams && searchParams.articleNumber) || ""}
              />
            </Grid>

            <Grid item lg={4} md={10} xs={20}></Grid>

            <Grid item md={4} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
              <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: "calc(100vh - 360px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={17} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                tableData.rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/storage/products/" + row.id)} sx={{ cursor: "pointer", padding: "10px" }}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25, 50]}
          component="div"
          count={tableData.totalCount}
          rowsPerPage={parseInt(tableData.rowsPerPage)}
          page={tableData.page}
          onPageChange={(event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))}
          onRowsPerPageChange={(event) => {
            setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
            localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
          }}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}

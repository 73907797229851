import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Add, Delete, Edit, GetApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import vehicleLogService from '../../../services/VehicleLogsService';
import { useTitle } from '../../dashboard/TitleContext';

const columns = [
    { id: 'preview', label: 'Kép Előnézet' },
    { id: 'text', label: 'Bejegyzés' },
    { id: 'createdAt', label: 'Létrehozás Dátuma' }
];

export default function VehicleLogTable() {

    const [id] = React.useState(window.location.pathname.split('/')[3]);

    useTitle([
        { name: 'Jármű Lista', url: '/dashboard/vehicles' },
        { name: 'Jármű', url: '/dashboard/vehicles/' + id },
        { name: 'Történet', url: '/dashboard/vehicles/' + id + '/logs' }
    ])

    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [totalCount, setTotalCount] = React.useState(0);

    useEffect(() => {
        vehicleLogService.getVehicleLogs(page, rowsPerPage, id,
            (response, error) => {
                if (response) {
                    setTotalCount(response.totalElements)
                    setRows(response.content);
                }
            }
        );
    }, [page, rowsPerPage, id]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const navigate = useNavigate();

    console.log(rows)

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                                    <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/vehicles/" + id + "/logs/create")}>
                                        <Add fontSize="large" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            let value;
                                            if (column.id === 'preview') {
                                                const blob = new Blob([new Uint8Array([...atob(row[column.id])]
                                                    .map(char => char.charCodeAt(0)))], { type: 'image/png' });
                                                const imageUrl = URL.createObjectURL(blob);
                                                value = <img src={imageUrl} alt="" />
                                            } else value = row[column.id] ? row[column.id].toLocaleString() : row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/vehicles/" + id + "/logs/" + row.id)} sx={{ cursor: "pointer", padding: "0px 10px" }}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell key="actions" padding='none' align='right'>
                                            <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                onClick={() => vehicleLogService.delete(row.id, (response, error) => {
                                                    if (!error) {
                                                        navigate(0)
                                                    }
                                                })}>
                                                <Delete />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ position: "relative" }}>
                    <Button type="button" variant="contained" color="error" onClick={() => navigate(-1)} style={{ position: "absolute", margin: "8px", zIndex: 1 }}>
                        Vissza
                    </Button>
                </div>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    );
}

import { Button, Container, TextField, Alert, Grid, Divider, CircularProgress, OutlinedInput, Box, Chip, MenuItem, Select, FormControl, InputLabel, Autocomplete } from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from "react"
import { useNavigate } from 'react-router-dom';
import ProjectSubTaskComments from "../project-task-comments/ProjectSubTaskComments";

export default function ProjectSubTaskEditorTemplate(props) {

    const { id, onChange, create, saveAsNew, update, message, users, projectSubTask, waitingForServer, onUsersChange, vehicles, onChangeSelectItem, onDateTimeChange } = props.data

    const navigate = useNavigate();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
            },
        },
    };

    function getStyles(name, users) {
        if (users && users.map(user => user.name).indexOf(name) === -1) return
        return {
            backgroundColor: "#2e7d32",
            color: "white"
        };
    }

    return (
        <div>
            <Container component="main">
                <Grid container columns={20}>
                    <Grid item xs={20}>
                        <div className="register-paper">
                            <form id="login" onSubmit={id ? update : create}>
                                <Grid container spacing={2} columns={40}>
                                    {id != null && projectSubTask.id == null ? <Grid item xs={40}><CircularProgress sx={{ margin: "200px" }} /> </Grid> :
                                        <>
                                            {createInputField("description", "Alfeladat Leírása", projectSubTask.description, onChange, false, false, false, "text", true)}
                                            {createInputField("condition", "Feltétel", projectSubTask.condition, onChange, false, false, false, "text", true)}

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                                    <InputLabel id="priority-label">Prioritás</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        fullWidth
                                                        labelId="priority-label"
                                                        name="priority"
                                                        label="Prioritás"
                                                        value={projectSubTask.priority}
                                                        defaultValue={"NORMAL"}
                                                        onChange={onChange}
                                                        sx={{ textAlign: "left" }}
                                                    >
                                                        <MenuItem value={"EXTRA_IMPORTANT"}>Extra fontos</MenuItem>
                                                        <MenuItem value={"IMPORTANT"}>Fontos</MenuItem>
                                                        <MenuItem value={"NORMAL"}>Normál</MenuItem>
                                                        <MenuItem value={"NOT_IMPORTANT"}>Nem fontos</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                                    <InputLabel id="status-label">Státusz</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        fullWidth
                                                        labelId="status-label"
                                                        name="status"
                                                        label="Státusz"
                                                        value={projectSubTask.status}
                                                        defaultValue={"NOT_STARTED"}
                                                        onChange={onChange}
                                                        sx={{ textAlign: "left" }}
                                                    >
                                                        <MenuItem value={"NOT_STARTED"}>Nincs elkezdve</MenuItem>
                                                        <MenuItem value={"IN_PROGRESS"}>Folyamatban</MenuItem>
                                                        <MenuItem value={"BLOCKED"}>Elakadva</MenuItem>
                                                        <MenuItem value={"OVERDUE"}>Csúszásban</MenuItem>
                                                        <MenuItem value={"DONE"}>Befejezve</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {createInputField("progressLevel", "Készültségi Szint", projectSubTask.progressLevel === null ? null : projectSubTask.progressLevel?.toString(), onChange, false, false, false, "number", false, 5)}
                                            {createInputField("weight", "Feladat Súly Hányadosa", projectSubTask.weight === null ? null : projectSubTask.weight?.toString(), onChange, false, false, false, "number", false, 5)}

                                            <Grid item lg={10} md={10} xs={20}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DateTimePicker label="Határidő" value={projectSubTask.deadline} sx={{ width: "100%", marginTop: "16px" }} onChange={(newValue) => onDateTimeChange(newValue, "deadline")} />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item lg={10} md={10} xs={20}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DateTimePicker label="Tervezett Kezdés" value={projectSubTask.scheduleDateStart} sx={{ width: "100%", marginTop: "16px" }} onChange={(newValue) => onDateTimeChange(newValue, "scheduleDateStart")} />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item lg={10} md={10} xs={20}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DateTimePicker label="Tervezett Befejezés" value={projectSubTask.scheduleDateEnd} sx={{ width: "100%", marginTop: "16px" }} onChange={(newValue) => onDateTimeChange(newValue, "scheduleDateEnd")} />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <Autocomplete
                                                    value={projectSubTask.vehicle || null}
                                                    margin="normal"
                                                    disableClearable
                                                    onChange={(event, newValue) => onChangeSelectItem(newValue, "vehicle")}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={vehicles}
                                                    getOptionLabel={(vehicle) => vehicle.licensePlate}
                                                    style={{ marginTop: "16px" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Jármű" />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                                    <InputLabel id="users-label">Dolgozók</InputLabel>
                                                    <Select
                                                        labelId="users-label"
                                                        multiple
                                                        value={projectSubTask.users || []}
                                                        onChange={onUsersChange}
                                                        label="Dolgozók"
                                                        variant="outlined"
                                                        input={<OutlinedInput id="select-multiple-chip" label="Dolgozók" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                                                {selected.map((value) => (
                                                                    <Chip key={value.id} label={value.name} />
                                                                ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {users.map((user) => (
                                                            <MenuItem
                                                                key={user.id}
                                                                value={user}
                                                                style={getStyles(user.name, projectSubTask.users)}
                                                            >
                                                                {user.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {message &&
                                                <Grid item xs={20}>
                                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                                </Grid>
                                            }
                                        </>
                                    }

                                    <Grid item md={40} xs={2}></Grid>

                                    <Grid item lg={4} md={6} xs={8}>
                                        <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                            Vissza
                                        </Button>
                                    </Grid>

                                    <Grid item lg={28} md={22} xs={16}></Grid>

                                    <Grid item lg={4} md={6} xs={8}>
                                        {!waitingForServer && <Button type="button" fullWidth variant="contained" color="primary" onClick={() => saveAsNew()}>
                                            Mentés Újként
                                        </Button>}
                                    </Grid>

                                    <Grid item lg={4} md={6} xs={8}>
                                        {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                            Mentés
                                        </Button>}
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Grid>
                    {id && <Grid item xs={20}>
                        <ProjectSubTaskComments />
                    </Grid>
                    }
                </Grid>
            </Container>
        </div>
    )
}

function createInputField(name, label, value, onChange, required = false, autoFocus = false, disabled = false, type = "text", multiline = false, lg=10) {
    return (<Grid item lg={lg} md={20} xs={40}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type={type}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value}
            disabled={disabled}
            multiline={multiline}
        />
    </Grid>)
}
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Alert, Button, CircularProgress } from '@mui/material';
import { Add, Delete, GetApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import { API_URL } from '../../App';
import invoiceService from '../../services/InvoiceService';
import authService from '../../services/AuthService';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';
import { useConfirmationDialog } from '../../util/ConfirmationDialogProvider';

const columns = [
    { id: 'preview', label: 'Előnézet' },
    { id: 'imageUrl', label: 'Útvonal' },
    { id: 'createdAt', label: 'Létrehozva' }
];

const VisuallyHiddenInput = styled('input')({
    width: 0
});

const LOCAL_STORAGE_ITEM_NAME = "task-log-item-invoices-rows-per-page"

export default function InvoiceTable() {

    const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })
    const { sendAlertMessage } = useSnackbar()
    const { openConfirmationDialog } = useConfirmationDialog()

    const urlParts = window.location.pathname.split('/')
    const taskLogId = urlParts[3];
    const taskLogItemId = urlParts[5];

    useTitle([
        { name: 'Munkaidő', url: '/dashboard/task-logs' },
        { name: 'Bejegyzés', url: '/dashboard/task-logs/' + taskLogId },
        { name: 'Számlák', url: '/dashboard/task-logs/' + taskLogId + '/task-log-items/' + taskLogItemId + '/invoices' }
    ])

    useEffect(() => {
        retrieveInvoices()
    }, [taskLogId, taskLogItemId, tableData.page, tableData.rowsPerPage]);

    const retrieveInvoices = () => {
        invoiceService.getInvoices(taskLogItemId, buildQueryParams(), (response, error) => {
            if (response) {
                setTableData((prev) => ({ ...prev, totalCount: response.totalElements, rows: response.content }))
            } else sendAlertMessage('Számlák lekérése sikertelen!', 'error')
        })
    }

    function buildQueryParams() {
        return {
            page: tableData.page,
            size: tableData.rowsPerPage
        }
    }

    const downloadInvoice = (id) => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/invoices/" + id,
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => saveAs(response.data, "image.png"))
            .catch(error => console.error(error));
    }

    const deleteInvoice = (id) => {
        invoiceService.deleteInvoice(id, (response, error) => {
            if (!error) {
                retrieveInvoices()
                sendAlertMessage('Számla sikeresen törölve!')
            }
            else sendAlertMessage('Számla törlése sikertelen!', 'error')
        })
    }

    const navigate = useNavigate();

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                                    <Button component="label" variant="contained" color='success' style={{ padding: "0px", minWidth: "30px", marginRight: "10px" }}>
                                        <Add fontSize="large" />
                                        <VisuallyHiddenInput type="file"
                                            onChange={(e) => {
                                                const formData = new FormData();
                                                formData.append('file', e.target.files[0]);
                                                invoiceService.createInvoice(taskLogItemId, formData, (response, error) => {
                                                    if (error) sendAlertMessage('Számla hozzáadása sikertelen!', 'error')
                                                    else {
                                                        sendAlertMessage('Számla sikeresen hozzáadva!')
                                                        retrieveInvoices()
                                                    }
                                                })
                                            }}
                                        />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                tableData.rows.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                let value;
                                                if (column.id === 'preview') {
                                                    const blob = new Blob([new Uint8Array([...atob(row[column.id])]
                                                        .map(char => char.charCodeAt(0)))], { type: 'image/png' });
                                                    const imageUrl = URL.createObjectURL(blob);
                                                    value = <img src={imageUrl} alt="" />
                                                }
                                                else value = row[column.id] ? row[column.id].toLocaleString() : row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} onClick={() => navigate('/dashboard/task-logs/' + taskLogId + '/task-log-items/' + taskLogItemId + '/invoices/' + row.id)} sx={{ cursor: "pointer", padding: "0px 10px" }}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right'>
                                                <Button variant='contained' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }}
                                                    onClick={() => downloadInvoice(row.id)}>
                                                    <GetApp fontSize="large" />
                                                </Button>
                                                <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                    onClick={() => openConfirmationDialog(
                                                        'Biztosan törölni szeretnéd az alábbi számlát?',
                                                        <>{row.imageUrl}</>,
                                                        () => deleteInvoice(row.id)
                                                    )}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ position: "relative" }}>
                    <Button type="button" variant="contained" color="error" onClick={() => navigate(-1)} style={{ position: "absolute", margin: "8px", zIndex: 1 }}>
                        Vissza
                    </Button>
                </div>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={tableData.totalCount}
                    rowsPerPage={tableData.rowsPerPage}
                    page={tableData.page}
                    onPageChange={(event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))}
                    onRowsPerPageChange={(event) => {
                        setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
                        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
                    }}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper >
        </>
    );
}

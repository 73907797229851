import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import { AccessTime, AccountBalance, Assignment, AttachMoney, Ballot, BarChart, ExpandLess, ExpandMore, Feed, FormatListNumbered, Group, Handyman, History, Inventory, LocalShipping, MoveDown, Receipt, Settings, SpeakerNotes, Storage, Warehouse } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';


export function MainListItems() {

  const navigate = useNavigate();
  const location = useLocation();

  const [openStorage, setOpenStorage] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const url = window.location.pathname
  const selectedMenuItem = { backgroundColor: "gray", color: "white" }
  const menuItems = [
    { path: "/dashboard/task-logs", label: "Munkaidő", icon: <AccessTime /> },
    {
      label: "Raktár",
      icon: <Warehouse />,
      collapsible: true,
      open: openStorage,
      toggleOpen: () => setOpenStorage(!openStorage),
      subItems: [
        { path: "/dashboard/storage/products", label: "Termékek", icon: <Inventory /> },
        { path: "/dashboard/storage/move", label: "Mozgatás", icon: <MoveDown /> },
        { path: "/dashboard/storage/logs", label: "Történet", icon: <History /> },
        { path: "/dashboard/storage/substorages", label: "Alraktárak", icon: <Storage /> }
      ]
    },
    {
      label: "Beállítások",
      icon: <Settings />,
      collapsible: true,
      open: openSettings,
      toggleOpen: () => setOpenSettings(!openSettings),
      subItems: [
        { path: "/dashboard/projects", label: "Projektek", icon: <Assignment /> },
        { path: "/dashboard/project-tasks/projects", label: "Projekt Feladatok", icon: <FormatListNumbered /> },
        { path: "/dashboard/task-types", label: "Feladatkörök", icon: <Ballot /> },
        { path: "/dashboard/users", label: "Felhasználók", icon: <Group /> },
        { path: "/dashboard/vehicles", label: "Járművek", icon: <LocalShipping /> },
        { path: "/dashboard/global-payment-modifiers", label: "Globális Értékek", icon: <AccountBalance /> }
      ]
    },
    { path: "/dashboard/payrolls", label: "Fizetések", icon: <AttachMoney /> },
    { path: "/dashboard/simple-invoices", label: "Számlák", icon: <Receipt /> },
    { path: "/dashboard/notes", label: "Jegyzetek", icon: <SpeakerNotes /> },
    { path: "/dashboard/tools", label: "Eszközök", icon: <Handyman /> },
  ];

  return (
    <React.Fragment>
      {menuItems.map((item, index) => (
        <React.Fragment key={index + '-' + item.path}>
          <ListItemButton
            onClick={item.collapsible ? item.toggleOpen : () => navigate(item.path)}
            sx={url.startsWith(item.path) ? selectedMenuItem : {}}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
            {item.collapsible && (item.open ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>

          {item.collapsible && (
            <Collapse in={item.open} timeout="auto" unmountOnExit>
              {item.subItems.map((subItem) => (
                <ListItemButton
                  key={subItem.path}
                  onClick={() => navigate(subItem.path)}
                  sx={url.startsWith(subItem.path) ? selectedMenuItem : {}}
                >
                  <ListItemIcon style={{ marginLeft: "20px" }}>
                    {subItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={subItem.label} />
                </ListItemButton>
              ))}
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
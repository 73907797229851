import React, { useState, useEffect } from 'react';
import ProductItemEditorTemplate from './ProductItemEditorTemplate';
import productService from '../../services/ProductService';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';

const ProductItemEditor = () => {

    const urlParts = window.location.pathname.split('/')
    const productId = urlParts[4];
    const id = urlParts[6];

    useTitle([
        { name: 'Raktár', url: '/dashboard/storage/products' },
        { name: 'Termék', url: '/dashboard/storage/products/' + productId },
        { name: 'Termék Elem', url: '/dashboard/storage/products/' + productId + '/item/' + id }
    ])

    const [productItem, setProductItem] = useState({});
    const [message, setMessage] = useState('')
    const [waitingForServer, setWaitingForServer] = useState(false)
    const { sendAlertMessage } = useSnackbar()

    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            productService.findItemById(id, (response, error) => {
                if (response) {
                    setProductItem(response);
                } else sendAlertMessage('Termék Példány lekérése sikertelen', 'error')
            });
        }
    }, [id]);

    const onChange = (event) => {
        const { name, value } = event.target;
        setProductItem((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const update = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        productService.updateProductItem(id, productItem, (response, error) => {
            if (!error) {
                sendAlertMessage('Termék Példány mentésre került')
                navigate(-1);
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const onChangeSelectItem = (newValue, field) => {
        setProductItem((prev) => ({
            ...prev,
            [field]: newValue,
        }));
    };

    return (
        <ProductItemEditorTemplate
            {...{
                id,
                productItem,
                message,
                onChangeSelectItem,
                onChange,
                update,
                navigate,
                waitingForServer
            }}
        />
    );
};

export default ProductItemEditor;

import { TextField } from "@mui/material";

export default function PercentageProgressBar ({ percentage, onChange, title, name, readonly, color, narrow = false, align = "left" }) {
	const normalizedPercentage = Math.min(Math.max(0, percentage), 100);
	const progressWidth = `${normalizedPercentage}%`;
	const bgColor = color === undefined ? `rgba(0, 0, 255, 0.15)` : color;

	return (
		<TextField
			value={percentage}
			label={title}
			fullWidth
			type="number"
			margin="normal"
			name={name}
			onChange={onChange}
			InputProps={readonly && { style: { pointerEvents: 'none', backgroundColor: "whitesmoke"} }}
			inputProps={{
				min: 0,
				max: 100,
				style: {
					background: `linear-gradient(to right, ${bgColor} ${progressWidth}, transparent ${progressWidth})`,
					WebkitAppearance: 'none',
					MozAppearance: 'textfield',
					appearance: 'textfield',
                    height: narrow ? "0px": "auto",
                    margin: narrow ? "0px": "auto",
                    padding: narrow ? "15px": "auto",
                    borderRadius: "5px",
                    textAlign: align
				}
			}}
			style={{ transition: 'background-color 0.3s'}}
		/>
	);
};
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import noteService from '../../services/NoteService';
import { useTitle } from '../dashboard/TitleContext';
import { useState } from 'react';
import { useSnackbar } from '../../util/SnackbarProvider';
import { useConfirmationDialog } from '../../util/ConfirmationDialogProvider';

const columns = [
  { id: 'text', label: 'Szöveg' },
  { id: 'username', label: 'Név', width: "120px", align: "center" },
  { id: 'createdAt', label: 'Létrehozva', width: "80px", align: "center" }
];

const LOCAL_STORAGE_ITEM_NAME = "notes-rows-per-page"

export default function NoteTable() {

  useTitle([{ name: 'Jegyzetek' }])

  const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })
  const { sendAlertMessage } = useSnackbar()
  const { openConfirmationDialog } = useConfirmationDialog()

  useEffect(() => {
    retrieveNotes()
  }, [tableData.page, tableData.rowsPerPage]);

  const retrieveNotes = () => {
    noteService.findAll(buildQueryParams(), (response, error) => {
      if (response) {
        setTableData((prev) => ({ ...prev, totalCount: response.totalElements, rows: response.content }))
      } else sendAlertMessage('Jegyzetek lekérése sikertelen!', 'error')
    })
  }

  function buildQueryParams() {
    return {
      page: tableData.page,
      size: tableData.rowsPerPage
    }
  }

  const deleteNote = (id) => {
    noteService.deleteNote(id, (response, error) => {
      if (!error) {
        retrieveNotes()
        sendAlertMessage('Jegyzet sikeresen törölve!')
      }
      else sendAlertMessage('Jegyzet törlése sikertelen!', 'error')
    })
  }

  const navigate = useNavigate();

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray", width: column.width }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px", width: "90px" }} align='right' padding='none'>
                  <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/notes/create")}>
                    <Add fontSize="large" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                tableData.rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        const displayValue = value.replace(/\n/g, '<br/>');
                        return (
                          <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/notes/" + row.id)} sx={{ cursor: "pointer" }}>
                            <div dangerouslySetInnerHTML={{ __html: displayValue }} />
                          </TableCell>
                        );
                      })}

                      <TableCell key="actions" padding='none' align='right'>
                        <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                          onClick={() => openConfirmationDialog(
                            'Biztosan törölni szeretnéd az alábbi jegyzetet?',
                            <>{row.text?.length > 50 ? row.text?.slice(0, 50) + '...' : row.text}</>,
                            () => deleteNote(row.id)
                          )}>
                          <Delete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25, 50]}
          component="div"
          count={tableData.totalCount}
          rowsPerPage={parseInt(tableData.rowsPerPage)}
          page={tableData.page}
          onPageChange={(event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))}
          onRowsPerPageChange={(event) => {
            setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
            localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
          }}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}

import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import LoginTemplate from "./LoginTemplate";
import { useNavigate } from "react-router-dom";

const Login = () => {

	const navigate = useNavigate()

	const [state, setState] = useState({});
	const [waitingForServer, setWaitingForServer] = useState(false)

	const onChange = (event) => {
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const login = (e) => {
		e.preventDefault();
		setWaitingForServer(true)
		setState((prevState) => ({ ...prevState, message: '' }));
		AuthService.login(state.email, state.password, (response, error) => {
			if (response) {
				localStorage.setItem("user", JSON.stringify(response));
				navigate("/dashboard/task-logs");
			} else {
				console.log(error)
				setState((prevState) => ({ ...prevState, message: error }));
			}
			setWaitingForServer(false)
		});
	};

	return <LoginTemplate 
	{...{ ...state, onChange, login, waitingForServer }}
	/>;
};

export default Login;

import * as React from 'react';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { history } from './History';
import authService from './services/AuthService';
import ProjectTable from './components/projects/Projects';
import ProjectEditor from './components/projects/ProjectEditor';
import UserEditor from './components/users/UserEditor';
import UserTable from './components/users/Users';
import SubStorageTable from './components/substorages/SubStorages';
import SubStorageEditor from './components/substorages/SubStorageEditor';
import VehicleTable from './components/vehicles/Vehicles';
import VehicleEditor from './components/vehicles/VehicleEditor';
import TaskTypeTable from './components/task-types/TaskTypes';
import TaskTypeEditor from './components/task-types/TaskTypeEditor';
import GlobalPaymentModifierTable from './components/global-payment-modifiers/GlobalPaymentModifiers';
import GlobalPaymentModifierEditor from './components/global-payment-modifiers/GlobalPaymentModifierEditor';
import TaskLogTable from './components/task-logs/TaskLogs';
import TaskLogEditor from './components/task-logs/TaskLogEditor';
import InvoiceTable from './components/invoices/Invoices';
import Invoice from './components/invoices/Invoice';
import InvoiceList from './components/invoices/InvoiceList';
import NoteTable from './components/notes/Notes';
import NoteEditor from './components/notes/NoteEditor';
import Payroll from './components/payrolls/Payroll';
import PayrollTable from './components/payrolls/Payrolls';
import SalaryDetails from './components/payrolls/salary-details/SalaryDetails';
import VehicleLogTable from './components/vehicles/vehicle-logs/VehicleLogs';
import VehicleLogEditor from './components/vehicles/vehicle-logs/VehicleLogEditor';
import ProductMoving from './components/products/ProductMoving';
import ChangeLogs from './components/change-logs/ChangeLogs';
import ProductTable from './components/products/Products';
import ProductEditor from './components/products/ProductEditor';
import ProductItemEditor from './components/products/ProductItemEditor';
import StorageLogTable from './components/storage-logs/StorageLogs';
import ProjectTaskTable from './components/project-tasks/ProjectTasks';
import ProjectTaskEditor from './components/project-tasks/ProjectTaskEditor';
import ProjectSubTaskEditor from './components/project-subtasks/ProjectSubTaskEditor';
import { TitleProvider } from './components/dashboard/TitleContext';
import ToolsTable from './components/tools/Tools';
import ToolEditor from './components/tools/ToolEditor';
import { SnackbarProvider } from './util/SnackbarProvider';
import { ConfirmationDialogProvider } from './util/ConfirmationDialogProvider';
import ProjectTaskView from './components/project-tasks/ProjectTaskView';
import SimpleInvoiceTable from './components/invoices/SimpleInvoices';
import SimpleInvoiceEditor from './components/invoices/SimpleInvoiceEditor';

export const API_URL = process.env.REACT_APP_API_URL

export default function App() {

  return (
    <>
      <TitleProvider>
        <SnackbarProvider>
          <ConfirmationDialogProvider>
            <div className="root">
              <Router>
                <Routes>
                  {!authService.getCurrentUser() && history.push("/login")}
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/dashboard/change-logs" element={<Dashboard content={<ChangeLogs />} />} />
                  <Route exact path='/dashboard/task-logs' element={<Dashboard content={<TaskLogTable />} />} />
                  <Route exact path='/dashboard/task-logs/create' element={<Dashboard content={<TaskLogEditor />} />} />
                  <Route exact path='/dashboard/task-logs/:id' element={<Dashboard content={<TaskLogEditor />} />} />
                  <Route exact path='/dashboard/task-logs/:taskLogId/task-log-items/:id/invoices' element={<Dashboard content={<InvoiceTable />} />} />
                  <Route exact path='/dashboard/task-logs/:taskLogId/task-log-items/:taskLogItemId/invoices/:id' element={<Dashboard content={<Invoice />} />} />
                  <Route exact path='/dashboard/storage/products' element={<Dashboard content={<ProductTable />} />} />
                  <Route exact path='/dashboard/storage/logs' element={<Dashboard content={<StorageLogTable />} />} />
                  <Route exact path='/dashboard/storage/products/:id' element={<Dashboard content={<ProductEditor />} />} />
                  <Route exact path='/dashboard/storage/products/:productId/item/:id' element={<Dashboard content={<ProductItemEditor />} />} />
                  <Route exact path='/dashboard/storage/move' element={<Dashboard content={<ProductMoving />} />} />
                  <Route exact path='/dashboard/storage/substorages' element={<Dashboard content={<SubStorageTable />} />} />
                  <Route exact path='/dashboard/storage/substorages/create' element={<Dashboard content={<SubStorageEditor />} />} />
                  <Route exact path='/dashboard/storage/substorages/:id' element={<Dashboard content={<SubStorageEditor />} />} />
                  <Route exact path='/dashboard/projects' element={<Dashboard content={<ProjectTable />} />} />
                  <Route exact path='/dashboard/projects/create' element={<Dashboard content={<ProjectEditor />} />} />
                  <Route exact path='/dashboard/projects/:id' element={<Dashboard content={<ProjectEditor />} />} />
                  <Route exact path='/dashboard/project-tasks/projects' element={<Dashboard content={<ProjectTaskView />} />} />
                  <Route exact path='/dashboard/project-tasks/projects/:id' element={<Dashboard content={<ProjectTaskTable />} />} />
                  <Route exact path='/dashboard/project-tasks/projects/:projectId/tasks/create' element={<Dashboard content={<ProjectTaskEditor />} />} />
                  <Route exact path='/dashboard/project-tasks/projects/:projectId/tasks/:id' element={<Dashboard content={<ProjectTaskEditor />} />} />
                  <Route exact path='/dashboard/project-tasks/projects/:projectId/tasks/:projectTaskId/sub-tasks/create' element={<Dashboard content={<ProjectSubTaskEditor />} />} />
                  <Route exact path='/dashboard/project-tasks/projects/:projectId/tasks/:projectTaskId/sub-tasks/:id'  element={<Dashboard content={<ProjectSubTaskEditor />} />} />
                  <Route exact path='/dashboard/task-types' element={<Dashboard content={<TaskTypeTable />} />} />
                  <Route exact path='/dashboard/task-types/create' element={<Dashboard content={<TaskTypeEditor />} />} />
                  <Route exact path='/dashboard/task-types/:id' element={<Dashboard content={<TaskTypeEditor />} />} />
                  <Route exact path='/dashboard/users' element={<Dashboard content={<UserTable />} />} />
                  <Route exact path='/dashboard/users/register' element={<Dashboard content={<UserEditor />} />} />
                  <Route exact path='/dashboard/users/:id' element={<Dashboard content={<UserEditor />} />} />
                  <Route exact path='/dashboard/vehicles' element={<Dashboard content={<VehicleTable />} />} />
                  <Route exact path='/dashboard/vehicles/create' element={<Dashboard content={<VehicleEditor />} />} />
                  <Route exact path='/dashboard/vehicles/:id' element={<Dashboard content={<VehicleEditor />} />} />
                  <Route exact path='/dashboard/vehicles/:id/logs' element={<Dashboard content={<VehicleLogTable />} />} />
                  <Route exact path='/dashboard/vehicles/:id/logs/create' element={<Dashboard content={<VehicleLogEditor />} />} />
                  <Route exact path='/dashboard/vehicles/:id/logs/:id' element={<Dashboard content={<VehicleLogEditor />} />} />
                  <Route exact path='/dashboard/global-payment-modifiers' element={<Dashboard content={<GlobalPaymentModifierTable />} />} />
                  <Route exact path='/dashboard/global-payment-modifiers/:id' element={<Dashboard content={<GlobalPaymentModifierEditor />} />} />
                  <Route exact path='/dashboard/payrolls' element={<Dashboard content={<PayrollTable />} />} />
                  <Route exact path='/dashboard/payrolls/:userId/year/:year/month/:month' element={<Dashboard content={<Payroll />} />} />
                  <Route exact path='/dashboard/payrolls/:userId/year/:year/month/:month/details' element={<Dashboard content={<SalaryDetails />} />} />
                  <Route exact path='/dashboard/invoices' element={<Dashboard content={<InvoiceList />} />} />
                  <Route exact path='/dashboard/simple-invoices' element={<Dashboard content={<SimpleInvoiceTable />} />} />
                  <Route exact path='/dashboard/simple-invoices/create' element={<Dashboard content={<SimpleInvoiceEditor />} />} />
                  <Route exact path='/dashboard/simple-invoices/:id' element={<Dashboard content={<SimpleInvoiceEditor />} />} />
                  <Route exact path='/dashboard/notes' element={<Dashboard content={<NoteTable />} />} />
                  <Route exact path='/dashboard/notes/create' element={<Dashboard content={<NoteEditor />} />} />
                  <Route exact path='/dashboard/notes/:id' element={<Dashboard content={<NoteEditor />} />} />
                  <Route exact path='/dashboard/tools' element={<Dashboard content={<ToolsTable />} />} />
                  <Route exact path='/dashboard/tools/:id' element={<Dashboard content={<ToolEditor />} />} />
                  <Route exact path='/dashboard/tools/create' element={<Dashboard content={<ToolEditor />} />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Router>
            </div>
          </ConfirmationDialogProvider>
        </SnackbarProvider>
      </TitleProvider>
    </>
  );
}

function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/dashboard/task-logs');
  }, [navigate]);
}

export function toString(datetime) {
    if (datetime) {
        const year = datetime.$y;
        const month = String(datetime.$M + 1).padStart(2, '0');
        const day = String(datetime.$D).padStart(2, '0');
        const hours = String(datetime.$H).padStart(2, '0');
        const minutes = String(datetime.$m).padStart(2, '0');
        const seconds = String(datetime.$s).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    } else return null
}
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { Add, Delete, Edit, GetApp, KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import toolService from '../../services/ToolService';
import { useTitle } from '../dashboard/TitleContext';
import { useConfirmationDialog } from '../../util/ConfirmationDialogProvider';
import { useSnackbar } from '../../util/SnackbarProvider';
import { useState } from 'react';

const columns = [
    { id: 'name', label: 'Név' },
    { id: 'description', label: 'Leírás' },
    { id: 'updatedAt', label: 'Módosítva' },
    { id: 'documentNames', label: 'Dokumentumok száma' }
];

const LOCAL_STORAGE_ITEM_NAME = "tool-rows-per-page"
const LOCAL_STORAGE_SEARCH_PARAMS = "tool-search-params"

export default function ToolsTable() {

    useTitle([{ name: 'Eszközök' }])

    const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })
    const [searchParams, setSearchParams] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_PARAMS)))
    const [expanded, setExpanded] = useState(true);
    const { sendAlertMessage } = useSnackbar()
    const { openConfirmationDialog } = useConfirmationDialog()

    useEffect(() => {
        retrieveTools()
    }, [tableData.page, tableData.rowsPerPage, searchParams]);

    const retrieveTools = () => {
        toolService.search(buildQueryParams(), (response, error) => {
            if (response) {
                setTableData((prev) => ({ ...prev, totalCount: response.totalElements, rows: response.content }))
            } else sendAlertMessage('Termékek lekérése sikertelen!', 'error')
        })
    }

    function buildQueryParams() {
        return {
            page: tableData.page,
            size: tableData.rowsPerPage,
            ...searchParams
        }
    }

    const deleteTool = (id) => {
        toolService.deleteTool(id, (response, error) => {
            if (!error) {
                retrieveTools()
                sendAlertMessage('Eszköz sikeresen törölve!')
            }
            else sendAlertMessage('Eszköz törlése sikertelen!', 'error')
        })
    }

    const onSearchParamsChange = (event) => {
        const { name, value } = event.target
        setSearchParams((prev) => ({
          ...prev,
          [name]: value
        }))
      }
    
      const clear = () => {
        if (searchParams != null) sendAlertMessage('Szűrők alaphelyzetbe állítva', 'info')
        setSearchParams(undefined)
      }
    
      useEffect(() => {
        if (searchParams) localStorage.setItem(LOCAL_STORAGE_SEARCH_PARAMS, JSON.stringify(searchParams));
        else localStorage.removeItem(LOCAL_STORAGE_SEARCH_PARAMS)
      }, [searchParams]);

    const navigate = useNavigate();

    return (
        <>
            <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
                    Keresési Feltételek
                </AccordionSummary>
                <AccordionDetails className="filter-panel">
                    <Grid container columns={20} spacing={2} padding={"10px"}>
                        <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Termék Neve"
                                name="name"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.name) || ""}
                            />
                        </Grid>

                        <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Gyártó"
                                name="description"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.description) || ""}
                            />
                        </Grid>

                        <Grid item lg={8} md={8} xs={20}></Grid>

                        <Grid item md={4} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
                            <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                                    <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/tools/create")}>
                                        <Add fontSize="large" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                tableData.rows.map((row) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id} >
                                            {columns.map((column) => {
                                                let value = row[column.id];
                                                if (column.id === 'documentNames') value = row[column.id]?.length
                                                if (column.id === 'description') value = <LinkifyText text={row[column.id]} />
                                                return (
                                                    <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/tools/" + row.id)} sx={{ cursor: "pointer" }}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right'>
                                                <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                    onClick={() => openConfirmationDialog(
                                                        'Biztosan törölni szeretnéd az alábbi eszközt?',
                                                        <>{row.name}<br />{row.description.length > 50 ? row.description.slice(0, 50) + '...' : row.description}</>,
                                                        () => deleteTool(row.id)
                                                    )}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={tableData.totalCount}
                    rowsPerPage={parseInt(tableData.rowsPerPage)}
                    page={tableData.page}
                    onPageChange={(event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))}
                    onRowsPerPageChange={(event) => {
                        setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
                        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
                    }}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    );
}

function LinkifyText({ text }) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const lines = text.split('\n');

    return (
        <div>
            {lines.map((line, lineIndex) => (
                <p key={lineIndex} style={{ margin: "0px" }}>
                    {line.split(urlRegex).map((part, index) => {
                        if (urlRegex.test(part)) {
                            return (
                                <a
                                    key={index}
                                    href={part}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'blue', textDecoration: 'underline' }}
                                >
                                    {part}
                                </a>
                            );
                        }
                        return part;
                    })}
                </p>
            ))}
        </div>
    );
}
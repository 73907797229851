import * as React from 'react';
import { API_URL } from '../../App';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authService from '../../services/AuthService';
import { useTitle } from '../dashboard/TitleContext';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Invoice() {

    const urlParts = window.location.pathname.split('/')
    const taskLogId = urlParts[3];
    const taskLogItemId = urlParts[5];
    const inVoiceId = urlParts[7];

    useTitle([
        {name: 'Munkaidő', url: '/dashboard/task-logs'},
        {name: 'Bejegyzés', url: '/dashboard/task-logs/' + taskLogId },
        {name: 'Számlák', url: '/dashboard/task-logs/' + taskLogId + '/task-log-items/' + taskLogItemId + '/invoices' },
        {name: 'Számla', url: '/dashboard/task-logs/' + taskLogId + '/task-log-items/' + taskLogItemId + '/invoices/' + inVoiceId }
    ])

    const [image, setImage] = useState();

    useEffect(() => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/invoices/" + inVoiceId,
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => setImage(URL.createObjectURL(new Blob([response.data]))))
            .catch(error => console.error(error));
    }, [inVoiceId]);

    const navigate = useNavigate()

    return (
        <div style={{ textAlign: "center", width: "100%" }}>
            <Button type="button" variant="contained" color="error"
                onClick={() => navigate(-1)}
                style={{ float: "left" }}>
                Vissza
            </Button>
            {image ? <img src={image} alt=""/> : <CircularProgress sx={{margin: "100px"}}/>}
        </div>
    );
}

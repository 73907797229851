import Service from "./Service"

class TaskTypeService extends Service {

    findById = (id, callback) => this.get("/task-types/" + id, callback)

    search = (callback) => this.get("/task-types", callback)

    getSelectors = (callback) => this.get("/task-types/selectors", callback)

    createTaskType = (tasktypeRequest, callback) => this.post("/task-types", tasktypeRequest, callback)

    updateTaskType = (tasktypeId, tasktypeRequest, callback) => this.patch("/task-types/" + tasktypeId, tasktypeRequest, callback)

    deleteTaskType = (tasktypeId, callback) => this.del("/task-types/" + tasktypeId, callback)
}
const taskTypeService = new TaskTypeService()
export default taskTypeService
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Badge, Box, Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Add, Delete, Edit, Forum, GetApp, KeyboardArrowDown, SnoozeTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import projectTaskService from '../../services/ProjectTaskService';
import ConfirmationDialog from '../common/ConfirmationDialog';
import projectService from '../../services/ProjectService';
import taskTypeService from '../../services/TaskTypeService';
import PercentageProgressBar from '../common/PercentageProgressBar';
import vehicleService from '../../services/VehicleService';
import userService from '../../services/UserService';
import { useTitle } from '../dashboard/TitleContext';
import { useState } from 'react';
import { limit } from '../../util/StringUtils';
import { useSnackbar } from '../../util/SnackbarProvider';
import { useConfirmationDialog } from '../../util/ConfirmationDialogProvider';

const columns = [
    { id: 'taskTypeName', label: 'Feladatkör' },
    { id: 'description', label: 'Leírás' },
    { id: 'subTaskCount', label: 'Alfeladatok Száma' },
    { id: 'priority', label: 'Prioritás' },
    { id: 'status', label: 'Státusz' },
    { id: 'earliestScheduleDate', label: 'Legkorábbi Ütemezés' },
    { id: 'earliestDeadline', label: 'Legkorábbi Határidő' },
    { id: 'vehicles', label: 'Járművek' },
    { id: 'users', label: 'Munkavállalók' },
    { id: 'updatedAt', label: 'Módosítva' },
    { id: 'lastModifiedBy', label: 'Módosította' }
];

const priorities = [
    { id: "EXTRA_IMPORTANT", name: "Extra Fontos" },
    { id: "IMPORTANT", name: "Fontos" },
    { id: "NORMAL", name: "Normál" },
    { id: "NOT_IMPORTANT", name: "Nem Fontos" }
]

const statuses = [
    { id: "NOT_STARTED", name: "Nincs elkezdve" },
    { id: "IN_PROGRESS", name: "Folyamatban" }
]

export default function ProjectTaskTable() {

    const projectId = window.location.pathname.split('/')[4]
    const [projectName, setProjectName] = useState('Projekt');

    useEffect(() => {
        projectService.findById(projectId, (response, error) => {
            if (response) setProjectName(response.name);
        });
    }, [projectName]);

    useTitle([
        { name: 'Projekt Feladatok', url: '/dashboard/project-tasks/projects' },
        { name: projectName, url: '/dashboard/project-tasks/projects/' + projectId },
    ], projectName);

    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("project-tasks-rows-per-page") || 25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [searchParams, setSearchParams] = React.useState(JSON.parse(localStorage.getItem("project-task-search-params")) || {})
    const [expanded, setExpanded] = React.useState(true);
    const { sendAlertMessage } = useSnackbar()
    const { openConfirmationDialog } = useConfirmationDialog()

    useEffect(() => {
        retrieveList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchParams]);

    const handleExtension = () => {
        setExpanded(!expanded);
    };

    const onSearchParamsChange = (name, value) => {
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value
        }))
    }

    const clear = () => {
        setSearchParams({})
    }

    useEffect(() => {
        if (searchParams) localStorage.setItem("project-task-search-params", JSON.stringify(searchParams));
        else localStorage.removeItem("project-task-search-params")
    }, [searchParams]);


    function retrieveList() {
        setRows(null)
        projectTaskService.search(projectId, buildQueryParams(),
            (response, error) => {
                console.log(response)
                if (response) {
                    const result = response.content.map(projectTask => ({
                        ...projectTask,
                        status: statuses.find(s => s.id === projectTask.status)?.name,
                        priority: priorities.find(s => s.id === projectTask.priority)?.name
                    }));
                    setTotalCount(response.totalElements)
                    setRows(result);
                }
            }
        );
    }

    function buildQueryParams() {
        return {
            page: page,
            size: rowsPerPage,
            ...(searchParams.priorities && searchParams.priorities.length > 0 && { priorities: searchParams.priorities.map(p => p.id) }),
            ...(searchParams.statuses && searchParams.statuses.length > 0 && { statuses: searchParams.statuses.map(s => s.id) }),
            ...(searchParams.description && { description: searchParams.description }),
            ...(searchParams.taskType && { taskTypeId: searchParams.taskType.id }),
            ...(searchParams.vehicle && { vehicleId: searchParams.vehicle.id }),
            ...(searchParams.users && { userIds: searchParams.users.map(user => user.id) }),
            ...(searchParams.fromDate && { fromDate: searchParams.fromDate }),
            ...(searchParams.toDate && { toDate: searchParams.toDate }),
            ...(searchParams.orderBy && { orderBy: searchParams.orderBy }),
            ...(searchParams.onlyNonFinished && { onlyNonFinished: searchParams.onlyNonFinished }),
            ...(searchParams.onlyWithSubTasks && { onlyWithSubTasks: searchParams.onlyWithSubTasks })
        }
    }

    const [vehicles, setVehicles] = React.useState([]);
    useEffect(() => {
        vehicleService.search(
            (response, error) => {
                if (response) setVehicles(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);


    const [users, setUsers] = React.useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else console.log(error);
            },);
    }, []);

    const onUsersChange = (event) => {
        const { value } = event.target
        const newSelection = typeof value === 'string' ? value.split(',') : value;
        const uniqueSelection = newSelection.reduce((acc, user) => {
            const exists = acc.find(item => item.id === user.id);
            if (exists) return acc.filter(item => item.id !== user.id);
            else return [...acc, user];
        }, []);

        setSearchParams((prev) => ({
            ...prev,
            users: uniqueSelection,
        }));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = event.target.value
        setRowsPerPage(event.target.value);
        localStorage.setItem("project-tasks-rows-per-page", rowsPerPage)
        setPage(0);
    };

    const navigate = useNavigate();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
            },
        },
    };

    function getStyles(priority, priorities) {
        if (priorities && priorities.map(p => p.name).indexOf(priority) === -1) return
        return {
            backgroundColor: "#2e7d32",
            color: "white"
        };
    }

    const onMultipleSelectChange = (event) => {
        const { value, name } = event.target
        const newSelection = typeof value === 'string' ? value.split(',') : value;
        const uniqueSelection = newSelection.reduce((acc, priority) => {
            const exists = acc.find(item => item.id === priority.id);
            if (exists) return acc.filter(item => item.id !== priority.id);
            else return [...acc, priority];
        }, []);

        setSearchParams((prev) => ({
            ...prev,
            [name]: uniqueSelection,
        }));
    };

    const onChangeCheckbox = (event) => {
        const { checked, name } = event.target;
        setSearchParams((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const deleteTask = (id) => {
        projectTaskService.deleteProjectTask(id, (response, error) => {
          if (!error) {
            retrieveList()
            sendAlertMessage('Feladat sikeresen törölve!')
          }
          else sendAlertMessage('Feladat törlése sikertelen!', 'error')
        })
      }
    

    return (
        <>
            <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => handleExtension()}>
                <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
                    Keresési Feltételek
                </AccordionSummary>
                <AccordionDetails className="filter-panel">
                    <Grid container columns={20} spacing={2} padding={"10px"}>

                    <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px", marginTop: "8px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Feladat Leírása"
                                name="name"
                                autoComplete="off"
                                onChange={(event) => onSearchParamsChange("description", event.target.value)}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.description) || ""}
                            />
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "8px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="priorities-label">Prioritás</InputLabel>
                                <Select
                                    labelId="priorities-label"
                                    multiple
                                    value={searchParams.priorities || []}
                                    onChange={onMultipleSelectChange}
                                    name="priorities"
                                    variant="outlined"
                                    input={<OutlinedInput id="select-multiple-chip" label="Prioritás" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                            {selected.map((value) => (
                                                <Chip key={value.id} label={value.name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {priorities.map((priority) => (
                                        <MenuItem
                                            key={priority.id}
                                            value={priority}
                                            style={getStyles(priority.name, searchParams.priorities)}
                                        >
                                            {priority.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "8px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="statuses-label">Státusz</InputLabel>
                                <Select
                                    labelId="statuses-label"
                                    multiple
                                    value={searchParams.statuses || []}
                                    onChange={onMultipleSelectChange}
                                    name="statuses"
                                    variant="outlined"
                                    input={<OutlinedInput id="select-multiple-chip" label="Státusz" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                            {selected.map((value) => (
                                                <Chip key={value.id} label={value.name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {statuses.map((status) => (
                                        <MenuItem
                                            key={status.id}
                                            value={status}
                                            style={getStyles(status.name, searchParams.statuses)}
                                        >
                                            {status.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={5} md={20} xs={20} sx={{ padding: "0px", marginTop: "8px" }}>
                            <Autocomplete
                                value={(searchParams && searchParams.vehicle) || null}
                                margin="normal"
                                id="vehicle"
                                onChange={(event, newValue) => onSearchParamsChange("vehicle", newValue)}
                                options={vehicles}
                                getOptionLabel={(vehicle) => vehicle.licensePlate}
                                renderInput={(params) => (
                                    <TextField {...params} label="Jármű" />
                                )}
                            />
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="users-label">Dolgozók</InputLabel>
                                <Select
                                    labelId="users-label"
                                    multiple
                                    value={searchParams.users || []}
                                    onChange={onUsersChange}
                                    label="Dolgozók"
                                    variant="outlined"
                                    input={<OutlinedInput id="select-multiple-chip" label="Dolgozók" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                            {selected.map((value) => (
                                                <Chip key={value.id} label={value.name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {users.map((user) => (
                                        <MenuItem
                                            key={user.id}
                                            value={user}
                                            style={getStyles(user.name, searchParams.users)}
                                        >
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="date"
                                label="-tól"
                                autoComplete="off"
                                onChange={(event) => onSearchParamsChange("fromDate", event.target.value)}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.fromDate) || "-1"}
                            />
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="date"
                                label="-ig"
                                autoComplete="off"
                                onChange={(event) => onSearchParamsChange("toDate", event.target.value)}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.toDate) || "-1"}
                            />
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ marginLeft: "0px", marginTop: "0px" }} fullWidth>
                                <InputLabel id="order-by-label">Rendezési Szempont</InputLabel>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    labelId="order-by-label"
                                    label="Rendezési Szempont"
                                    value={(searchParams && searchParams.orderBy) || null}
                                    onChange={(event) => onSearchParamsChange("orderBy", event.target.value)}
                                    sx={{ padding: "0px" }}
                                >
                                    <MenuItem value={"earliest_deadline"}>Legkorábbi Határidő</MenuItem>
                                    <MenuItem value={"earliest_schedule_date"}>Legkorábbi ütemezés</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={2} lg={5} md={10} xs={10}>
                            <FormControlLabel control={<Checkbox
                                margin="normal"
                                fullWidth
                                size="medium"
                                onChange={onChangeCheckbox}
                                name="onlyNonFinished"
                                checked={searchParams.onlyNonFinished}
                            />} label={<span style={{ fontSize: "16px" }}>Csak befejezetlenek</span>} />
                        </Grid>

                        <Grid item xl={3} lg={5} md={10} xs={10}>
                            <FormControlLabel control={<Checkbox
                                margin="normal"
                                fullWidth
                                size="medium"
                                onChange={onChangeCheckbox}
                                name="onlyWithSubTasks"
                                checked={searchParams.onlyWithSubTasks}
                            />} label={<span style={{ fontSize: "16px" }}>Feladattal rendelkezők</span>} />
                        </Grid>

                        <Grid item lg={10} xs={20}></Grid>

                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
                            <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "135px" }} align='right' padding='none'>
                                    <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                                        <GetApp fontSize="large" />
                                    </Button>
                                    <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/project-tasks/projects/" + projectId + "/tasks/create")}>
                                        <Add fontSize="large" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                rows.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column, index) => {
                                                let value = column.subId != null ? (row[column.id] == null ? null : row[column.id][column.subId]) : row[column.id];
                                                if (column.id === 'users')
                                                    value = row.users.join(", ")
                                                if (column.id === 'progressLevel')
                                                    value = <PercentageProgressBar fullWidth percentage={row[column.id]} narrow readonly color={"rgba(0,255,0,0.4)"} align='center' />
                                                else if (column.id === 'vehicles')
                                                    value = row.vehicles.join(", ")
                                                return (
                                                    <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/project-tasks/projects/" + projectId + "/tasks/" + row.id)} sx={{ cursor: "pointer", padding: "0px 10px" }}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right'>
                                                <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                    onClick={() => openConfirmationDialog(
                                                        'Biztosan törölni szeretnéd az alábbi feladatot?',
                                                        <>{row.taskTypeName}<br/>{limit(row.description, 50)}</>,
                                                        () => deleteTask(row.id)
                                                    )}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={parseInt(rowsPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    );
}

import { Button, Container, TextField, Alert, Grid, Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';

export default function TaskTypeEditorTemplate(props) {

	const { id, onChange, create, update, message } = props.data

	const { name, paymentModifier, paymentCategory, createdAt, updatedAt } = props.data.tasktype

	const navigate = useNavigate();

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : create}>
						<Grid container spacing={2} columns={20}>
							{createButton("name", "Feladatkör Neve", name, onChange, true, true)}
							{createButton("paymentModifier", "Fizetési Módosító", paymentModifier, onChange)}

							<Grid item lg={4} xs={20}>
							<FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
								<InputLabel id="paymentCategoryLabel">Fizetési Kategória</InputLabel>
								<Select
									variant="outlined"
									required
									fullWidth
									id="paymentCategory"
									name="paymentCategory"
									label="Fizetési Kategória"
									labelId="paymentCategoryLabel"
									value={paymentCategory}
									onChange={onChange}
									sx={{ textAlign: "left" }}
								>
									<MenuItem value={"PROGRAMMING_AND_PLANNING"}>Programozás és Tervezés</MenuItem>
									<MenuItem value={"ELECTRICAL_INSTALLATION"}>Villanyszerelés</MenuItem>
									<MenuItem value={"ACCOMMODATION"}>Szállás</MenuItem>
									<MenuItem value={"CATEGORY_TYPE_4"}>Kategória 4</MenuItem>
									<MenuItem value={"CATEGORY_TYPE_5"}>Kategória 5</MenuItem>
									<MenuItem value={"TRAVELLING"}>Utazás</MenuItem>
									<MenuItem value={"OTHER"}>Egyéb</MenuItem>
								</Select>
								</FormControl>
							</Grid>

							{id !==undefined &&
								<>
									<Grid item lg={20} md={20} xs={20}>
										<Divider />
									</Grid>

									{createButton("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
									{createButton("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
									<Grid item lg={10} xs={20}></Grid>
								</>

							}



							{message &&
								<Grid item lg={20} xs={20}>
									<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
								</Grid>
							}


							<Grid item lg={2} md={5} xs={9}>
								<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/task-types")}>
									Vissza
								</Button>
							</Grid>

							<Grid item lg={16} md={10} xs={2}></Grid>

							<Grid item lg={2} md={5} xs={9}>
								<Button type="submit" fullWidth variant="contained" color="success">
									Mentés
								</Button>
							</Grid>

						</Grid>
					</form>
				</div>

			</Container>
		</div>
	)
}

function createButton(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
	return (<Grid item lg={5} md={10} xs={20}>
		<TextField
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={label}
			name={name}
			type="text"
			autoFocus={autoFocus}
			autoComplete="off"
			onChange={onChange}
			value={value}
			disabled={disabled}
		/>
	</Grid>)
}
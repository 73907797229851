import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Checkbox, CircularProgress, Container, Divider, FormControlLabel, Grid, TextField } from '@mui/material';
import payrollService from '../../../services/PayrollService';
import { useTitle } from '../../dashboard/TitleContext';
import { useSnackbar } from '../../../util/SnackbarProvider';
import { useState } from 'react';

export default function SalaryDetails() {

    const urlParts = window.location.pathname.split('/')
    const userId = urlParts[3]
    const year = urlParts[5]
    const month = urlParts[7]

    useTitle([
        { name: 'Bérjegyzék Lista', url: '/dashboard/payrolls' },
        { name: 'Munkavállalói Jegyzék', url: '/dashboard/payrolls/' + userId + '/year/' + year + '/month/' + month },
        { name: 'Részletek Szerkesztése', url: '/dashboard/payrolls/' + userId + '/year/' + year + '/month/' + month + '/details' }
    ])

    const [salaryDetails, setSalaryDetails] = useState()
    const [message, setMessage] = useState('')
    const { sendAlertMessage } = useSnackbar()
    const [waitingForServer, setWaitingForServer] = useState(false)

    useEffect(() => {
        payrollService.getSalaryDetails(userId, year, month,
            (response, error) => {
                if (!error) {
                    setSalaryDetails((prev) => ({
                        ...prev,
                        ...response,
                    }));
                } else sendAlertMessage('Részletek lekérése sikertelen', 'error')
            }
        );
    }, [userId, year, month]);

    const update = (e) => {
        e.preventDefault()
        salaryDetails.userId = userId
        salaryDetails.year = year
        salaryDetails.month = month
        setWaitingForServer(true)
        payrollService.saveSalaryDetails(salaryDetails, (response, error) => {
            if (!error) {
                sendAlertMessage('Részletek mentése sikeres')
                navigate(-1)
            }
            else setMessage(error)
            setWaitingForServer(false)
        })
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setSalaryDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onChangeCheckbox = (event) => {
        const { checked, name } = event.target;
        setSalaryDetails((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const navigate = useNavigate();

    return (
        <>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={update}>
                        <Grid container spacing={2} columns={20}>
                            {salaryDetails == null ? <Grid item xs={20}><CircularProgress sx={{ margin: "100px" }} /> </Grid> :
                                <>
                                    {createTextField("compensationAmount", "Kompenzáció Összege", salaryDetails.compensationAmount, onChange, false, true)}

                                    <Grid item xl={5} lg={5} md={15} xs={15} style={{ textAlign: "left" }}>
                                        <FormControlLabel control={<Checkbox
                                            variant="outlined"
                                            margin="normal"
                                            size="large"
                                            id="paid"
                                            label="paid"
                                            name="paid"
                                            onChange={onChangeCheckbox}
                                            checked={salaryDetails.paid}
                                            value={salaryDetails.paid}
                                        />} label="Kifizetve"
                                            style={{ paddingTop: "20px" }} />
                                    </Grid>

                                    <Grid item lg={10} md={20} xs={20}></Grid>

                                    <Grid item lg={5} md={10} xs={20}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="compensationComment"
                                            label="Megjegyzés"
                                            name="compensationComment"
                                            type="text"
                                            autoComplete="off"
                                            onChange={onChange}
                                            value={salaryDetails.compensationComment}
                                            sx={{ marginTop: "0px" }}
                                            multiline
                                            rows={5}
                                        />
                                    </Grid>

                                    {salaryDetails.createdAt !== undefined &&
                                        <>
                                            <Grid item lg={20} md={20} xs={20}>
                                                <Divider />
                                            </Grid>

                                            {createTextField("createdAt", "Létrehozva", salaryDetails.createdAt, onChange, false, false, true)}
                                            {createTextField("updatedAt", "Módosítva", salaryDetails.updatedAt, onChange, false, false, true)}
                                            <Grid item lg={10} xs={20}></Grid>
                                        </>

                                    }

                                    {message &&
                                        <Grid item lg={20} xs={20}>
                                            <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                        </Grid>
                                    }

                                    <Grid item lg={20} md={20} xs={20}></Grid>
                                    <Grid item lg={2} md={5} xs={9}>
                                        <Button type="button" variant="contained" color="error" onClick={() => navigate(-1)} style={{ width: "150px", marginTop: "10px" }}>
                                            Vissza
                                        </Button>
                                    </Grid>
                                    <Grid item lg={16} md={10} xs={2}></Grid>

                                    <Grid item lg={2} md={5} xs={9}>
                                        {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                            Mentés
                                        </Button>}
                                    </Grid>
                                </>}
                        </Grid>
                    </form>
                </div>
            </Container >
        </>
    );
}

function createTextField(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
    return (<Grid item lg={5} md={10} xs={20}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type="text"
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value === undefined ? "" : value}
            disabled={disabled}
        />
    </Grid>)
}
import { Close, Delete } from "@mui/icons-material";
import { Button, Container, TextField, Alert, Grid, Divider, CircularProgress, styled, Table, TableCell, TableRow, TableBody, Link } from "@mui/material"
import React from "react"

const VisuallyHiddenInput = styled('input')({
    width: 0
});

export default function ToolEditorTemplate(props) {

    const createInputField = ({ name, label, required = false, autoFocus = false, disabled = false, multiline = false }) =>
        <Grid item lg={5} md={10} xs={20}>
            <TextField
                variant="outlined"
                margin="normal"
                required={required}
                fullWidth
                id={name}
                label={label}
                name={name}
                type="text"
                autoFocus={autoFocus}
                autoComplete="off"
                onChange={props.onChange}
                value={props.tool[name] || ""}
                disabled={disabled}
                multiline={multiline}
                minRows={multiline == null ? undefined : 5}
            />
        </Grid>

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={props.id ? props.update : props.create}>
                        {(props.tool.id != null && props.tool.name == null) ? <CircularProgress sx={{ margin: "100px" }} /> :
                            <Grid container spacing={2} columns={20}>
                                {createInputField({ name: "name", label: "Eszköz Neve", required: "true", autoFocus: "true" })}
                                {createInputField({ name: "description", label: "Leírás", required: "true", multiline: "true" })}

                                <Grid item lg={2} md={10} xs={20}>
                                    <Button component="label" variant="contained" color='info' style={{ float: "left", marginTop: "16px" }}>
                                        Fájlok hozzáadása
                                        <VisuallyHiddenInput type="file" multiple onChange={props.handleFileChange} />
                                    </Button>


                                </Grid>

                                <Grid item lg={8} md={10} xs={20}>
                                    <Table>
                                        <TableBody>
                                            {props.tool?.documentNames?.map((name, index) => (
                                                <TableRow>
                                                    <TableCell key={index} sx={{ textAlign: "left", padding: "5px" }}>
                                                        <Link onClick={() => props.download(name)} sx={{textDecoration: "none", cursor: "pointer", fontWeight: "bold"}}>{name}</Link>
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>
                                                        <Button onClick={() => props.removeDocumentName(name)} variant="text" color='error' sx={{ marginLeft: "10px", padding: "5px", minWidth: "0px" }}>
                                                            <Delete />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {props.files.map((file, index) => (
                                                <TableRow>
                                                    <TableCell key={index} sx={{ textAlign: "left", padding: "0px 0px 0px 5px" }}>
                                                        {file.name}
                                                    </TableCell>
                                                    <TableCell key={index} sx={{ textAlign: "left", padding: "0px" }}>
                                                        {(new Intl.NumberFormat().format((file.size / 1024 / 1024).toFixed(2))).replace(',', '.')} MB
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button onClick={() => props.handleRemoveFile(index)} variant="text" color='error' sx={{ marginLeft: "10px", padding: "5px", minWidth: "0px" }}>
                                                            <Close />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>

                                {props.id !== undefined &&
                                    <>
                                        <Grid item lg={20} md={20} xs={20}>
                                            <Divider />
                                        </Grid>

                                        {createInputField({ name: "createdAt", label: "Létrehozva", disabled: true })}
                                        {createInputField({ name: "updatedAt", label: "Módosítva", disabled: true })}
                                        <Grid item lg={10} xs={20}></Grid>
                                    </>

                                }

                                {props.message &&
                                    <Grid item lg={20} xs={20}>
                                        <Alert severity="error" variant="standard" style={{ color: "red" }}>{props.message}</Alert>
                                    </Grid>
                                }

                                <Grid item lg={20} md={20} xs={20}></Grid>

                                <Grid item lg={2} md={5} xs={9}>
                                    <Button type="button" fullWidth variant="contained" color="error" onClick={() => props.navigate("/dashboard/tools")}>
                                        Vissza
                                    </Button>
                                </Grid>

                                <Grid item lg={16} md={10} xs={2}></Grid>

                                <Grid item lg={2} md={5} xs={9}>
                                    {props.waitingForServer ? <CircularProgress /> : <Button type="submit" fullWidth variant="contained" color="success">
                                        Mentés
                                    </Button>}
                                </Grid>

                            </Grid>}
                    </form>
                </div>

            </Container>
        </div>
    )
}

function createInputField(name, label, value, onChange, required = false, autoFocus = false, disabled = false, multiline = false) {
    return (<Grid item lg={5} md={10} xs={20}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type="text"
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value || ""}
            disabled={disabled}
            multiline={multiline}
        />
    </Grid>)
}
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Select, Snackbar, TextField, Tooltip } from '@mui/material';
import { Add, AttachMoney, Delete, Done, KeyboardArrowDown, MoneyOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../dashboard/TitleContext';
import { useState } from 'react';
import { useSnackbar } from '../../util/SnackbarProvider';
import { useConfirmationDialog } from '../../util/ConfirmationDialogProvider';
import simpleInvoiceService from '../../services/SimpleInvoiceService';
import { parseToMoney } from '../../util/StringUtils';
import authService from '../../services/AuthService';
import axios from 'axios';
import { API_URL } from '../../App';
import { saveAs } from 'file-saver';
import { BarChart } from '@mui/x-charts/BarChart';
import userService from '../../services/UserService';
import simpleInvoicePaymentPartService from '../../services/SimpleInvoicePaymentPartService';

const columns = [
    { id: 'serialNumber', label: 'Azonosító' },
    { id: 'name', label: 'Név' },
    { id: 'sender', label: 'Feladó' },
    { id: 'receiver', label: 'Címzett' },
    { id: 'amount', label: 'Összeg' },
    { id: 'dateOfInvoice', label: 'Keltezés' },
    { id: 'deadline', label: 'Határidő' },
    { id: 'transactionType', label: 'Típus' },
    { id: 'category', label: 'Kategória' },
    { id: 'paymentType', label: 'Fizetés Módja' },
    { id: 'invoiceType', label: 'Számla Típusa' },
    { id: 'paidBy', label: 'Teljesítette', align: "center" },
    { id: 'paidAt', label: 'Teljesítés Dátuma', align: "center" },
    { id: 'documentNames', label: 'Dokumentumok' }
];

const invoiceTypes = [
    { id: "INVOICE", name: "Számla" },
    { id: "ADVANCE_INVOICE", name: "Előlegszámla" },
    { id: "FEE_REQUEST", name: "Díjbekérő" },
    { id: "RECEIPT", name: "Nyugta" }
]

const paymentTypes = [
    { id: "CASH", name: "Készpénz" },
    { id: "TRANSFER", name: "Átutalás" },
    { id: "CREDIT_CARD", name: "Bankkártya" },
    { id: "CASH_ON_DELIVERY", name: "Utánvét" }
]

const transactionTypes = [
    { id: "EXPENSE", name: "Kiadás" },
    { id: "INCOME", name: "Bevétel" }
]

const categories = [
    { id: "GENERAL", name: "Általános" },
    { id: "SALARY_PAYMENT", name: "Bérkifizetés" },
    { id: "VEHICLE_PAYMENT", name: "Jármű" },
    { id: "TAX_PAYMENT", name: "Adó" },
    { id: "OPERATIONAL_COST", name: "Üzemeltetés" },
]

const LOCAL_STORAGE_ITEM_NAME = "simple-invoices-rows-per-page"
const LOCAL_STORAGE_SEARCH_PARAMS = "simple-invoices-search-params"
const DEFAULT_SEARCH_PARAMS = { onlyNonPaid: true, orderBy: 'deadline_asc' }
const TODAY = new Date().toISOString().split('T')[0];

export default function SimpleInvoiceTable() {

    useTitle([{ name: 'Számlák' }])

    const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })
    const { sendAlertMessage } = useSnackbar()
    const { openConfirmationDialog } = useConfirmationDialog()
    const [expanded, setExpanded] = useState(true);
    const [searchParams, setSearchParams] = useState(JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_PARAMS)) || DEFAULT_SEARCH_PARAMS)
    const [stats, setStats] = useState()

    useEffect(() => {
        retrieveSimpleInvoices()
    }, [tableData.page, tableData.rowsPerPage, searchParams]);

    const retrieveSimpleInvoices = () => {
        simpleInvoiceService.findAll(buildQueryParams(), (response, error) => {
            if (response) {

                const result = response.simpleInvoices.content.map(invoice => ({
                    ...invoice,
                    invoiceType: invoiceTypes.find(s => s.id === invoice.invoiceType)?.name,
                    paymentType: paymentTypes.find(s => s.id === invoice.paymentType)?.name,
                    transactionType: transactionTypes.find(s => s.id === invoice.transactionType)?.name,
                    category: categories.find(s => s.id === invoice.category)?.name
                }));
                setTableData((prev) => ({ ...prev, totalCount: response.simpleInvoices.totalElements, rows: result }))
                setStats(response.stats)
            } else sendAlertMessage('Számlák lekérése sikertelen!', 'error')
        })
    }

    const [users, setUsers] = React.useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else console.log(error);
            },
        );
    }, []);

    function buildQueryParams() {
        return {
            page: tableData.page,
            size: tableData.rowsPerPage,
            ...Object.fromEntries(
                Object.entries(searchParams).filter(([_, value]) => value !== null)
            )
        };
    }


    const deleteSimpleInvoice = (id) => {
        simpleInvoiceService.deleteSimpleInvoice(id, (response, error) => {
            if (!error) {
                retrieveSimpleInvoices()
                sendAlertMessage('Számla sikeresen törölve!')
            }
            else sendAlertMessage('Számla törlése sikertelen!', 'error')
        })
    }

    const togglePaid = (id, paid, paidAt, userId) => {
        simpleInvoiceService.togglePaid(id, userId ? { paidAt: paidAt, userId: userId } : null, (response, error) => {
            if (!error) {
                sendAlertMessage(paid ? 'Számla teljesítése visszavonva' : 'Számla teljesítve')
                retrieveSimpleInvoices()
            }
            else sendAlertMessage('Számla teljesítése sikertelen!', 'error')
        })
    }

    const saveAsPaymentPart = (id, formJson) => {
        simpleInvoicePaymentPartService.createPaymentPart(id, { paidAt: formJson.paidAt, userId: formJson.userId, amount: formJson.amount }, (response, error) => {
            if (!error) {
                retrieveSimpleInvoices()
                sendAlertMessage('Számla részleges teljesítése sikeres!')
            }
            else sendAlertMessage(error || 'Számla részleges teljesítése sikertelen!', 'error')
        })
    }

    const navigate = useNavigate();

    const onSearchParamsChange = (event) => {
        const { name, value } = event.target
        setSearchParams((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onChangeCheckbox = (event) => {
        const { checked, name } = event.target;
        setSearchParams((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const clear = () => {
        if (searchParams != DEFAULT_SEARCH_PARAMS) sendAlertMessage('Szűrők alaphelyzetbe állítva', 'info')
        setSearchParams(DEFAULT_SEARCH_PARAMS)
    }

    useEffect(() => {
        if (searchParams) localStorage.setItem(LOCAL_STORAGE_SEARCH_PARAMS, JSON.stringify(searchParams));
        else localStorage.removeItem(LOCAL_STORAGE_SEARCH_PARAMS)
    }, [searchParams]);

    const renderCellValue = (column, value, row) => {
        switch (column) {
            case 'amount': {
                const parts = row.paymentParts?.map(pp => pp.amount).reduce((acc, amount) => acc + amount, 0)
                const remaining = value - parts
                return remaining === 0 ? parseToMoney(value) : (parseToMoney(remaining) + (parts > 0 ? " (" + parseToMoney(value) + ")" : ""))
            }
            case 'paidBy': return value?.name;
            case 'documentNames': return (
                <Link onClick={(e) => {
                    e.stopPropagation()
                    download(row.id, value[0])
                }}
                    sx={{ textDecoration: "none", cursor: "pointer", fontWeight: "bold" }}>
                    {value[0]}
                </Link>)
            default: return value
        }
    }

    const download = async (id, fileName) => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/simple-invoices/" + id + "/document",
            params: { fileName: fileName },
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const blob = new Blob([response.data], { type: contentType });
                const url = window.URL.createObjectURL(blob);
                console.log(contentType)

                if (contentType === 'application/pdf' || contentType.startsWith('image/')) {
                    window.open(url)
                } else {
                    saveAs(blob, fileName);
                    sendAlertMessage('Fájl sikeresen letöltve')
                }
            })
            .catch(error => {
                console.error(error)
                sendAlertMessage('Fájl letöltése sikertelen', 'error')
            });
    }

    function valueFormatter(value) {
        return `${value?.toLocaleString()} Ft`;
    }

    const [open, setOpen] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState(undefined);

    const handleClickOpen = (row) => {
        setCurrentItem(row)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loggedInUser = JSON.parse(localStorage.getItem('user'))
    const [selectedUser, setSelectedUser] = useState({ id: loggedInUser.userId, name: loggedInUser.username });

    console.log(selectedUser)

    return (
        <>
            <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
                    Keresési Feltételek
                </AccordionSummary>
                <AccordionDetails className="filter-panel">
                    <Grid container columns={20} spacing={2} padding={"10px"}>
                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Számla Neve"
                                name="name"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.name) || ""}
                            />
                        </Grid>

                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Számla Azonosító"
                                name="serialNumber"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.serialNumber) || ""}
                            />
                        </Grid>

                        <Grid item lg={5} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="transaction-type-label">Tranzakció Módja</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="transaction-type-label"
                                    name="transactionType"
                                    label="Tranzakció Módja"
                                    value={(searchParams && searchParams.transactionType) || ""}
                                    onChange={onSearchParamsChange}
                                    sx={{ textAlign: "left" }}
                                >
                                    <MenuItem value={null}>Összes</MenuItem>
                                    {transactionTypes.map(transactionType =>
                                        <MenuItem value={transactionType.id}>{transactionType.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={5} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="category-label">Kategória</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="category-label"
                                    name="category"
                                    label="Kategória"
                                    value={(searchParams && searchParams.category) || ""}
                                    onChange={onSearchParamsChange}
                                    sx={{ textAlign: "left" }}
                                >
                                    <MenuItem value={null}>Összes</MenuItem>
                                    {categories.map(category =>
                                        <MenuItem value={category.id}>{category.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Feladó"
                                name="sender"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.sender) || ""}
                            />
                        </Grid>

                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Címzett"
                                name="receiver"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.receiver) || ""}
                            />
                        </Grid>

                        <Grid item lg={5} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="payment-type-label">Fizetési mód</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="payment-type-label"
                                    name="paymentType"
                                    label="Fizetési mód"
                                    value={(searchParams && searchParams.paymentType) || ""}
                                    onChange={onSearchParamsChange}
                                    sx={{ textAlign: "left" }}
                                >
                                    <MenuItem value={null}>Összes</MenuItem>
                                    <MenuItem value={"CASH"}>Készpénz</MenuItem>
                                    <MenuItem value={"TRANSFER"}>Átutalás</MenuItem>
                                    <MenuItem value={"CREDIT_CARD"}>Bankkártya</MenuItem>
                                    <MenuItem value={"CASH_ON_DELIVERY"}>Utánvét</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={5} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="invoice-type-label">Számla típusa</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="invoice-type-label"
                                    name="invoiceType"
                                    label="Számla típusa"
                                    value={(searchParams && searchParams.invoiceType) || null}
                                    onChange={onSearchParamsChange}
                                    sx={{ textAlign: "left" }}
                                >
                                    <MenuItem value={null}>Összes</MenuItem>
                                    <MenuItem value={"INVOICE"}>Számla</MenuItem>
                                    <MenuItem value={"ADVANCE_INVOICE"}>Előlegszámla</MenuItem>
                                    <MenuItem value={"FEE_REQUEST"}>Díjbekérő</MenuItem>
                                    <MenuItem value={"RECEIPT"}>Nyugta</MenuItem>
                                    <MenuItem value={"OTHER"}>Speciális</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={5} lg={5} md={10} xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="date"
                                label="-tól"
                                name="fromDate"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ marginTop: "0px" }}
                                value={(searchParams && searchParams.fromDate) || "-1"}
                            />
                        </Grid>

                        <Grid item xl={5} lg={5} md={10} xs={10}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="date"
                                label="-ig"
                                name="toDate"
                                autoComplete="off"
                                onChange={onSearchParamsChange}
                                sx={{ marginTop: "0px" }}
                                value={(searchParams && searchParams.toDate) || "-1"}
                            />
                        </Grid>

                        <Grid item lg={5} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="order-by-label">Rendezés Módja</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="order-by-label"
                                    name="orderBy"
                                    label="Rendezés Módja"
                                    value={(searchParams && searchParams.orderBy) || null}
                                    onChange={onSearchParamsChange}
                                    sx={{ textAlign: "left" }}
                                >
                                    <MenuItem value={"deadline_desc"}>Határidő szerint csökkenő</MenuItem>
                                    <MenuItem value={"deadline_asc"}>Határidő szerint növekvő</MenuItem>
                                    <MenuItem value={"paid_at_desc"}>Teljesítés dátuma szerint csökkenő</MenuItem>
                                    <MenuItem value={"paid_at_asc"}>Teljesítés dátuma szerint növekvő</MenuItem>
                                    <MenuItem value={"created_at_desc"}>Létrehozás dátuma szerint csökkenő</MenuItem>
                                    <MenuItem value={"created_at_asc"}>Létrehozás dátuma szerint növekvő</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={5} lg={5} md={10} xs={10}>
                            <FormControlLabel sx={{ marginTop: "0px" }} control={<Checkbox
                                margin="normal"
                                fullWidth
                                size="large"
                                onChange={onChangeCheckbox}
                                name="onlyNonPaid"
                                checked={(searchParams && searchParams.onlyNonPaid) || false}
                            />} label={<span style={{ fontSize: "16px" }}>Csak fizetetlenek</span>} />
                        </Grid>

                        <Grid md={15} xs={20}></Grid>

                        <Grid item md={5} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
                            <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Paper style={{ margin: "10px 0px", padding: "16px 0px" }}>
                {stats != null &&
                    <BarChart
                        dataset={Object.entries(stats.monthlyCosts).map(([key, value]) => {
                            return {
                                month: key.slice(0, 7),
                                totalPaidExpense: value[0],
                                salaryPaymentExpense: value[1],
                                vehicleExpense: value[2],
                                taxPaymentExpense: value[3],
                                operationalCostExpense: value[4],
                                totalPaidIncome: value[5],
                            }
                        })}
                        series={[
                            { dataKey: "totalPaidExpense", label: "Kiadás", color: "#cc0000", stack: 'expense', valueFormatter },
                            { dataKey: "taxPaymentExpense", label: "Adó", color: "#ff0000", stack: 'expense', valueFormatter },
                            { dataKey: "salaryPaymentExpense", label: "Bérkifizetés", color: "#ff7f00", stack: 'expense', valueFormatter },
                            { dataKey: "vehicleExpense", label: "Járművek", color: "#ffd966", stack: 'expense', valueFormatter },
                            { dataKey: "operationalCostExpense", label: "Üzemeltetési Költség", color: "#ffcccc", stack: 'expense', valueFormatter },
                            { dataKey: "totalPaidIncome", label: "Bevétel", color: "green", stack: 'income', valueFormatter },
                        ]}
                        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                        yAxis={[{ position: "left", valueFormatter: (value) => value.toLocaleString() + ' Ft' }]}
                        margin={{ top: 50, bottom: 30, left: 110, right: 10 }}
                        height="200"
                    />}

            </Paper>

            <Paper sx={{ margin: "10px 0px", width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ background: "lightgray", width: "100px" }}></TableCell>
                                <TableCell style={{ fontWeight: 700, background: "lightgray", textAlign: "center" }}>Teljesített</TableCell>
                                <TableCell style={{ fontWeight: 700, background: "lightgray", textAlign: "center" }}>Teljesítésre Vár</TableCell>
                                <TableCell style={{ fontWeight: 700, background: "lightgray", textAlign: "center" }}>Lejárt</TableCell>
                                <TableCell style={{ fontWeight: 700, background: "lightgray", textAlign: "center" }}>
                                    <Tooltip title='(Bevétel ÁFA mennyisége - Kiadás ÁFA mennyisége) Negatív szám esetén nem tartozás, hanem túltörlesztés van'>Fizetendő ÁFA</Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 700, background: "lightgray" }}>Bevétel</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{parseToMoney(stats?.totalPaidIncome, true)}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{parseToMoney(stats?.totalUnpaidIncome, true)}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{parseToMoney(stats?.totalExpiredIncome, true)}</TableCell>
                                <TableCell style={{ textAlign: "center" }} rowSpan={2}>{parseToMoney(stats?.totalVat, true)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 700, background: "lightgray" }}>Kiadás</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{parseToMoney(stats?.totalPaidExpense, true)}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{parseToMoney(stats?.totalUnpaidExpense, true)}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{parseToMoney(stats?.totalExpiredExpense, true)}</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Paper>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px", width: "90px" }} align='left' padding='none'>
                                    <Tooltip title="Új Számla Hozzáadása" placement='left'>
                                        <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginLeft: "12px" }} onClick={() => navigate("/dashboard/simple-invoices/create")}>
                                            <Add fontSize="large" />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                                {columns.filter(column => !['paid', 'paidAt', 'paidBy'].includes(column.id) || searchParams?.onlyNonPaid === false).map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray", width: column.width }}>
                                        {column.label}
                                    </TableCell>
                                ))}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                tableData.rows.map((row) => {
                                    return (
                                        <TableRow role="checkbox" tabIndex={-1} key={row.id} sx={getRowBackgroundColor(row)}>
                                            <TableCell padding='none' align='right' style={{ position: "sticky", left: 0, paddingLeft: "10px", minWidth:"100px"}}>
                                                <Tooltip title={row.paid ? 'Teljesítés visszavonása' : 'Számla teljesítése'} placement='left'>
                                                    <Button onClick={() => {
                                                        console.log(row)
                                                        if (row.paid) togglePaid(row.id, row.paid)
                                                        else handleClickOpen(row);
                                                    }
                                                    } color={row.paid ? 'error' : (row.paymentParts?.length > 0 ? 'warning' : 'success')} variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}>
                                                        {row.paid ? <MoneyOff /> : <AttachMoney />}
                                                    </Button>
                                                </Tooltip>
                                                <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                    onClick={() => openConfirmationDialog(
                                                        'Biztosan törölni szeretnéd az alábbi számlát?',
                                                        <>{row.name.length > 50 ? row.name.slice(0, 50) + '...' : row.name}<br />{parseToMoney(row.amount)}<br />{row.deadline}</>,
                                                        () => deleteSimpleInvoice(row.id)
                                                    )}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                            {columns.filter(column => !['paid', 'paidAt', 'paidBy'].includes(column.id) || searchParams?.onlyNonPaid === false).map((column) => {
                                                const value = renderCellValue(column.id, row[column.id], row);
                                                return (
                                                    <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/simple-invoices/" + row.id)} sx={{ cursor: "pointer", padding: "0px 10px" }}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={tableData.totalCount}
                    rowsPerPage={parseInt(tableData.rowsPerPage)}
                    page={tableData.page}
                    onPageChange={(event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))}
                    onRowsPerPageChange={(event) => {
                        setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
                        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
                    }}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        if (selectedUser) {
                            formData.set('userId', selectedUser.id);
                        }
                        const formJson = Object.fromEntries((formData).entries());

                        console.log(formJson.amount, currentItem.amount, formJson.amount == currentItem.amount)

                        if (formJson.amount != currentItem.amount || currentItem.paymentParts?.length > 0) saveAsPaymentPart(currentItem.id, formJson)
                        else togglePaid(currentItem.id, currentItem.paid, formJson.paidAt, formJson.userId);
                        handleClose();
                    },
                }}
            >
                <DialogTitle sx={{ background: "#1976d2", color: "white", width: "500px" }}>Számla Teljesítése</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        autoFocus
                        required
                        margin="dense"
                        name="user"
                        onChange={(event, newValue) => setSelectedUser(newValue)}
                        defaultValue={selectedUser}
                        options={users}
                        getOptionLabel={(user) => user.name}
                        sx={{ marginTop: "10px" }}
                        renderInput={(params) => (
                            <TextField {...params} label="Teljesítette" variant="standard" required />
                        )}
                    />
                    <TextField
                        required
                        margin="dense"
                        name="paidAt"
                        label="Teljesítés Dátuma"
                        fullWidth
                        defaultValue={TODAY}
                        variant="standard"
                        type='date'
                        sx={{ marginTop: "30px" }}
                    />
                    <TextField
                        required
                        margin="dense"
                        name="amount"
                        label="Összeg (Ft)"
                        fullWidth
                        defaultValue={currentItem?.amount - currentItem?.paymentParts?.map(pp => pp.amount).reduce((acc, amount) => acc + amount, 0)}
                        variant="standard"
                        type='number'
                        sx={{ marginTop: "30px" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="info" variant="contained" sx={{ marginRight: "auto" }}>Mentés</Button>
                    <Button onClick={handleClose} color="error" variant="outlined">Mégse</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function getRowBackgroundColor(row) {
    const isExpense = row.receiver === 'Fay Automatika Kft.'
    const isExpired = (new Date(row.deadline) < new Date(TODAY) && row.paid !== true)
    const color = isExpired ? '#ffffb2' : (isExpense ? '#ffb7b2' : '#c7f6c7')


    return {
        ':hover': {
            backgroundColor: isExpired ? '#e5e5a0' : (isExpense ? '#e5a4a0' : '#b3ddb3'),
        }, backgroundColor: color
    }
}

import React, { useState, useEffect } from 'react';
import VehicleEditorTemplate from './VehicleEditorTemplate';
import vehicleService from '../../services/VehicleService';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../dashboard/TitleContext';
import authService from '../../services/AuthService';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { API_URL } from '../../App';
import { useSnackbar } from '../../util/SnackbarProvider';

const LOCAL_STORAGE_ITEM_NAME = "vehicle-invoices-rows-per-page"

const VehicleEditor = () => {

  const urlParts = window.location.pathname.split('/')
  const id = urlParts[3] === 'create' ? undefined : urlParts[3]

  useTitle([
    { name: 'Jármű Lista', url: '/dashboard/vehicles' },
    { name: 'Jármű', url: '/dashboard/vehicles/' + (id || 'create') }
  ])

  const [tableData, setTableData] = useState({ page: 0, rowsPerPage: localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || 25, totalCount: 0 })
  const { sendAlertMessage } = useSnackbar()
  const [vehicle, setVehicle] = useState({});
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      vehicleService.findById(id, (response, error) => {
        if (response) {
          setVehicle((prevVehicle) => ({
            ...prevVehicle,
            ...response,
          }));
        } else console.log(error);
      })
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      vehicleService.getInvoices(id, (response, error) => {
        if (response) {
          const result = response.content.map((invoice) => {
            let invoiceType = "";
            switch (invoice.invoiceType) {
                case "INVOICE": invoiceType = "Számla"; break;
                case "ADVANCE_INVOICE": invoiceType = "Előlegszámla"; break;
                case "FEE_REQUEST": invoiceType = "Díjbekérő"; break;
                case "RECEIPT": invoiceType = "Nyugta"; break;
                case "OTHER": invoiceType = "Speciális"; break;
                default: invoiceType = ""; break;
            }

            let paymentType = "";
            switch (invoice.paymentType) {
                case "CASH": paymentType = "Készpénz"; break;
                case "TRANSFER": paymentType = "Átutalás"; break;
                case "CREDIT_CARD": paymentType = "Bankkártya"; break;
                case "CASH_ON_DELIVERY": paymentType = "Utánvét"; break;
                default: paymentType = ""; break;
            }
            return { ...invoice, invoiceType: invoiceType, paymentType: paymentType }
        })
          setTableData((prev) => ({ ...prev, totalCount: response.totalElements, rows: result }))
        } else console.log(error);
      });
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      [name]: value,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    vehicleService.createVehicle(vehicle, (response, error) => {
      if (!error) {
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    vehicleService.updateVehicle(id, vehicle, (response, error) => {
      if (!error) {
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const download = async (id, fileName) => {
    const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
    axios({
        url: API_URL + "/simple-invoices/" + id + "/document",
        params: { fileName: fileName },
        method: 'GET',
        responseType: 'blob',
        headers: headers,
    })
        .then(response => {
            const contentType = response.headers['content-type'];
            const blob = new Blob([response.data], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            console.log(contentType)

            if (contentType === 'application/pdf' || contentType.startsWith('image/')) {
                window.open(url)
            } else {
                saveAs(blob, fileName);
                sendAlertMessage('Fájl sikeresen letöltve')
            }
        })
        .catch(error => {
            console.error(error)
            sendAlertMessage('Fájl letöltése sikertelen', 'error')
        });
}

  const onPageChange = (event, newPage) => setTableData((prev) => ({ ...prev, page: newPage }))

  const onRowsPerPageChange = (event) => {
    setTableData((prev) => ({ ...prev, rowsPerPage: event.target.value, page: 0 }))
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, event.target.value)
  }

  return (
    <VehicleEditorTemplate
      {...{
        id,
        vehicle,
        message,
        onChange,
        create,
        update,
        tableData,
        onPageChange,
        onRowsPerPageChange,
        download
      }}
    />
  );
};

export default VehicleEditor;

import { Close, Delete } from "@mui/icons-material"
import { Button, Container, TextField, Alert, Grid, CircularProgress, FormControlLabel, Checkbox, MenuItem, Select, InputLabel, FormControl, TableRow, TableBody, Link, Table, TableCell, styled, Autocomplete, Divider, Paper, TableContainer, TableHead } from "@mui/material"
import React from "react"
import simpleInvoicePaymentPartService from "../../services/SimpleInvoicePaymentPartService";
import { useSnackbar } from "../../util/SnackbarProvider";
import { parseToMoney } from "../../util/StringUtils";

const VisuallyHiddenInput = styled('input')({
    width: 0
});

const columns = [
    { id: 'paidBy', label: 'Teljesítette', map: value => value.name },
    { id: 'paidAt', label: 'Dátum' },
    { id: 'amount', label: 'Összeg', map: value => parseToMoney(value) },
    { id: 'createdAt', label: 'Létrehozva' }
];

export default function simpleInvoiceEditorTemplate(props) {

    const { id, simpleInvoice, message, create, update, navigate, waitingForServer, sendAlertMessage, retrievePaymentParts } = props

    const createInputField = ({ name, label, required = false, autoFocus = false, disabled = false, type = 'text' }) =>
        <Grid item lg={5} md={10} xs={20}>
            <TextField
                variant="outlined"
                margin="normal"
                required={required}
                fullWidth
                id={name}
                label={label}
                name={name}
                type={type}
                autoFocus={autoFocus}
                autoComplete="off"
                onChange={props.onChange}
                value={(props.simpleInvoice && props.simpleInvoice[name]) || (type === 'date' ? -1 : '')}
                disabled={disabled}
            />
        </Grid>

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={id ? update : create}>
                        <Grid container spacing={2} columns={25}>
                            <Grid item lg={20} xs={25}>
                                <Grid container spacing={2} columns={20}>
                                    {id != null && simpleInvoice == null ? <Grid item xs={20}><CircularProgress sx={{ margin: "100px" }} /> </Grid> :
                                        <>
                                            {createInputField({ name: 'name', label: 'Számla Neve', autoFocus: true, required: true })}
                                            {createInputField({ name: 'serialNumber', label: 'Számla Sorszáma' })}
                                            {createInputField({ name: 'amount', label: 'Összeg (Ft)', type: 'number', required: true })}

                                            <Grid item lg={5} md={10} xs={20}>
                                                <Autocomplete
                                                    value={simpleInvoice.vat}
                                                    margin="normal"
                                                    name="vat"
                                                    freeSolo
                                                    autoSelect
                                                    onChange={(event, newValue) => props.onSelectChange(newValue, 'vat')}
                                                    options={[27, 5, 0]}
                                                    getOptionLabel={(value) => value}
                                                    style={{ marginTop: "16px" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="ÁFA" required />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                                    <InputLabel id="payment-type-label">Fizetési mód</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        labelId="payment-type-label"
                                                        name="paymentType"
                                                        label="Fizetési mód"
                                                        value={simpleInvoice.paymentType || ""}
                                                        onChange={props.onChange}
                                                        sx={{ textAlign: "left" }}
                                                    >
                                                        <MenuItem value={"CASH"}>Készpénz</MenuItem>
                                                        <MenuItem value={"TRANSFER"}>Átutalás</MenuItem>
                                                        <MenuItem value={"CREDIT_CARD"}>Bankkártya</MenuItem>
                                                        <MenuItem value={"CASH_ON_DELIVERY"}>Utánvét</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                                    <InputLabel id="invoice-type-label">Számla típusa</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        labelId="invoice-type-label"
                                                        name="invoiceType"
                                                        label="Számla típusa"
                                                        value={simpleInvoice.invoiceType || ""}
                                                        onChange={props.onChange}
                                                        sx={{ textAlign: "left" }}
                                                    >
                                                        <MenuItem value={"INVOICE"}>Számla</MenuItem>
                                                        <MenuItem value={"ADVANCE_INVOICE"}>Előlegszámla</MenuItem>
                                                        <MenuItem value={"FEE_REQUEST"}>Díjbekérő</MenuItem>
                                                        <MenuItem value={"RECEIPT"}>Nyugta</MenuItem>
                                                        <MenuItem value={"OTHER"}>Speciális</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {createInputField({ name: 'deadline', label: 'Határidő', type: 'date' })}
                                            {createInputField({ name: 'dateOfInvoice', label: 'Keltezés', type: 'date', required: true })}
                                            <Grid item xs={20}>
                                                <Divider />
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth required>
                                                    <InputLabel id="transaction-type-label">Tranzakció Típusa</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        labelId="transaction-type-label"
                                                        name="transactionType"
                                                        label="Tranzakció Típusa"
                                                        value={simpleInvoice.transactionType || ""}
                                                        onChange={props.onTransactionTypeChange}
                                                        sx={{ textAlign: "left" }}
                                                    >
                                                        <MenuItem value={"INCOME"}>Kimenő Számla</MenuItem>
                                                        <MenuItem value={"EXPENSE"}>Bejövő Számla</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth required>
                                                    <InputLabel id="category-label">Kategória</InputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        labelId="category-label"
                                                        name="category"
                                                        label="Kategória"
                                                        value={simpleInvoice.category || ""}
                                                        onChange={props.onChange}
                                                        sx={{ textAlign: "left" }}
                                                    >
                                                        <MenuItem value={"GENERAL"}>Általános</MenuItem>
                                                        <MenuItem value={"SALARY_PAYMENT"}>Bérkifizetés</MenuItem>
                                                        <MenuItem value={"VEHICLE_PAYMENT"}>Jármű</MenuItem>
                                                        <MenuItem value={"TAX_PAYMENT"}>Adó</MenuItem>
                                                        <MenuItem value={"OPERATIONAL_COST"}>Üzemeltetés</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                {(simpleInvoice.category === 'GENERAL' || simpleInvoice.category === 'VEHICLE_PAYMENT') && <Autocomplete
                                                    margin="dense"
                                                    name="project"
                                                    onChange={(event, newValue) => props.onSelectChange(newValue, 'project')}
                                                    options={props.projects}
                                                    value={props.simpleInvoice.project || null}
                                                    getOptionLabel={(project) => project.name}
                                                    sx={{ marginTop: "16px" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Projekt" variant="outlined" />
                                                    )}
                                                />}
                                            </Grid>

                                            <Grid item lg={5} md={10} xs={20}>
                                                {simpleInvoice.category === 'VEHICLE_PAYMENT' && <Autocomplete
                                                    margin="dense"
                                                    name="vehicle"
                                                    onChange={(event, newValue) => props.onSelectChange(newValue, 'vehicle')}
                                                    options={props.vehicles}
                                                    value={props.simpleInvoice.vehicle || null}
                                                    getOptionLabel={(vehicle) => vehicle.licensePlate}
                                                    sx={{ marginTop: "16px" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Jármű" variant="outlined" />
                                                    )}
                                                />}
                                            </Grid>

                                            {simpleInvoice.transactionType &&
                                                <>
                                                    <Grid item lg={5} md={10} xs={20}>
                                                        <Autocomplete
                                                            value={simpleInvoice.sender || ""}
                                                            margin="normal"
                                                            label="Feladó"
                                                            name="sender"
                                                            freeSolo
                                                            autoSelect
                                                            onChange={(event, newValue) => props.onSenderChange(event, newValue)}
                                                            options={props.senderOptions?.map(sender => sender.name)}
                                                            getOptionLabel={(sender) => sender}
                                                            style={{ marginTop: "16px" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Feladó" required />
                                                            )}
                                                        />
                                                    </Grid>

                                                    {createInputField({ name: 'senderTaxNumber', label: 'Feladó Adószáma' })}
                                                    {createInputField({ name: 'senderAddress', label: 'Feladó Címe' })}
                                                    {createInputField({ name: 'senderBankAccountNumber', label: 'Feladó Bankszámla Száma' })}

                                                    <Grid item lg={5} md={10} xs={20}>
                                                        <Autocomplete
                                                            value={simpleInvoice.receiver || ""}
                                                            margin="normal"
                                                            label="Címzett"
                                                            name="receiver"
                                                            freeSolo
                                                            autoSelect
                                                            onChange={(event, newValue) => props.onReceiverChange(event, newValue)}
                                                            options={props.receiverOptions?.map(receiver => receiver.name)}
                                                            getOptionLabel={(receiver) => receiver}
                                                            style={{ marginTop: "16px" }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Címzett" required />
                                                            )}
                                                        />
                                                    </Grid>

                                                    {createInputField({ name: 'receiverTaxNumber', label: 'Címzett Adószáma' })}

                                                    <Grid item xs={10}></Grid>
                                                </>
                                            }

                                            <Grid item lg={5} md={10} xs={20} sx={{ textAlign: "left" }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        variant="outlined"
                                                        margin="normal"
                                                        size="large"
                                                        name="paid"
                                                        onChange={props.onChangeCheckbox}
                                                        checked={props.simpleInvoice.paid || false}
                                                    />} label="Teljesítve"
                                                    style={{ paddingTop: "20px" }} />
                                            </Grid>

                                            {props.simpleInvoice.paid === true &&
                                                <>
                                                    {props.simpleInvoice.sender !== 'Fay Automatika Kft.' &&
                                                        <Grid item lg={5} md={10} xs={20}>
                                                            <Autocomplete
                                                                required
                                                                margin="dense"
                                                                name="paidBy"
                                                                onChange={props.onPaidByChange}
                                                                options={props.users}
                                                                value={props.simpleInvoice.paidBy}
                                                                getOptionLabel={(user) => user.name}
                                                                sx={{ marginTop: "10px" }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Teljesítette" variant="outlined" required />
                                                                )}
                                                            />
                                                        </Grid>}
                                                    <Grid item lg={5} md={10} xs={20}>
                                                        <TextField
                                                            required
                                                            margin="dense"
                                                            name="paidAt"
                                                            label="Teljesítés Dátuma"
                                                            fullWidth
                                                            onChange={props.onChange}
                                                            value={props.simpleInvoice.paidAt || -1}
                                                            variant="outlined"
                                                            type='date'
                                                            sx={{ marginTop: "10px" }}
                                                        />
                                                    </Grid>
                                                </>}


                                            {message &&
                                                <Grid item lg={20} xs={20}>
                                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                                </Grid>
                                            }

                                            <Grid item lg={20} md={20} xs={20}></Grid>

                                        </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item lg={5} xs={25}>
                                <Grid container spacing={2} columns={5}>
                                    {(props.simpleInvoice?.documentNames?.length > 0 || props.files?.length > 0) && <Grid item xs={5}>
                                        <Table sx={{ marginTop: "16px" }}>
                                            <TableBody>
                                                {props.simpleInvoice?.documentNames?.map((name, index) => (
                                                    <TableRow>
                                                        <TableCell key={index} sx={{ textAlign: "left", padding: "5px" }}>
                                                            <Link onClick={() => props.download(name)} sx={{ textDecoration: "none", cursor: "pointer", fontWeight: "bold" }}>{name}</Link>
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => props.removeDocumentName(name)} variant="text" color='error' sx={{ marginLeft: "10px", padding: "5px", minWidth: "0px" }}>
                                                                <Delete />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {props.files.map((file, index) => (
                                                    <TableRow>
                                                        <TableCell key={index} sx={{ textAlign: "left", padding: "0px 0px 0px 5px" }}>
                                                            {file.name}
                                                        </TableCell>
                                                        <TableCell key={index} sx={{ textAlign: "left", padding: "0px" }}>
                                                            {(new Intl.NumberFormat().format((file.size / 1024 / 1024).toFixed(2))).replace(',', '.')} MB
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => props.handleRemoveFile(index)} variant="text" color='error' sx={{ marginLeft: "10px", padding: "5px", minWidth: "0px" }}>
                                                                <Close />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>}
                                    <Grid item xs={5}>
                                        <Button component="label" variant="contained" color='info' style={{ float: "left", marginTop: "16px" }}>
                                            Fájlok hozzáadása
                                            <VisuallyHiddenInput type="file" multiple onChange={props.handleFileChange} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                    Vissza
                                </Button>
                            </Grid>

                            <Grid item lg={16} md={10} xs={2}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>}
                            </Grid>
                        </Grid>
                    </form>
                </div>

            </Container>

            {simpleInvoice.paymentParts?.length > 0 && <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '16px' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray", width: column.width }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell style={{ background: "lightgray" }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {simpleInvoice.paymentParts.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ height: '30px' }}>
                                        {columns.map((column) => {
                                            let value = column.map ? column.map(row[column.id]) : row[column.id]
                                            return (
                                                <TableCell key={column.id} align={column.align}
                                                    sx={{ cursor: "pointer", padding: "0px 10px" }}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                        <TableCell padding='none' align='right' style={{ position: "sticky", left: 0, backgroundColor: "'white" }}>
                                            <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                onClick={() => props.openConfirmationDialog(
                                                    'Biztosan törölni szeretnéd az alábbi részleges teljesítést?',
                                                    <>{row.paidBy?.name}<br />{parseToMoney(row.amount)}<br />{row.paidAt}</>,
                                                    () => {
                                                        simpleInvoicePaymentPartService.deletePaymentPart(row.id, (response, error) => {
                                                            if (!error) {
                                                                sendAlertMessage('Részlet teljesítés bejegyzés sikeresen törölve!')
                                                                props.retrieveSimpleInvoice()
                                                            } else sendAlertMessage(error, 'error')
                                                        })
                                                    }
                                                )}>
                                                <Delete />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>}

        </div>
    )
}
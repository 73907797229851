import React, { useState, useEffect } from 'react';
import ProjectEditorTemplate from './ProjectEditorTemplate';
import projectService from '../../services/ProjectService';
import { useNavigate } from 'react-router-dom';
import userService from '../../services/UserService';
import { useTitle } from '../dashboard/TitleContext';

const ProjectEditor = () => {

  const [id] = useState(
    window.location.pathname.split('/')[3] === 'create'
      ? undefined
      : window.location.pathname.split('/')[3]
  );

  useTitle([
    { name: 'Projekt Lista', url: '/dashboard/projects' },
    { name: 'Projekt', url: '/dashboard/projects/' + (id || 'create') }
  ])

  const [project, setProject] = useState({
    shouldCreateTemplate: true
  });

  const [budget, setBudget] = useState({
    contractPrice: "",
    progressLevel: "",
    planningAndProgrammingHourlyRate: 0,
    electricInstallationHourlyRate: "",
    otherTaskHourlyRate: "",
    onsiteTravelCost: "",
    materialsPrice: "",
    totalCost: "",
    travelCost: "",
    profitAmount: "",
    materialsCost: "",
    costCompensationPercentage: "",
    costCompensationAmount: "",
    taskItems: [],
    planningAndProgrammingProgressLevel: "",
    electricInstallationProgressLevel: "",
    otherTaskProgressLevel: "",
    travelProgressLevel: "",
    accommodationProgressLevel: "",
    materialsProgressLevel: "",
    totalPrice: 0,
    totalProgressLevel: 0,
    task4HourlyRate: "",
    task5HourlyRate: "",
    task4ProgressLevel: "",
    task5ProgressLevel: "",
    estimatedTask4Hours: "",
    estimatedTask5Hours: "",
    budgetCategoryList: [{}, {}, {}, {}, {}, {}, {}]
  })

  const [message, setMessage] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const handleExtension = () => (event, isExpanded) => {
    setExpanded(!expanded);
    var element = document.getElementById("financial-details")
    setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'end' }), 1000)
  };

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      projectService.findById(id, (response, error) => {
        if (response) {
          if (response.priority == null) response.priority = "NORMAL"
          setProject(response);
        } else console.log(error);
      })
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      projectService.getBudgetOfProject(id, (response, error) => {
        if (response) {
          setBudget(response);
          console.log(response)
        } else console.log(error);
      })
    }
  }, [id]);

  const [users, setUsers] = useState([]);
  useEffect(() => {
    userService.getSelectors(
      (response, error) => {
        if (response) setUsers(response);
        else {
          console.log(error);
        }
      },
    );
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  const onBudgetChange = (event) => {
    const { name, value } = event.target;
    setBudget((prevBudget) => ({
      ...prevBudget,
      [name]: value,
    }));
  };

  const onLogbookHandlerChange = (newValue) => {
    setProject((prevProject) => ({
      ...prevProject,
      logbookHandler: newValue,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    prepareRequest();
    projectService.createProject(project, (response, error) => {
      if (!error) {
        navigate('/dashboard/projects');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    prepareRequest();
    projectService.updateProject(id, project, (response, error) => {
      if (!error) {
        window.location.reload()
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const updateBudget = (e) => {
    e.preventDefault();
    projectService.updateProjectBudget(id, budget, (response, error) => {
      if (!error) window.location.reload()
      else setMessage(error === undefined ? 'Szerver nem elérhető' : error);
    });
  };

  const prepareRequest = () => {
    project.logbookHandlerId = project.logbookHandler?.id
  }

  const onChangeCheckbox = (event) => {
    const { checked, name } = event.target
    setProject((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  return (
    <ProjectEditorTemplate
      data={{
        id,
        project,
        budget,
        message,
        onChange,
        onBudgetChange,
        onLogbookHandlerChange,
        create,
        update,
        updateBudget,
        users,
        handleExtension,
        expanded,
        onChangeCheckbox
      }}
    />
  );
};

export default ProjectEditor;

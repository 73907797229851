import { Button, Container, TextField, Alert, Grid, CircularProgress } from "@mui/material"
import React from "react"

export default function NoteEditorTemplate(props) {

    const { id, note, message, onChange, create, update, navigate, waitingForServer } = props

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={id ? update : create}>
                        <Grid container spacing={2} columns={20}>
                            {id != null && note == null ? <Grid item xs={20}><CircularProgress sx={{ margin: "100px" }} /> </Grid> :
                                <>
                                    <Grid item lg={20} md={20} xs={20}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="text"
                                            label="Jegyzet"
                                            name="text"
                                            type="text"
                                            autoFocus
                                            autoComplete="off"
                                            onChange={onChange}
                                            value={note?.text}
                                            multiline={true}
                                            minRows={5}
                                        />
                                    </Grid>

                                    {message &&
                                        <Grid item lg={20} xs={20}>
                                            <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                        </Grid>
                                    }

                                    <Grid item lg={20} md={20} xs={20}></Grid>

                                    <Grid item lg={2} md={5} xs={9}>
                                        <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                            Vissza
                                        </Button>
                                    </Grid>

                                    <Grid item lg={16} md={10} xs={2}></Grid>

                                    <Grid item lg={2} md={5} xs={9}>
                                        {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                            Mentés
                                        </Button>}
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </form>
                </div>

            </Container>
        </div>
    )
}
import Service from "./Service"

class ProjectTaskCommentService extends Service {

    findById = (id, callback) => this.get("/project-tasks/sub-tasks/comments/" + id, callback)

    findAllByProjectSubTask = (searchParams, projectSubTaskId, callback) => this.getWithParams("/project-tasks/sub-tasks/" + projectSubTaskId + "/comments", searchParams, callback)

    createComment = (projectTaskCommentRequest, projectSubTaskId, callback) => this.post("/project-tasks/sub-tasks/" + projectSubTaskId + "/comments", projectTaskCommentRequest, callback)

    updateComment = (commentId, commentRequest, callback) => this.patch("/project-tasks/sub-tasks/comments/" + commentId, commentRequest, callback)

    deleteComment = (commentId, callback) => this.del("/project-tasks/sub-tasks/comments/" + commentId, callback)
}
const projectTaskCommentService = new ProjectTaskCommentService()
export default projectTaskCommentService
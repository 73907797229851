import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MainListItems } from './listItems';
import { History, LocalAtm, Logout, WarningAmber } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import AuthService from '../../services/AuthService';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/AuthService';
import projectService from '../../services/ProjectService';
import payrollService from '../../services/PayrollService';
import { TitleContext } from './TitleContext';
import { useState } from 'react';
import { useEffect } from 'react';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard({ content }) {
  const [open, setOpen] = React.useState(false);
  const { title, text } = React.useContext(TitleContext);
  const navigate = useNavigate()
  const username = authService.getCurrentUser()?.username

  const depth = window.location.pathname.split('/').length
  let background;

  const [lastTenPages, setLastTenPages] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setLastTenPages((prevPages) => {
      const currentPath = text;
      if (text === '') return prevPages;
      const pageExists = prevPages.some((page) => page.name === currentPath);
      let updatedPages = pageExists ? prevPages.filter((page) => page.name !== currentPath) : prevPages;
      updatedPages = [...updatedPages, { name: currentPath, url: location.pathname }];
      return updatedPages.length > 10 ? updatedPages.slice(1) : updatedPages;
    });
  }, [location, title]);


  switch (depth) {
    case 0:
    case 1:
    case 2:
    case 3: background = "lightcyan"; break;
    case 4: background = "#87CEEB"; break;
    case 5:
    case 6:
    case 7: background = "#2e77bf"; break;
    default: background = "lightcyan"; break;
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [projects, setProjects] = React.useState({});
  React.useEffect(() => {
    projectService.getImportantProjects(
      (response, error) => {
        if (response) {
          setProjects(response)
        }
      }
    )
  }, [content])

  const [unpaidSalariesCount, setUnpaidSalariesCount] = React.useState(0);
  React.useEffect(() => {
    payrollService.getUnpaidSalariesCount(
      (response, error) => {
        if (response) {
          setUnpaidSalariesCount(response)
        }
      }
    )
  }, [content])


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
            <Typography>
              {username}
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" onClick={() => {
              const now = new Date()
              const month = now.getMonth() === 0 ? 11 : now.getMonth();
              const year = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
              localStorage.setItem("payroll-year", year)
              localStorage.setItem("payroll-month", month)
              navigate("/dashboard/payrolls")
            }
            }>
              <Tooltip title="Kifizetésre vár">
                <Badge badgeContent={unpaidSalariesCount} color="secondary">
                  <LocalAtm />
                </Badge>
              </Tooltip>
            </IconButton>
            <IconButton color="inherit" onClick={() => {
              localStorage.setItem("project-priority", JSON.stringify("EXTRA_IMPORTANT"))
              navigate("/dashboard/projects")
            }}>
              <Tooltip title={
                <span>{'Projektek'}
                  <br />
                  {'Extra Fontos: ' + projects.extraImportant}
                  <br />
                  {'Fontos: ' + projects.important}
                </span>}>
                <Badge badgeContent={projects.extraImportant} color="secondary">
                  <WarningAmber />
                </Badge>
              </Tooltip>
            </IconButton>
            <IconButton color="inherit" onClick={() => { AuthService.logout(); navigate("/login") }}>
              <Tooltip title="Kijelentkezés">
                <Badge color="secondary">
                  <Logout />
                </Badge>
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer id="drawer" variant="permanent" open={open} >
          <Toolbar>
            <IconButton color="inherit" sx={{ marginLeft: "-12px", fontSize: "20px" }} onClick={() => navigate("/dashboard/change-logs")}>
              v1.2.5
            </IconButton>
            <IconButton onClick={toggleDrawer} sx={{ marginLeft: "auto" }}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {MainListItems()}
          </List>
          <List sx={{ marginTop: "auto", backgroundColor: "lightgray" }}>
            {lastTenPages.slice().reverse().map((page, index) => (
              <Tooltip title={page.url} placement='right' key={index}>
                <ListItemButton key={index} onClick={() => navigate(page.url)} sx={{ height: "25px" }}>
                  <ListItemIcon>
                    <History />
                  </ListItemIcon>
                  <span style={{ fontSize: '12px', marginLeft: "-24px" }}>{page.name}</span>
                </ListItemButton>
              </Tooltip>
            ))}
          </List>
        </Drawer>
        <Box
          component="main"
          id="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            padding: '84px 20px 0px 20px',
            overflow: 'auto',
            background: background
          }}
        >
          {content}
        </Box>
      </Box>
    </ThemeProvider >
  );
}

function formatPath(path) {
  if (path && path.startsWith('/dashboard/')) {
    return path.replace('/dashboard/', '');
  }
  return path;
}
import Service from "./Service"

class TaskLogService extends Service {

    findById = (id, callback) => this.get("/task-logs/" + id, callback)

    search = (queryParams, callback) => this.getWithParams("/task-logs", queryParams, callback)

    createTaskLog = (tasklogRequest, callback) => this.post("/task-logs", tasklogRequest, callback)

    updateTaskLog = (tasklogId, tasklogRequest, callback) => this.patch("/task-logs/" + tasklogId, tasklogRequest, callback)

    deleteTaskLog = (tasklogId, callback) => this.del("/task-logs/" + tasklogId, callback)
}
const tasklogService = new TaskLogService()
export default tasklogService
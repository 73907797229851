import React, { useState, useEffect } from 'react';

import projectTaskCommentService from "../../services/ProjectTaskCommentService";
import { Button, CircularProgress, Paper, TablePagination, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProjectSubTaskComments = () => {

    const urlParts = window.location.pathname.split('/')
    const projectSubTaskId = urlParts[8]

    const [page, setPage] = React.useState(0);
    const [comments, setComments] = React.useState([]);
    const [newComment, setNewComment] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("project-sub-task-comments-rows-per-page") || 25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [waitingForServer, setWaitingForServer] = useState(false)

    useEffect(() => {
        retrieveComments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    function buildQueryParams() {
        return {
            page: page,
            size: rowsPerPage,
        }
    }

    function retrieveComments() {
        setComments(null)
        projectTaskCommentService.findAllByProjectSubTask(buildQueryParams(), projectSubTaskId, (response, error) => {
            if (response) {
                setTotalCount(response.totalElements)
                setComments(response.content);
            } else console.log(error)
        })
    }

    function sendComment() {
        setWaitingForServer(true)
        projectTaskCommentService.createComment({ message: newComment }, projectSubTaskId, (response, error) => {
            if (response) {
                retrieveComments()
                setNewComment("")
            }
            else console.log(error)
            setWaitingForServer(false)
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const changeNewComment = (event) => {
        setNewComment(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = event.target.value
        setRowsPerPage(event.target.value);
        localStorage.setItem("project-sub-task-comments-rows-per-page", rowsPerPage)
        setPage(0);
    };

    const navigate = useNavigate()

    return (
        <>
            <Paper sx={{ marginTop: "15px", borderRadius: "5px", padding: "5px 15px" }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    label="Új Komment"
                    autoComplete="off"
                    autoFocus
                    onChange={changeNewComment}
                    value={newComment}
                />
                <div style={{ textAlign: "right" }}>
                    {waitingForServer ? <CircularProgress /> : <Button component="label" variant="contained" color='primary' style={{ marginBottom: "10px" }} onClick={() => sendComment()}>
                        Küldés
                    </Button>}
                </div>
            </Paper>
            {comments && comments.map(comment => <Comment data={comment} />)}
            <Paper sx={{ margin: "15px 0px", borderRadius: "5px" }}>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={parseInt(rowsPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    )
}

export default ProjectSubTaskComments;

function Comment({ data }) {
    return (
        <Paper sx={{ margin: "15px 0px", borderRadius: "5px" }}>
            <div style={{ backgroundColor: "lightgray", color: "black", padding: "10px", borderRadius: "5px 5px 0px 0px" }}>
                {data.user.name}
                <span style={{ float: "right" }}>{data.createdAt}</span>
            </div>
            <div style={{ padding: "10px", whiteSpace: "pre-line" }}>{data.message}</div>
        </Paper>
    )
}
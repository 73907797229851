import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Add, Delete, Edit, GetApp, KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import projectService from '../../services/ProjectService';
import { useTitle } from '../dashboard/TitleContext';

const columns = [
  { id: 'name', label: 'Projekt Név' },
  { id: 'location', label: 'Székhely' },
  { id: 'representative', label: 'Képviselő' },
  { id: 'logbookHandler', label: 'E-napló felelős' },
  { id: 'status', label: 'Státusz' },
  { id: 'priority', label: 'Prioritás' },
];

export default function ProjectTable() {

  useTitle([{ name: 'Projektek' }])

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("project-rows-per-page") || 25);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchParams, setSearchParams] = React.useState(JSON.parse(localStorage.getItem("project-search-params")))
  const [expanded, setExpanded] = React.useState(true);

  useEffect(() => {
    projectService.search(searchParams?.name, searchParams?.status, searchParams?.priority, page, rowsPerPage,
      (response, error) => {
        if (response) {
          const result = response.content.map((project) => {
            let status = "";
            switch (project.status) {
              case "IN_PROGRESS": status = "Folyamatban"; break;
              case "NOT_STARTED": status = "Nincs elkezdve"; break;
              case "DONE": status = "Befejezve"; break;
              default: status = ""; break;
            }

            let priority = "";
            switch (project.priority) {
              case "EXTRA_IMPORTANT": priority = "Extra fontos"; break;
              case "IMPORTANT": priority = "Fontos"; break;
              case "NORMAL": priority = "Normál"; break;
              case "NOT_IMPORTANT": priority = "Nem fontos"; break;
              default: priority = ""; break;
            }
            return { ...project, status: status, priority: priority }
          })
          setTotalCount(response.totalElements)
          setRows(result);
        }
      }
    );
  }, [page, rowsPerPage, searchParams]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    setRowsPerPage(event.target.value);
    localStorage.setItem("project-rows-per-page", rowsPerPage)
    setPage(0);
  };

  const handleExtension = () => {
    setExpanded(!expanded);
  };

  const onSearchParamsChange = (event) => {
    const { name, value } = event.target
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [name]: value
    }))
  }

  const clear = () => {
    setSearchParams(undefined)
  }

  useEffect(() => {
    if (searchParams) localStorage.setItem("project-search-params", JSON.stringify(searchParams));
    else localStorage.removeItem("project-search-params")
  }, [searchParams]);

  const navigate = useNavigate();

  return (
    <>
      <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => handleExtension()}>
        <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
          Keresési Feltételek
        </AccordionSummary>
        <AccordionDetails className="filter-panel">
          <Grid container columns={20} spacing={2} padding={"10px"}>
            <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                label="Név"
                name="name"
                autoComplete="off"
                onChange={onSearchParamsChange}
                sx={{ marginTop: "8px" }}
                value={(searchParams && searchParams.name) || ""}
              />
            </Grid>

            <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
              <FormControl sx={{ m: 1, minWidth: 120, marginLeft: "0px" }} fullWidth>
                <InputLabel id="status-label">Státusz</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="status-label"
                  name="status"
                  label="Státusz"
                  value={(searchParams && searchParams.status) || ""}
                  onChange={onSearchParamsChange}
                  sx={{ textAlign: "left", padding: "0px" }}
                >
                  <MenuItem value={null}>Összes</MenuItem>
                  <MenuItem value={"NOT_STARTED"}>Nincs elkezdve</MenuItem>
                  <MenuItem value={"IN_PROGRESS"}>Folyamatban</MenuItem>
                  <MenuItem value={"DONE"}>Befejezve</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px" }}>
              <FormControl sx={{ m: 1, minWidth: 120, marginLeft: "0px" }} fullWidth>
                <InputLabel id="status-label">Prioritás</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="priority-label"
                  name="priority"
                  label="Prioritás"
                  value={(searchParams && searchParams.priority) || ""}
                  onChange={onSearchParamsChange}
                  sx={{ textAlign: "left" }}
                >
                  <MenuItem value={null}>Összes</MenuItem>
                  <MenuItem value={"EXTRA_IMPORTANT"}>Extra fontos</MenuItem>
                  <MenuItem value={"IMPORTANT"}>Fontos</MenuItem>
                  <MenuItem value={"NORMAL"}>Normál</MenuItem>
                  <MenuItem value={"NOT_IMPORTANT"}>Nem fontos</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px", margin: "8px 0px 10px 0px" }}>
              <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
            </Grid>

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: "calc(100vh - " + (expanded ? "340" : "230") + "px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                  <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                    <GetApp fontSize="large" />
                  </Button>
                  <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/projects/create")}>
                    <Add fontSize="large" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} onClick={() => navigate("/dashboard/projects/" + row.id)} sx={{cursor: "pointer", padding: "10px"}}>
                          {value || ""}
                        </TableCell>
                      );
                    })}
                    <TableCell key="actions" padding='none' align='right'>
                      <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                        onClick={() => projectService.deleteProject(row.id, (response, error) => {
                          if (!error) {
                            navigate(0)
                          }
                        })}>
                        <Delete />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}

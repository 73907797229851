import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VehicleLogEditorTemplate from './VehicleLogEditorTemplate';
import vehicleLogService from '../../../services/VehicleLogsService';
import authService from '../../../services/AuthService';
import axios from 'axios';
import { API_URL } from '../../../App';
import { useTitle } from '../../dashboard/TitleContext';

const VehicleLogEditor = () => {

  const vehicleId = window.location.pathname.split('/')[3]
  const id = window.location.pathname.split('/')[5] === 'create' ? undefined : window.location.pathname.split('/')[5]

  useTitle([
    { name: 'Jármű Lista', url: '/dashboard/vehicles' },
    { name: 'Jármű', url: '/dashboard/vehicles/' + vehicleId},
    { name: 'Történet', url: '/dashboard/vehicles/' + vehicleId + '/logs' },
    { name: 'Bejegyzés', url: '/dashboard/vehicles/' + vehicleId + '/logs/' + (id || 'create') }
  ])

  const [fullImage, setFullImage] = useState()

  const [vehicleLog, setVehicleLog] = useState({
    vehicleId: vehicleId,
    text: "",
    image: undefined,
    createdAt: "",
    updatedAt: ""
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      vehicleLogService.getVehicleLog(id, (response, error) => {
        if (response) {
          setVehicleLog((prevVehicleLog) => ({
            ...prevVehicleLog,
            ...response,
          }));
        } else console.log(error)
      });
      downloadImage(id)
    }
  }, [id]);


  const downloadImage = (id) => {
    const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
    axios({
      url: API_URL + "/vehicle-logs/" + id + "/image",
      method: 'GET',
      responseType: 'blob',
      headers: headers,
    })
    .then(response => setFullImage(response.data))
    .catch(error => console.error(error));
  }

  const onChange = (event) => {
    const { name, value } = event.target;
    setVehicleLog((prevVehicleLog) => ({
      ...prevVehicleLog,
      [name]: value,
    }));
  };

  const setImage = (image) => {
    setVehicleLog((prevVehicleLog) => ({
      ...prevVehicleLog,
      image: image,
    }));
  }

  const create = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('vehicleId', vehicleLog.vehicleId);
    formData.append('text', vehicleLog.text);
    if(vehicleLog.image) formData.append('image', vehicleLog.image);

    vehicleLogService.createVehicleLog(formData, (response, error) => {
      if (!error) navigate(-1)
      else setMessage(error === undefined ? 'Szerver nem elérhető' : error);
    })
  };

  const update = (e) => {
    e.preventDefault();
    vehicleLogService.updateVehicleLog(id, vehicleLog, (response, error) => {
      if (!error) {
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <VehicleLogEditorTemplate
      data={{
        id,
        vehicleLog,
        fullImage,
        message,
        onChange,
        setImage,
        create,
        update,
      }}
    />
  );
};

export default VehicleLogEditor;

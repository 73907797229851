import { Button, Container, TextField, Alert, Grid, Divider, styled, CircularProgress } from "@mui/material"
import { saveAs } from 'file-saver';
import React from "react"
import { useNavigate } from 'react-router-dom';

export default function VehicleLogEditorTemplate(props) {

    const { id, onChange, create, update, message, fullImage, setImage } = props.data

    const { text, preview, image, createdAt, updatedAt } = props.data.vehicleLog

    console.log(fullImage)

    const navigate = useNavigate();

    const VisuallyHiddenInput = styled('input')({
        width: 0
    });

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={id ? update : create}>
                        <Grid container spacing={2} columns={20}>

                            <Grid item lg={5} md={10} xs={20}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    id="text"
                                    label={"Bejegyzés"}
                                    name="text"
                                    type="text"
                                    autoFocus
                                    autoComplete="off"
                                    onChange={onChange}
                                    value={text}
                                />
                            </Grid>



                            {id ?
                                <Grid item lg={13} md={10} xs={20} align={"left"}>
                                    {fullImage ?
                                        <img src={URL.createObjectURL(new Blob([fullImage]))} alt="Kép" style={{ minHeight: "148px", maxWidth: "800px", cursor: "pointer", marginTop: "16px" }} onClick={() => saveAs(fullImage, "image.png")}></img>
                                        : (!preview ? "" : <CircularProgress />)
                                    }
                                </Grid>
                                :
                                <>
                                    <Grid item lg={2} md={10} xs={20}>
                                        {id ? () => getPreview(preview) : <Button component="label" variant="contained" color='primary' fullWidth style={{ padding: "0px", minWidth: "30px", marginRight: "10px", height: "56px", marginTop: "16px" }}>
                                            {image ? image.name : "Kép Kiválasztása"}
                                            <VisuallyHiddenInput type="file" onChange={(e) => setImage(e.target.files[0])} />
                                        </Button>}
                                    </Grid>
                                    <Grid item lg={11} md={10} xs={20} align={"left"}>

                                        <img src={image && URL.createObjectURL(image)} alt="" height={"148px"} style={{ marginTop: "16px" }} />
                                    </Grid>
                                </>
                            }



                            {id !== undefined &&
                                <>
                                    <Grid item lg={20} md={20} xs={20}>
                                        <Divider />
                                    </Grid>

                                    {createButton("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
                                    {createButton("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
                                    <Grid item lg={10} xs={20}></Grid>
                                </>

                            }

                            {message &&
                                <Grid item lg={20} xs={20}>
                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                </Grid>
                            }

                            <Grid item lg={20} md={20} xs={20}></Grid>


                            <Grid item lg={2} md={5} xs={2}>
                                <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                    Vissza
                                </Button>
                            </Grid>

                            <Grid item lg={16} md={10} xs={2}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </div>

            </Container>
        </div>
    )
}

function getPreview(preview) {
    const blob = new Blob([new Uint8Array([...atob(preview)]
        .map(char => char.charCodeAt(0)))], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);
    return <img src={imageUrl} alt="" />
}

function createButton(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
    return (<Grid item lg={5} md={10} xs={20}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type="text"
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    </Grid>)
}
import Service from "./Service"

class ProjectSubTaskService extends Service {

    findById = (id, callback) => this.get("/project-tasks/sub-tasks/" + id, callback)

    createProjectSubTask = (projectTaskId, projectSubTaskRequest, callback) => this.post("/project-tasks/" + projectTaskId + "/sub-tasks", projectSubTaskRequest, callback)

    updateProjectSubTask = (projectSubTaskId, projectSubTaskRequest, callback) => this.patch("/project-tasks/sub-tasks/" + projectSubTaskId, projectSubTaskRequest, callback)

    deleteProjectSubTask = (projectSubTaskId, callback) => this.del("/project-tasks/sub-tasks/" + projectSubTaskId, callback)
}
const projectSubTaskService = new ProjectSubTaskService()
export default projectSubTaskService
import React, { useState, useEffect } from 'react';
import ProductEditorTemplate from './ProductEditorTemplate';
import productService from '../../services/ProductService';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';

const ProductEditor = () => {

  const id = window.location.pathname.split('/')[4];

  useTitle([
    { name: 'Raktár', url: '/dashboard/storage' },
    { name: 'Termék', url: '/dashboard/storage/' + id }
  ])

  const [product, setProduct] = useState();
  const [message, setMessage] = useState('');
  const [waitingForServer, setWaitingForServer] = useState(false)
  const [waitingForCorrection, setWaitingForCorrection] = useState(false)
  const { sendAlertMessage } = useSnackbar()

  const navigate = useNavigate()

  useEffect(() => {
    retrieveProduct()
  }, [id]);

  const retrieveProduct = () => {
    if (id) {
      productService.findById(id, (response, error) => {
        if (response) {
          setProduct(response);
        } else {
          console.log(error);
        }
      })
    }
  }

  const onChange = (event) => {
    const { name, value } = event.target;
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeCheckbox = (event) => {
    const { checked, name } = event.target
    setProduct((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const onChangeSelectItem = (newValue, field) => {
    setProduct((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const update = (e) => {
    e.preventDefault();
    setWaitingForServer(true)
    productService.updateProduct(id, product, (response, error) => {
      if (!error) {
        sendAlertMessage('Termék sikeresen módosítva')
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
      setWaitingForServer(false)
    });
  };

  const correctAmount = (productItemId, amount) => {
    setWaitingForCorrection(true)
    productService.correctProductItemAmount(productItemId, amount, (response, error) => {
      if (!error) {
        retrieveProduct()
        sendAlertMessage('Sikeres mennyiség korrekció')
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
      setWaitingForCorrection(false)
    })
  }

  return (
    <ProductEditorTemplate
      {...{
        id,
        product,
        message,
        onChange,
        onChangeCheckbox,
        onChangeSelectItem,
        update,
        correctAmount,
        waitingForServer,
        navigate,
        waitingForCorrection
      }}
    />
  );
};

export default ProductEditor;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import subStorageService from '../../services/SubStorageService';
import SubStorageEditorTemplate from './SubStorageEditorTemplate';
import { useTitle } from '../dashboard/TitleContext';

const SubStorageEditor = () => {

  const [id] = useState(
    window.location.pathname.split('/')[4] === 'create'
      ? undefined
      : window.location.pathname.split('/')[4]
  );

  useTitle([
    {name: 'Alraktár Lista', url: '/dashboard/storage/substorages'},
    {name: 'Alkraktár', url: '/dashboard/storage/substorages/' + (id || 'create') }
])

  const [subStorage, setSubStorage] = useState({
    name: "",
    createdAt: "",
    updatedAt: ""
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      subStorageService.findById(
        id,
        (response, error) => {
          if (response) {
            console.log(response)
            setSubStorage((prevSubStorage) => ({
              ...prevSubStorage,
              ...response,
            }));
          } else {
            console.log(error);
          }
        },
      );
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setSubStorage((prevSubStorage) => ({
      ...prevSubStorage,
      [name]: value,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    subStorageService.createSubStorage(subStorage, (response, error) => {
      if (!error) {
        navigate('/dashboard/storage/substorages');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    subStorageService.updateSubStorage(id, subStorage, (response, error) => {
      if (!error) {
        navigate('/dashboard/storage/substorages');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <SubStorageEditorTemplate
      data={{
        id,
        subStorage,
        message,
        onChange,
        create,
        update,
      }}
    />
  );
};

export default SubStorageEditor;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import projectSubTaskService from '../../services/ProjectSubTaskService';
import ProjectSubTaskEditorTemplate from './ProjectSubTaskEditorTemplate';
import vehicleService from '../../services/VehicleService';
import userService from '../../services/UserService';
import dayjs from 'dayjs';
import { toString } from '../../util/DateConverter';
import { useTitle } from '../dashboard/TitleContext';
import projectTaskService from '../../services/ProjectTaskService';
import { limit } from '../../util/StringUtils';
import { useSnackbar } from '../../util/SnackbarProvider';

const ProjectSubTaskEditor = () => {

    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[4]
    const projectTaskId = urlParts[6]
    const id = urlParts[8] === 'create' ? undefined : urlParts[8]
    const [waitingForServer, setWaitingForServer] = useState(false)
    const [message, setMessage] = useState('');
    const navigate = useNavigate()
    const [projectSubTask, setProjectSubTask] = useState({
        createdAt: "",
        updatedAt: ""
    });
    const [projectTask, setProjectTask] = useState({})
    const { sendAlertMessage } = useSnackbar()

    useEffect(() => {
        projectTaskService.findById(projectTaskId, true, (response, error) => {
            if (response) setProjectTask(response)
        })
    }, [projectTaskId])

    useTitle([
        { name: 'Projekt Feladatok', url: '/dashboard/project-tasks/projects' },
        { name: projectTask.project?.name || 'Projekt', url: '/dashboard/project-tasks/projects/' + projectId },
        { name: projectTask.taskType?.name || 'Feladat', url: '/dashboard/project-tasks/projects/' + projectId + '/tasks/' + projectTaskId },
        { name: limit(projectSubTask.description, 30) || 'Alfeladat', url: '/dashboard/project-tasks/projects/' + projectId + '/tasks/' + projectTaskId + '/tasks/' + (id || 'create') }
    ], projectTask.project?.name)

    const [users, setUsers] = useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else console.log(error);
            },);
    }, []);

    const [vehicles, setVehicles] = useState([]);
    useEffect(() => {
        vehicleService.search(
            (response, error) => {
                if (response) setVehicles(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    useEffect(() => {
        if (id) {
            projectSubTaskService.findById(id, (response, error) => {
                if (response) {
                    setProjectSubTask((prevProjectSubTask) => ({
                        ...prevProjectSubTask,
                        ...response,
                        deadline: response.deadline ? dayjs(response.deadline) : null,
                        scheduleDateStart: response.scheduleDateStart ? dayjs(response.scheduleDateStart) : null,
                        scheduleDateEnd: response.scheduleDateEnd ? dayjs(response.scheduleDateEnd) : null,
                    }));
                } else console.log(error);
            },
            );
        }
    }, [id]);

    const onChange = (event) => {
        const { name, value } = event.target;
        setProjectSubTask((prevProjectSubTask) => ({
            ...prevProjectSubTask,
            [name]: value,
        }));
    };

    const create = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        projectSubTaskService.createProjectSubTask(projectTaskId, prepareRequest(), (response, error) => {
            if (!error) {
                navigate(-1);
                sendAlertMessage('Alfeladat sikeresen létrehozva')
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const update = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        projectSubTaskService.updateProjectSubTask(id, prepareRequest(), (response, error) => {
            if (!error) {
                navigate(-1);
                sendAlertMessage('Alfeladat sikeresen mentve')
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const saveAsNew = () => {
        const request = prepareRequest()
        delete request.id
        request.description = incrementDescription(request.description)
        setWaitingForServer(true)
        projectSubTaskService.createProjectSubTask(projectTaskId, request, (response, error) => {
            if (!error) {
                sendAlertMessage('Alfeladat sikeresen klónozva')
                navigate(-1)
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    }

    const incrementDescription = (description) => {
        const match = description.match(/(.*?)(\d+)?$/);
        const baseDescription = match[1].trim();
        const currentNumber = match[2] ? parseInt(match[2]) : 1;
        return `${baseDescription} ${currentNumber + 1}`;
    };

    const prepareRequest = () => {
        const copy = structuredClone(projectSubTask)
        copy.deadline = toString(copy.deadline)
        copy.scheduleDateStart = toString(copy.scheduleDateStart)
        copy.scheduleDateEnd = toString(copy.scheduleDateEnd)
        if (projectSubTask.users) copy.userIds = projectSubTask.users.map(user => user.id)
        if (projectSubTask.vehicle) copy.vehicleId = projectSubTask.vehicle.id
        delete copy.vehicle
        delete copy.users
        return copy
    }

    const onUsersChange = (event) => {
        const { value } = event.target
        const newSelection = typeof value === 'string' ? value.split(',') : value;
        const uniqueSelection = newSelection.reduce((acc, user) => {
            const exists = acc.find(item => item.id === user.id);
            if (exists) return acc.filter(item => item.id !== user.id);
            else return [...acc, user];
        }, []);

        setProjectSubTask((prev) => ({
            ...prev,
            users: uniqueSelection,
        }));
    };

    const onChangeSelectItem = (newValue, name) => {
        setProjectSubTask((prevProjectSubTask) => ({
            ...prevProjectSubTask,
            [name]: newValue
        }));
    }

    const onDateTimeChange = (newValue, name) => {
        setProjectSubTask((prevProjectSubTask) => ({
            ...prevProjectSubTask,
            [name]: newValue,
        }));
    };

    return (
        <ProjectSubTaskEditorTemplate
            data={{
                id,
                projectSubTask,
                message,
                onChange,
                create,
                saveAsNew,
                update,
                onUsersChange,
                onChangeSelectItem,
                onDateTimeChange,
                waitingForServer,
                vehicles,
                users
            }}
        />
    );
};

export default ProjectSubTaskEditor;

import { Add, ArrowDownward, ArrowUpward, Remove } from "@mui/icons-material";
import { Button, Container, TextField, Alert, Grid, Divider, Autocomplete, Checkbox, FormControlLabel, Hidden, CircularProgress } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';
import { nonProjects } from "../../util/Const";

export default function TaskLogEditorTemplate(props) {

    const { id, onChange, onChangeItem, onChangeSelectItem, onChangeCheckbox, onChangeCheckboxItem, onUserChange, addRow, removeLastRow,
        create, update, message, users, taskTypes, projects, vehicles, swapRecords, waitingForServer } = props.data

    const { user, date, lunchBreak, lateness, taskLogItems, createdAt, updatedAt } = props.data.tasklog

    const navigate = useNavigate();

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={id ? update : create}>
                        <Grid container spacing={2} columns={30}>
                            {id != null && taskLogItems == null ? <Grid item xs={30}><CircularProgress sx={{ margin: "200px" }} /> </Grid> :
                                <>
                                    <Grid item lg={10} md={15} xs={30}>
                                        <Autocomplete
                                            value={user}
                                            margin="normal"
                                            id="user"
                                            label="user"
                                            name="user"
                                            onChange={(event, newValue) => onUserChange(newValue)}
                                            options={users}
                                            getOptionLabel={(user) => user.name}
                                            style={{ marginTop: "16px" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Munkavállaló" required />
                                            )}
                                        />
                                    </Grid>
                                    {createButton("date", "Dátum", date, onChange, true, true, false, "date")}

                                    <Grid item xl={5} lg={5} md={15} xs={15} style={{ textAlign: "left" }}>
                                        <FormControlLabel control={<Checkbox
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="large"
                                            id="lunchBreak"
                                            label="lunchBreak"
                                            name="lunchBreak"
                                            onChange={onChangeCheckbox}
                                            checked={lunchBreak}
                                            value={lunchBreak}
                                        />} label="Ebédszünet"
                                            style={{ paddingTop: "20px" }} />
                                    </Grid>

                                    <Grid item xl={5} lg={5} md={15} xs={15} style={{ textAlign: "left" }}>
                                        <FormControlLabel control={<Checkbox
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            size="large"
                                            id="lateness"
                                            label="lateness"
                                            name="lateness"
                                            onChange={onChangeCheckbox}
                                            checked={lateness}
                                            value={lateness}
                                        />} label="Késés"
                                            style={{ paddingTop: "20px" }} />
                                    </Grid>

                                    <Grid item lg={30} md={30} xs={30}><Divider style={{ border: "1px solid gray" }} /></Grid>

                                    {Array.from({ length: taskLogItems.length }, (_, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xl={1} lg={2} md={2} sm={3} xs={5}>
                                                <Button disabled={index === 0} type="button" variant="contained" sx={{ padding: 0, marginLeft: "10px", marginRight: "10px", minWidth: 0 }} onClick={() => swapRecords(index, index - 1)}>
                                                    <ArrowUpward fontSize="large" />
                                                </Button>
                                                <Button disabled={index === taskLogItems.length - 1} type="button" variant="contained" sx={{ padding: 0, marginLeft: "10px", marginRight: "10px", minWidth: 0, marginTop: 1 }} onClick={() => swapRecords(index, index + 1)}>
                                                    <ArrowDownward fontSize="large" />
                                                </Button>
                                            </Grid>
                                            {createSubField({
                                                name: "startTime",
                                                label: "Kezdés",
                                                value: taskLogItems[index].startTime,
                                                key: taskLogItems[index].startTime,
                                                onChange: (e) => onChangeItem(e, index),
                                                required: true,
                                                type: "time",
                                                lgWidth: 4,
                                                xlWidth: 2,
                                                mdWidth: 8,
                                                smWidth: 27,
                                                xsWidth: 25
                                            })}
                                            <Grid item xl={4} lg={8} md={10} xs={30}>
                                                <Autocomplete
                                                    value={taskLogItems[index].project}
                                                    key={taskLogItems[index].project}
                                                    margin="normal"
                                                    id="project"
                                                    label="project"
                                                    name="project"
                                                    autoHighlight
                                                    autoSelect
                                                    disableClearable
                                                    noOptionsText="Nincs ilyen projekt"
                                                    onChange={(event, newValue) => onChangeSelectItem(index, newValue, "project")}
                                                    options={projects}
                                                    getOptionLabel={(project) => project.name}
                                                    style={{ marginTop: "16px" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Projekt" required />
                                                    )}
                                                />
                                            </Grid>
                                            {taskLogItems[index].project && taskLogItems[index].project.name !== "Befejezés" && !nonProjects.includes(taskLogItems[index].project.name) &&
                                                <Grid item xl={4} lg={8} md={10} xs={30}>
                                                    <Autocomplete
                                                        value={taskLogItems[index].taskType}
                                                        key={taskLogItems[index].taskType}
                                                        margin="normal"
                                                        id="taskType"
                                                        label="taskType"
                                                        name="taskType"
                                                        disableClearable
                                                        onChange={(event, newValue) => onChangeSelectItem(index, newValue, "taskType")}
                                                        options={taskTypes}
                                                        getOptionLabel={(taskType) => taskType.name}
                                                        style={{ marginTop: "16px" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Feladat Típus" required />
                                                        )}
                                                    />
                                                </Grid>}
                                            {taskLogItems[index].taskType &&
                                                <Grid item xl={3} lg={8} md={15} xs={30}>
                                                    <Autocomplete
                                                        value={taskLogItems[index].location}
                                                        key={taskLogItems[index].location}
                                                        margin="normal"
                                                        id="location"
                                                        label="location"
                                                        name="location"
                                                        freeSolo
                                                        autoSelect
                                                        onChange={(event, newValue) => onChangeSelectItem(index, newValue, "location")}
                                                        options={taskLogItems[index].project.secondaryLocation ? ["Mohács", taskLogItems[index].project.secondaryLocation] : ["Mohács"]}
                                                        getOptionLabel={(location) => location}
                                                        style={{ marginTop: "16px" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Helyszín" required />
                                                        )}
                                                    />
                                                </Grid>}

                                            {taskLogItems[index].taskType && (() => {
                                                switch (taskLogItems[index].taskType && taskLogItems[index].taskType.name) {
                                                    case 'Szállás':
                                                        return (
                                                            <>
                                                                <Hidden mdDown>
                                                                    <Grid item xl={10} lg={14} md={10}></Grid>
                                                                </Hidden>
                                                            </>)
                                                    case 'Jármű Utas':
                                                        return (
                                                            <>
                                                                <Grid item xl={3} lg={5} md={15} xs={30}>
                                                                    <Autocomplete
                                                                        value={taskLogItems[index].vehicle}
                                                                        key={taskLogItems[index].vehicle}
                                                                        margin="normal"
                                                                        id="vehicle"
                                                                        label="vehicle"
                                                                        name="vehicle"
                                                                        disableClearable
                                                                        onChange={(event, newValue) => onChangeSelectItem(index, newValue, "vehicle")}
                                                                        options={vehicles}
                                                                        getOptionLabel={(vehicle) => vehicle.licensePlate}
                                                                        style={{ marginTop: "16px" }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Jármű" required />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Hidden mdDown>
                                                                    <Grid item xl={7} lg={9} md={0} xs={0}></Grid>
                                                                </Hidden>
                                                            </>
                                                        )
                                                    case 'Jármű Vezető':
                                                        return (
                                                            <>
                                                                <Grid item xl={3} lg={5} md={15} xs={30}>
                                                                    <Autocomplete
                                                                        value={taskLogItems[index].vehicle}
                                                                        key={taskLogItems[index].vehicle}
                                                                        margin="normal"
                                                                        id="vehicle"
                                                                        label="vehicle"
                                                                        name="vehicle"
                                                                        disableClearable
                                                                        onChange={(event, newValue) => onChangeSelectItem(index, newValue, "vehicle")}
                                                                        options={vehicles}
                                                                        getOptionLabel={(vehicle) => vehicle.licensePlate}
                                                                        style={{ marginTop: "16px" }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Jármű" required />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                {createSubField({
                                                                    name: "odometer",
                                                                    label: "Kilométer Óra",
                                                                    value: taskLogItems[index].odometer,
                                                                    onChange: (e) => onChangeItem(e, index),
                                                                    xlWidth: 3,
                                                                    lgWidth: 4,
                                                                    mdWidth: 15,
                                                                    xsWidth: 12
                                                                })}
                                                                {createSubField({
                                                                    name: "fuelCost",
                                                                    label: "Üzemanyag Költség (Ft)",
                                                                    value: taskLogItems[index].fuelCost,
                                                                    onChange: (e) => onChangeItem(e, index),
                                                                    lgWidth: 5,
                                                                    mdWidth: 15,
                                                                    xsWidth: 18
                                                                })}
                                                            </>
                                                        )
                                                    default:
                                                        return (
                                                            <>
                                                                <Grid item xl={5} lg={7} md={8} xs={15} style={{ textAlign: "left" }}>
                                                                    <FormControlLabel control={<Checkbox
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        size="large"
                                                                        id="teamLeader"
                                                                        label="teamLeader"
                                                                        name="teamLeader"
                                                                        onChange={(e) => onChangeCheckboxItem(e, index)}
                                                                        checked={taskLogItems[index].teamLeader}
                                                                        value={taskLogItems[index].teamLeader}
                                                                        key={taskLogItems[index].teamLeader}
                                                                    />} label="Csoportvezető"
                                                                        style={{ paddingTop: "20px" }} />
                                                                </Grid>
                                                                <Grid item xl={5} lg={7} md={7} xs={15} ></Grid>
                                                            </>
                                                        )
                                                }
                                            })()}

                                            {taskLogItems[index].taskType && createSubField({
                                                name: "comment",
                                                label: ["Jármű Utas", "Jármű Vezető"].includes(taskLogItems[index].taskType.name) ? "Járművel Kapcsolatos Megjegyzés" :
                                                    ["Szállás"].includes(taskLogItems[index].taskType.name) ? "Szállással Kapcsolatos Megjegyzés" : "Projekttel Kapcsolatos Megjegyzés",
                                                value: taskLogItems[index].comment,
                                                onChange: (e) => onChangeItem(e, index),
                                                xlWidth: 6,
                                                lgWidth: 15,
                                                mdWidth: 29
                                            })}
                                            <Grid item lg={30} md={30} xs={30}><Divider style={{ border: "1px solid gray" }} /></Grid>
                                        </React.Fragment>
                                    ))}

                                    <Grid item lg={30} md={30} xs={30}></Grid>
                                    <Grid item minWidth={"70px"}>
                                        <Button type="button" variant="contained" color="success" onClick={() => addRow()}>
                                            <Add />
                                        </Button>
                                    </Grid>

                                    <Grid item minWidth={"70px"}>
                                        <Button type="button" variant="contained" color="error" onClick={() => removeLastRow()}>
                                            <Remove />
                                        </Button>
                                    </Grid>
                                    <Grid item lg={30} md={30} xs={30}></Grid>

                                    {id !== undefined &&
                                        <>
                                            <Grid item lg={30} md={30} xs={30}>
                                                <Divider />
                                            </Grid>

                                            {createButton("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
                                            {createButton("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
                                            <Grid item lg={15} xs={30}></Grid>
                                        </>

                                    }
                                </>}

                            <Grid item lg={30} md={30} xs={30}></Grid>

                            {message &&
                                <Grid item lg={30} xs={30}>
                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                </Grid>
                            }

                            <Grid item lg={3} md={6} xs={10}>
                                <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/task-logs")}>
                                    Vissza
                                </Button>
                            </Grid>

                            <Grid item lg={24} md={18} xs={10}></Grid>
                            {taskLogItems &&
                                <Grid item lg={3} md={6} xs={10}>
                                    {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                        Mentés
                                    </Button>}
                                </Grid>
                            }

                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    )
}

function createButton(name, label, value, onChange, required = false, autoFocus = false, disabled = false, type = "text") {
    return (<Grid item xl={5} lg={10} md={15} xs={30}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type={type}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    </Grid>)
}

function createSubField({
    name,
    label,
    value,
    onChange,
    required = false,
    autoFocus = false,
    disabled = false,
    type = "text",
    xlWidth = 4,
    lgWidth = 4,
    mdWidth = 10,
    xsWidth = 30,
    smWidth = 30,
    minWidth = 0 }) {
    return (<Grid item xl={xlWidth} lg={lgWidth} md={mdWidth} sm={smWidth} xs={xsWidth} minWidth={minWidth}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type={type}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    </Grid>)
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import projectTaskService from '../../services/ProjectTaskService';
import ProjectTaskEditorTemplate from './ProjectTaskEditorTemplate';
import projectService from '../../services/ProjectService';
import taskTypeService from '../../services/TaskTypeService';
import vehicleService from '../../services/VehicleService';
import userService from '../../services/UserService';
import { toString } from '../../util/DateConverter';
import projectSubTaskService from '../../services/ProjectSubTaskService';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';

const priorities = [
    { id: "EXTRA_IMPORTANT", name: "Extra Fontos" },
    { id: "IMPORTANT", name: "Fontos" },
    { id: "NORMAL", name: "Normál" },
    { id: "NOT_IMPORTANT", name: "Nem Fontos" }
]

const statuses = [
    { id: "NOT_STARTED", name: "Nincs elkezdve" },
    { id: "IN_PROGRESS", name: "Folyamatban" },
    { id: "BLOCKED", name: "Elakadva" },
    { id: "OVERDUE", name: "Csúszásban" },
    { id: "DONE", name: "Befejezve" }
]

const ProjectTaskEditor = () => {

    const [waitingForServer, setWaitingForServer] = useState(false)
    const [nonFinishedOnly, setNonFinishedOnly] = useState(true)

    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[4]
    const id = urlParts[6] === 'create' ? undefined : urlParts[6]
    const { sendAlertMessage } = useSnackbar()

    const [projectTask, setProjectTask] = useState({
        users: [],
        priority: "NORMAL",
        status: "NOT_STARTED",
        progressLevel: "0"
    });

    const [projectName, setProjectName] = useState('Projekt');

    useEffect(() => {
        projectService.findById(projectId, (response, error) => {
            if (response) setProjectName(response.name);
        });
    }, [projectName]);

    useTitle([
        {name: 'Projekt Feladatok', url: '/dashboard/project-tasks/projects'},
        {name: projectName || 'Projekt', url: '/dashboard/project-tasks/projects/' + projectId},
        {name: projectTask.taskType?.name || (id ? 'Feladat' : 'Új Feladat'), url: '/dashboard/project-tasks/projects/'+projectId + '/tasks/' + (id || 'create') }
    ], projectName)

    const [message, setMessage] = useState('');
    const navigate = useNavigate()

    const [users, setUsers] = useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    const [projects, setProjects] = React.useState([]);
    useEffect(() => {
        projectService.getSelectors(
            (response, error) => {
                if (response) setProjects(response);
                else console.log(error);
            },
        );
    }, []);

    const [taskTypes, setTaskTypes] = useState([]);
    useEffect(() => {
        taskTypeService.getSelectors(
            (response, error) => {
                if (response) setTaskTypes(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    const [vehicles, setVehicles] = useState([]);
    useEffect(() => {
        vehicleService.search(
            (response, error) => {
                if (response) setVehicles(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    useEffect(() => {
        retrieveProjectTask()
    }, [id, users, projects, vehicles, taskTypes, nonFinishedOnly]);

    function retrieveProjectTask() {
        if (id) {
            projectTaskService.findById(id, nonFinishedOnly, (response, error) => {
                if (response) {
                    const subTasks = response.subTasks.map(subTask => ({
                        ...subTask,
                        status: statuses.find(s => s.id === subTask.status)?.name,
                        priority: priorities.find(s => s.id === subTask.priority)?.name
                    }));
                    setProjectTask((prevProjectTask) => ({
                        ...prevProjectTask,
                        ...response,
                        subTasks: subTasks
                    }));
                } else console.log(error);
            })
        }
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setProjectTask((prevProjectTask) => ({
            ...prevProjectTask,
            [name]: value,
        }));
    };

    const onChangeCheckbox = (event) => {
        const { checked } = event.target;
        setNonFinishedOnly(checked)
    };

    const setDate = (newValue, name) => {
        setProjectTask((prevProjectTask) => ({
            ...prevProjectTask,
            [name]: newValue,
        }));
    };

    const onChangeSelectItem = (newValue, name) => {
        setProjectTask((prevProjectTask) => ({
            ...prevProjectTask,
            [name]: newValue
        }));
    }

    const create = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        const request = prepareRequest()
        projectTaskService.createProjectTask(projectId, request, (response, error) => {
            if (!error) {
                navigate(-1);
                sendAlertMessage('Feladat sikeresen létrehozva')
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const update = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        const request = prepareRequest()
        projectTaskService.updateProjectTask(id, request, (response, error) => {
            if (!error) {
                navigate(-1);
                sendAlertMessage('Feladat sikeresen mentve')
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const deleteSubTask = (dialogState) => {
        setProjectTask((prevProjectTask) => ({
            ...prevProjectTask,
            subTasks: null
        }));
        projectSubTaskService.deleteProjectSubTask(dialogState.object?.id, (response, error) => { if (!error) {
            retrieveProjectTask()
            sendAlertMessage('Alfeladat sikeresen törölve') 
        }})
    }

    const prepareRequest = () => {
        const copy = structuredClone(projectTask)
        if (projectTask.taskType) copy.taskTypeId = projectTask.taskType.id
        if (projectTask.vehicle) copy.vehicleId = projectTask.vehicle.id
        copy.deadline = toString(projectTask.deadline)
        copy.scheduleDateStart = toString(projectTask.scheduleDateStart)
        copy.scheduleDateEnd = toString(projectTask.scheduleDateEnd)
        copy.userIds = projectTask.users.map(user => user.id)
        delete copy.taskType
        delete copy.vehicle
        delete copy.users
        return copy
    }

    const onUsersChange = (event) => {
        const { value } = event.target
        const newSelection = typeof value === 'string' ? value.split(',') : value;
        const uniqueSelection = newSelection.reduce((acc, user) => {
            const exists = acc.find(item => item.id === user.id);
            if (exists) return acc.filter(item => item.id !== user.id);
            else return [...acc, user];
        }, []);

        setProjectTask((prev) => ({
            ...prev,
            users: uniqueSelection,
        }));
    }

    const copyProjectTask = (projectId) => {
        projectTaskService.copyProjectTask({ projectTaskId: id, projectId: projectId }, (response, error) => {
            if (!error) {
                sendAlertMessage('Feladat másolása sikeres')
            }
        })
    }

    return (
        <ProjectTaskEditorTemplate
            data={{
                id,
                projectId,
                projectTask,
                message,
                projects,
                taskTypes,
                vehicles,
                users,
                onChange,
                onChangeCheckbox,
                onUsersChange,
                deleteSubTask,
                setDate,
                onChangeSelectItem,
                create,
                update,
                waitingForServer,
                copyProjectTask,
                nonFinishedOnly
            }}
        />
    );
};

export default ProjectTaskEditor;

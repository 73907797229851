import Service from "./Service"

class NoteService extends Service {

    findById = (id, callback) => this.get("/notes/" + id, callback)

    findAll = (queryParams, callback) => this.getWithParams("/notes", queryParams, callback)

    createNote = (noteRequest, callback) => this.post("/notes", noteRequest, callback)

    updateNote = (noteId, noteRequest, callback) => this.patch("/notes/" + noteId, noteRequest, callback)

    deleteNote = (noteId, callback) => this.del("/notes/" + noteId, callback)
}
const noteService = new NoteService()
export default noteService
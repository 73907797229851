import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { useTitle } from '../dashboard/TitleContext';

export default function ChangeLogs() {

    useTitle([{ name: 'Verzió Történet' }])

    return (
        <>
            {addVersion("v1.2.5", "2024-11-09",
                <ul>
                    <li>Számlák kategóriákba sorolása és tranzakció típusának megadása (Bejövő/Kimenő számla)</li>
                    <li>Számlák keltezése</li>
                    <li>Grafikonon egyes kategóriák megjelenítése</li>
                    <li>Számlák részletfizetésének opciója</li>
                </ul>
            )}
            {addVersion("v1.2.4", "2024-10-30",
                <ul>
                    <li>Számlákhoz projekt illetve jármű hozzárendelése</li>
                    <li>Számla teljesítéssel kapcsolatos részletek felugró ablak csak készpénz és bankkártya esetén, egyébként a bejelentkezett felhasználó a teljesítő</li>
                    <li>Bevételi számla esetén a "Teljesítette" mező nem kötelező</li>
                    <li>Számlák listázása a járművek alatt</li>
                </ul>
            )}
            {addVersion("v1.2.3", "2024-10-28",
                <ul>
                    <li>Számlán tetszőleges ÁFA bevitel, lehet nem egész szám is</li>
                    <li>ÁFA tartozás kiszámolása a Számlák oldalon</li>
                    <li>Eszközök leírásában minden link kattintható</li>
                    <li>Eszközök szűrése név és leírás alapján</li>
                    <li>Eszközök módosítás dátuma alapján csökkenő sorrendbe rendezése</li>
                    <li>Előzmény lista elemeinek számának megemelése 10-re, duplikációk elkerülése</li>
                </ul>
            )}
            {addVersion("v1.2.2", "2024-10-25",
                <ul>
                    <li>Számlákhoz grafikus statisztika</li>
                    <li>Statisztika táblázat</li>
                    <li>Számlák teljesítéséhez felugró ablak, adatok megadásához</li>
                    <li>Számlák teljesítettségének megadása létrehozáskor is</li>
                    <li>Címzettek listázása, további adatok automatikus kitöltése</li>
                </ul>
            )}
            {addVersion("v1.2.1", "2024-10-15",
                <ul>
                    <li>Számlák kezelése</li>
                    <ul>
                        <li>Számla hozzáadása, módosítása, törlése</li>
                        <li>Számlák közötti keresés név alapján</li>
                        <li>Checkbox: alapértelmezetten csak fizetetlen számlák mutatása</li>
                        <li>Számlák rendezése határidő szerinti növekvő sorrendben</li>
                    </ul>
                </ul>
            )}
            {addVersion("v1.2.0", "2024-10-14",
                <ul>
                    <li>Projekt feladat struktúra átdolgozása</li>
                    <ul>
                        <li>Feladatok státusza és prioritása az alfeladatai alapján</li>
                        <li>Projektek státusza és prioritása a feladat fejlécei alapján</li>
                        <li>Checkbox filterek feladatokhoz és alfeladatokhoz</li>
                        <li>Felugró üzenetek a műveletek sikerességéről</li>
                    </ul>
                </ul>
            )}
            {addVersion("v1.1.22", "2024-10-09",
                <ul>
                    <li>Eszközöknél dokumentumok kezelése</li>
                    <ul>
                        <li>Dokumentumok feltöltése felhőbe</li>
                        <li>Dokumentumok letöltése felhőből</li>
                        <li>Korábban feltöltött dokumentumok törlése</li>
                        <li>Dokumentumok számának megjelenítése az eszközök táblázatban</li>
                        <li>Eszköz törlésekor, dokumentumok törlése a felhőből</li>
                    </ul>
                </ul>
            )}
            {addVersion("v1.1.21", "2024-10-07",
                <ul>
                    <li>Befejezetlen alfeladatok mutatása, checkbox-al állítható</li>
                    <li>Módosítást végző felhasználó megjelenítése a projekt feladaton</li>
                    <li style={{ fontStyle: "italic" }}>Frontend kód refaktorálás</li>
                </ul>
            )}
            {addVersion("v1.1.20", "2024-10-03",
                <ul>
                    <li>Feljéc navigáció minden oldalon</li>
                    <li>Kattinható előzmény menü a főmenü alatt</li>
                    <li>Táblázatok sora kattintható az eddigi szerkesztés/részletek gombok helyett</li>
                </ul>
            )}
            {addVersion("v1.1.19", "2024-10-01",
                <ul>
                    <li>Projekt Feladatok szűrése dátum intervallum alapján</li>
                    <ul>
                        <li>Dátum intervallum a legkorábbi ütemezésre és a legkorábbi határidőre vonatkozik</li>
                        <li>A legkorábbi határidő és ütemezés is csak a nem befejezett alfeladatokra vonatkozik</li>
                        <li>Projekt Feladat fejlécek rendezése legkorábbi határidő VAGY legkorábbi ütemezés szerint</li>
                    </ul>
                </ul>
            )}
            {addVersion("v1.1.18", "2024-09-30",
                <ul>
                    <li>Eszközök kezelése (hozzáadás, törlés, módosítás)</li>
                    <li>Szűrők hátterének módosítása </li>
                </ul>
            )}
            {addVersion("v1.1.17", "2024-09-24",
                <ul>
                    <li>Termékek mozgathatóak projektekbe és projektekből</li>
                    <li>Mozgatáskor termékek listázása táblázatban a kiválasztott cél és forrás raktárakban</li>
                    <li>Feladat fejlécek státuszának és piroritásának automatikus frissítése az alfeladatok állapota alapján</li>
                    <li>Fizetések megjelenítése csak aktív dolgozókról, figyelmeztetés csak fizetetlen aktívakról</li>
                </ul>
            )}
            {addVersion("v1.1.16", "2024-09-16",
                <ul>
                    <li>Projekt feladatok táblázatban alfeladatok számának megjelenítése</li>
                    <li>Projekt feladatokhoz járművek és munkavállalók listázása</li>
                    <li>Projekt feladatok szűrése járművek és munkavállalók alapján</li>
                    <li>Fizetéseknél jelenléti bónusz és SZJA csak akkor ha a dolgozó dolgozott a hónapban</li>
                </ul>
            )}
            {addVersion("v1.1.15", "2024-09-14",
                <ul>
                    <li>Projekt feladatok mentése újként</li>
                    <li>Projekt alfeladatok mentése újként</li>
                    <li>Fizetéseknél csak aktív felhasználók listázása</li>
                </ul>
            )}
            {addVersion("v1.1.14", "2024-09-12",
                <ul>
                    <li>Projekt alfeladatok (hozzáadás, szerkesztés, listázás, törlés)</li>
                    <li>Projekt alfeladat kommentek</li>
                    <li>Elérhető szabadságok számának validációja (0 esetén nem enged többet kivenni)</li>
                </ul>
            )}
            {addVersion("v1.1.13", "2024-09-04",
                <ul>
                    <li>Raktár történetnél mutatja a korábbi és az új mennyiségeket</li>
                    <li>Szabadságok száma újévkor újraindul automatikusan</li>
                    <li>Felhasználók lista szűrése aktivitás alapján</li>
                    <li>Projekt feladat készültség folyamat csík</li>
                </ul>
            )}
            {addVersion("v1.1.12", "2024-09-02",
                <ul>
                    <li>Projekt Feladatok közti keresés</li>
                    <li>Járműhöz kapcsolódó figyelmeztető email-ek</li>
                </ul>
            )}
            {addVersion("v1.1.11", "2024-08-28",
                <ul>
                    <li>Raktár történet befejezése</li>
                    <li>Termék példányok selejtezése</li>
                    <li>Projekt Feladatok (Beállítások {'->'} Projekt Feladatok)</li>
                    <li>Törlés megerősítés dialógusablak</li>
                </ul>
            )}
            {addVersion("v1.1.10", "2024-07-26",
                <ul>
                    <li>Raktár történet</li>
                    <li>Termékek mozgatása</li>
                </ul>
            )}
            {addVersion("v1.1.9", "2024-07-06",
                <ul>
                    <li>Termék ÁFA legördülő menű (27%, 5%, AAM)</li>
                    <li>Termékeknél keresés funkció (név, gyártó, cikkszám alapján)</li>
                </ul>
            )}
            {addVersion("v1.1.8", "2024-06-26",
                <ul>
                    <li>Fizetésekhez 'Kifizetve' opció</li>
                    <li>Felső sávon figyelmeztetés a Kifizetésre várók számáról</li>
                </ul>
            )}
            {addVersion("v1.1.7", "2024-06-19",
                <ul>
                    <li>Fizetés táblázatban ledolgozott munkanapok (legalsó sor)</li>
                    <li>Felső Menüsávban Fontos Projektek Száma </li>
                    <li>Projekt határidő beállítása</li>
                    <li>Projekt prioritás automatikus frissítése és ezekről email értesítés</li>
                </ul>
            )}
            {addVersion("v1.1.6", "2024-06-18",
                <ul>
                    <li>Projekt Becslés Számítás Javítása</li>
                    <li>Projekt Státusz és Prioritás filter a munkaidő nyilvántartásnál</li>
                </ul>
            )}
            {addVersion("v1.1.5", "2024-06-12",
                <ul>
                    <li>Karbantartás státusz hozzáadva</li>
                    <li>Projekt kereső szűrők törlése</li>
                    <li>Projekt lista hossz megjegyzése (Elemek oldalanként)</li>
                    <li>Alapértelmezett Normál címke mentődik projekthez</li>
                    <li>Extra Fontos projektek jelzése a munkaidő bejegyzéseknél</li>
                </ul>
            )}
            {addVersion("v1.1.4", "2024-06-08",
                <ul>
                    <li>Termékek</li>
                    <ul>
                        <li>Honnan és Hova értékek mentődnek</li>
                        <li>Mennyiség pozitív érték kell legyen (lehet nem egész szám is)</li>
                        <li>Árak lehetnek nem egész számok</li>
                        <li>Cikkszám kötelező</li>
                    </ul>
                    <li>Projektek</li>
                    <ul>
                        <li>Státusz és Prioritás hozzáadása</li>
                        <li>Keresés Státusz és Prioritás alapján</li>
                    </ul>
                </ul>
            )}
            {addVersion("v1.1.3", "2024-06-04",
                <ul>
                    <li>Termék Mértékegységnél legördülő menü</li>
                    <li>Azonos nevű és gyártójú termékek hozzáadásának validálása (nincs duplikáció)</li>
                </ul>
            )}
            {addVersion("v1.1.2", "2024-05-30",
                <ul>
                    <li>Sorok felcsérélése funkció munkaidő bejegyzéseknél</li>
                </ul>
            )}
            {addVersion("v1.1.1", "2024-05-29",
                <ul>
                    <li>Külsős Alvállalkozó Fizetési Sablon</li>
                    <ul>
                        <li>Adott felhasználóhoz kiválasztható a beállításokban</li>
                        <li>Fizetés kiszámolása táblázatban</li>
                        <ul>
                            <li>Munkaórák órabér alapján</li>
                            <li>Szállás</li>
                            <li>Jármű vezetés kilométer díj alapján</li>
                        </ul>
                    </ul>
                </ul>
            )}
            {addVersion("v1.1.0", "2024-05-15",
                <ul>
                    <li>Raktár</li>
                    <ul>
                        <li>Termékek hozzáadása</li>
                        <li>Termékek Mozgatása</li>
                        <li>Termékek Adatainak Szerkesztése</li>
                        <li>Termékek és Termék Elemek Listázása</li>
                    </ul>
                    <li style={{ fontStyle: "italic" }}>Automatikus Napi Adatbázis Mentés Készítés</li>
                </ul>
            )}
            {addVersion("v1.0.8", "2024-04-30",
                <ul>
                    <li>Pénzügyi Statisztika Diagram Projektekhez</li>
                    <li style={{ fontStyle: "italic" }}>Integrációs Tesztek
                        <ul>
                            <li>Authentikáció</li>
                            <li>Projekt Műveletek</li>
                            <li>Felhasználó Műveletek</li>
                        </ul>
                    </li>
                </ul>
            )}
            {addVersion("v1.0.7", "2024-04-15",
                <ul>
                    <li>Kategória 4 és 5
                        <ul>
                            <li>Feladatkörként Hozzáadva</li>
                            <li>Projekt Költségnél Megjelenítve</li>

                        </ul>
                    </li>
                    <li>Projekt Név Alapú Keresés</li>
                </ul>
            )}
            {addVersion("v1.0.6", "2024-04-10",

                <ul>
                    <li>Maradék Szabadság
                        <ul>
                            <li>Felhasználó Beállításainál</li>
                            <li>Fizetés Táblázatban</li>
                            <li>Automatikus Kezelés Munkaidőbejegyzések Alapján</li>
                        </ul>
                    </li>
                    <li style={{ fontStyle: "italic" }}>Hibajavítás projekt költség számolásban</li>
                </ul>

            )}
            {addVersion("v1.0.5", "2024-04-09",
                <ul>
                    <li>Projekt Részletek
                        <ul>
                            <li>Munka Órák</li>
                            <li>Vállalási Költség Összesítés</li>
                            <li>Projekt Ár Becslés</li>
                            <li>Munkatíposonkénti Készültségi Szint</li>
                            <li>Összesített Készültségi Szint</li>
                            <li>Utazással Töltött Idő Szétbontása</li>
                        </ul>
                    </li>
                </ul>
            )}
            {addVersion("v1.0.4", "2024-04-02",
                <ul>
                    <li>Projekt Pénzügyi Részletek
                        <ul>
                            <li>Árak, Díjak szerkesztése</li>
                            <li>Pénzügyi Összegzések</li>
                            <li>Projekt Vállalási Költsége</li>
                            <li>Projekt Önköltsége</li>
                        </ul>
                    </li>
                    <li>Fizetett Távollét hozzáadása a fizetéshez</li>
                    <li>Effektív Órabérek</li>
                    <li>Átutalt és Készpénzes kifizetés</li>
                </ul>
            )}
            {
                addVersion("v1.0.3", "2024-03-05",
                    <ul>
                        <li>Feladatkörhöz fizetési kategória
                            <ul>
                                <li>Fizetési kategória hozzáadása, szerkesztése</li>
                                <li>Fizetési kategória megjelenítése a lista oldalon</li>
                            </ul>
                        </li>
                        <li>Raktár Kezelés (csak piszkozat)
                            <ul>
                                <li>Új Termék Hozzáadása Bevételezéssel</li>
                                <li>Meglévő Termék Hozzáadása Bevételezéssel</li>
                                <li>Termékek Listázása a Termékek menüpontban (csak elkezdve)</li>
                            </ul>
                        </li>
                    </ul>
                )
            }
            {
                addVersion("v1.0.2", "2024-02-28",
                    <ul>
                        <li>Kompenzációhoz megjegyzés lehetőség</li>
                        <li>Órabér alapú fizetési sablonhoz kompenzáció hozzáadásának lehetősége</li>
                    </ul>
                )
            }
            {
                addVersion("v1.0.1", "2024-02-27",
                    <ul>
                        <li>Verziók változtatásainak összefoglalója ezen az oldalon</li>
                        <li>Munkaidő táblázathoz plusz filterek
                            <ul>
                                <li>Projekt alapú szűrés</li>
                                <li>Feladattípus alapú szűrés</li>
                                <li>Órák összegzése 3 kategóriában</li>
                            </ul>
                        </li>
                    </ul>
                )
            }
            {
                addVersion("v1.0.0", "2024-02-26",
                    <>Kiindulási Verzió</>
                )
            }
        </>
    );
}

function addVersion(semver, date, content) {
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: "20px" }}>
            <Typography sx={{ backgroundColor: "gray", color: "white", padding: "5px 15px 5px 15px", fontSize: "24px" }}>
                {semver}
                <span style={{ float: "right" }}>{date}</span>
            </Typography>
            <div style={{ padding: "10px" }}>{content}</div>
        </Paper>)
}

import { TrendingFlat } from "@mui/icons-material";
import { Alert, Autocomplete, Button, Checkbox, CircularProgress, Container, Divider, FormControlLabel, Grid, Link, Paper, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import React from "react"
import { useNavigate } from "react-router-dom";

const storageColumns = [
	{ id: 'productName', label: 'Név' },
	{ id: 'product', subId: 'manufacturer', label: 'Gyártó' },
	{ id: 'product', subId: 'articleNumber', label: 'Cikkszám' },
	{ id: 'amount', label: 'Mennyiség' },
]


export default function ProductMovingTemplate(props) {

	const { fromStorage, toStorage, productItem, storages, changeStorageFrom, changeStorageTo, changeProduct, changeProductSearchText, isNewProduct,
		handleIsNewProductChange, productItems, amount, changeAmount, onNewProductChange, submit, onChangeCheckbox, handleClose, feedbackMessage, open,
		alertType, waitingForServer: waitingForServer, onChangeSelectItem, fromStorageItemsTable,
		handleFromStorageChangePage, handleFromStorageChangeRowsPerPage, toStorageItemsTable, handleToStorageChangePage, handleToStorageChangeRowsPerPage } = props

	const { name, manufacturer, centralStorageLocation, ean, articleNumber, minimumStockInStorage, vtszSzjTeszor, packageManagement, component,
		stockManagement, dataSheetUrl, scoreSheetUrl, comment, purchasePrice, sellingPrice, vat, guarantee, expiryDate, factoryNumber, unitOfMeasurement } = props.newProduct


	const toStorages = storages.filter(st => fromStorage == null || st.id !== fromStorage.id)

	const navigate = useNavigate()

	return (
		<Container component="main">
			<div className="register-paper">
				<form id="login" onSubmit={submit}>
					<Grid container spacing={2} columns={24}>

						<Grid item md={10} xs={24}>
							<Autocomplete
								value={fromStorage}
								margin="normal"
								id="fromStorage"
								label="Honnan"
								name="fromStorage"
								onChange={(event, newValue) => changeStorageFrom(newValue)}
								options={[{ name: "Bevételezés" }, ...storages]}
								getOptionLabel={(storage) => storage.name == null ? storage.project.name : storage.name}
								style={{ marginTop: "16px" }}
								renderInput={(params) => (
									<TextField {...params} label="Honnan" required />
								)}
							/>
						</Grid>

						<Grid item md={4} xs={24}>
							<TrendingFlat sx={{ height: "150px", fontSize: "10em", marginTop: "-30px", marginBottom: "-60px" }} fontSize="large" />
						</Grid>

						<Grid item md={10} xs={24}>
							<Autocomplete
								value={toStorage}
								margin="normal"
								id="toStorage"
								label="Hova"
								name="toStorage"
								onChange={(event, newValue) => changeStorageTo(newValue)}
								options={[...toStorages, ...(fromStorage?.name !== "Bevételezés" ? [{ name: "Selejtezés" }] : [])]}
								getOptionLabel={(storage) => storage.name == null ? storage.project.name : storage.name}
								style={{ marginTop: "16px" }}
								renderInput={(params) => (
									<TextField {...params} label="Hova" required />
								)}
							/>
						</Grid>

						<Grid item xs={24}>
							<Divider sx={{ borderWidth: "1px", borderColor: "darkgray" }} />
						</Grid>

						<Grid item md={4} xs={24}>
							<FormControlLabel sx={{ marginTop: "24px" }} control={<Switch defaultChecked />} label="Ez egy új Termék" checked={isNewProduct} onChange={handleIsNewProductChange} disabled={fromStorage && fromStorage.name !== 'Bevételezés'} />
						</Grid>

						<Grid item md={16} xs={24}>
							<Autocomplete
								value={productItem}
								margin="normal"
								id="productItem"
								label="Termék Kereső"
								name="productItem"
								onInputChange={(e) => changeProductSearchText(e == null ? "" : e.target.value)}
								onChange={(event, newValue) => changeProduct(newValue)}
								onBlur={(e) => changeProductSearchText("")}
								options={productItems}
								getOptionLabel={(item) => (item.subStorage.name || item.subStorage.project.name) + " | " + item.amount + (item.product.unitOfMeasurement ? " " + item.product.unitOfMeasurement : "") + " | " + item.product.name + " | " + item.product.manufacturer + " | " + item.updatedAt + " | "}
								style={{ marginTop: "16px" }}
								renderInput={(params) => (
									<TextField {...params} label="Termék Kereső" />
								)}
								disabled={isNewProduct || !fromStorage}
							/>
						</Grid>

						<Grid item md={4} xs={24}>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="amount"
								label="Mennyiség"
								name="amount"
								type="number"
								onChange={changeAmount}
								value={amount}
							/>
						</Grid>

						{(isNewProduct || productItem) &&
							<>
								{createTextField("name", name, "Megnevezés", onNewProductChange, !isNewProduct, true, 12)}
								{createTextField("manufacturer", manufacturer, "Gyártó", onNewProductChange, !isNewProduct, true, 12)}
								{createTextField("centralStorageLocation", centralStorageLocation, "Központi Raktár Hely", onNewProductChange, !isNewProduct)}
								{createTextField("ean", ean, "EAN", onNewProductChange, !isNewProduct)}
								{createTextField("articleNumber", articleNumber, "Cikkszám", onNewProductChange, !isNewProduct, true)}
								{createTextField("vtszSzjTeszor", vtszSzjTeszor, "Vtsz/Szj/TESZOR", onNewProductChange, !isNewProduct)}

								<Grid item md={6} xs={24} sx={{ padding: "0px" }}>
									<Autocomplete
										value={unitOfMeasurement}
										margin="normal"
										id="unitOfMeasurement"
										label="Mértékegység"
										name="unitOfMeasurement"
										freeSolo
										autoSelect
										onChange={(event, newValue) => onChangeSelectItem(newValue, "unitOfMeasurement")}
										disabled={!isNewProduct}
										options={["méter", "db", "tétel"]}
										getOptionLabel={(unitOfMeasurement) => unitOfMeasurement}
										renderInput={(params) => (
											<TextField {...params} label="Mértékegység" />
										)}
									/>
								</Grid>

								{createTextField("component", component, "Komponensek", onNewProductChange, !isNewProduct)}

								<Grid item md={6} xs={24} sx={{ padding: "0px", textAlign: "left" }}>
									<FormControlLabel control={<Checkbox
										variant="outlined"
										margin="normal"
										fullWidth
										size="large"
										id="stockManagement"
										label="stockManagement"
										name="stockManagement"
										onChange={onChangeCheckbox}
										checked={stockManagement}
										value={stockManagement}
										disabled={!isNewProduct}
									/>} label="Készletkezelés" />
								</Grid>
								{createTextField("dataSheetUrl", dataSheetUrl, "Adatlap URL", onNewProductChange, !isNewProduct)}
								{createTextField("scoreSheetUrl", scoreSheetUrl, "Teljesítménylap URL", onNewProductChange, !isNewProduct)}
								{createTextField("comment", comment, "Megjegyzés", onNewProductChange, !isNewProduct)}

								<Grid item xs={24}>
									<Divider sx={{ borderWidth: "1px", borderColor: "darkgray" }} />
								</Grid>


								{createTextField("purchasePrice", purchasePrice, "Vásárlási Ár", onNewProductChange, false, false, 6, "number")}
								{createTextField("sellingPrice", sellingPrice, "Eladási Ár", onNewProductChange, false, false, 6, "number")}

								<Grid item md={6} xs={24} sx={{ padding: "0px" }}>
									<Autocomplete
										value={vat}
										margin="normal"
										id="vat"
										label="ÁFA"
										name="vat"
										freeSolo
										autoSelect
										onChange={(event, newValue) => onChangeSelectItem(newValue, "vat")}
										options={["27%", "5%", "AAM"]}
										getOptionLabel={(vat) => vat}
										renderInput={(params) => (
											<TextField {...params} label="ÁFA" />
										)}
									/>
								</Grid>


								{createTextField("minimumStockInStorage", minimumStockInStorage, "Raktárankénti Minimum Készlet", onNewProductChange)}
								{createTextField("packageManagement", packageManagement, "Kiszerelés kezelése", onNewProductChange)}
								{createTextField("guarantee", guarantee, "Garancia", onNewProductChange, false, false, 6, "date")}
								{createTextField("expiryDate", expiryDate, "Szavatosság Lejárta", onNewProductChange, false, false, 6, "date")}
								{createTextField("factoryNumber", factoryNumber, "Egyedi Gyári Szám", onNewProductChange)}

								<Grid item xs={24}></Grid>

								<Grid item lg={21} md={18}></Grid>

								<Grid item lg={3} md={6} xs={10}>
									{waitingForServer ? <CircularProgress /> : <Button type="submit" fullWidth variant="contained" color="success">
										Mentés
									</Button>}
								</Grid>
							</>
						}

					</Grid>
				</form>
			</div>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert elevation={10} onClose={handleClose} severity={alertType} sx={{ width: '100%' }} variant='filled'>
					{feedbackMessage}
				</Alert>
			</Snackbar>
			<Grid container columns={2} spacing={2} sx={{ marginTop: "10px" }}>
				<Grid item lg={1}>

					{fromStorage && fromStorage.id && <Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer style={{ maxHeight: "calc(100vh - 230px)" }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
								<TableRow>
										<TableCell colSpan={4} style={{ fontWeight: 700, background: "lightgray", textAlign: "center", padding: "8px", fontSize: "20px" }}>
											{fromStorage.name} termékei
										</TableCell>
									</TableRow>
									<TableRow >
										{storageColumns.map((column) => (
											<TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray", padding: "0px 16px"  }}>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{fromStorageItemsTable.rows && fromStorageItemsTable.rows.map((row) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
												{storageColumns.map((column) => {
													let value = column.subId != null ? row[column.id][column.subId] : row[column.id];
													if (column.id === 'amount')
														value = row.amount + ' ' + (row.product.unitOfMeasurement || "")
													else if (column.id === 'productName')
														value = <Link onClick={() => navigate("/dashboard/storage/item/" + row.id)} sx={{ fontWeight: "bold", textDecoration: "none", textAlign: "left" }} component="button">
															{row.product.name}
														</Link>
													return (
														<TableCell key={column.id} align={column.align}>
															{value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[3, 10, 25, 50]}
							component="div"
							count={fromStorageItemsTable.totalCount}
							rowsPerPage={fromStorageItemsTable.rowsPerPage}
							page={fromStorageItemsTable.page}
							onPageChange={handleFromStorageChangePage}
							onRowsPerPageChange={handleFromStorageChangeRowsPerPage}
							labelRowsPerPage="Elemek oldalanként:"
						/>
					</Paper>}

				</Grid>

				<Grid item lg={1}>

					{toStorage && toStorage.id && <Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer style={{ maxHeight: "calc(100vh - 230px)" }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell colSpan={4} style={{ fontWeight: 700, background: "lightgray", textAlign: "center", padding: "8px", fontSize: "20px" }}>
											{toStorage.name} termékei
										</TableCell>
									</TableRow>
									<TableRow >
										{storageColumns.map((column) => (
											<TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray", padding: "0px 16px" }}>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{toStorageItemsTable.rows && toStorageItemsTable.rows.map((row) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
												{storageColumns.map((column) => {
													let value = column.subId != null ? row[column.id][column.subId] : row[column.id];
													if (column.id === 'amount')
														value = row.amount + ' ' + (row.product.unitOfMeasurement || "")
													else if (column.id === 'productName')
														value = <Link onClick={() => navigate("/dashboard/storage/item/" + row.id)} sx={{ fontWeight: "bold", textDecoration: "none", textAlign: "left" }} component="button">
															{row.product.name}
														</Link>
													return (
														<TableCell key={column.id} align={column.align}>
															{value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[3, 10, 25, 50]}
							component="div"
							count={toStorageItemsTable.totalCount}
							rowsPerPage={toStorageItemsTable.rowsPerPage}
							page={toStorageItemsTable.page}
							onPageChange={handleToStorageChangePage}
							onRowsPerPageChange={handleToStorageChangeRowsPerPage}
							labelRowsPerPage="Elemek oldalanként:"
						/>
					</Paper>}

				</Grid>

			</Grid>
		</Container>
	)
}

function createTextField(name, value, title, onNewProductChange, disabled = false, required = false, width = 6, type = "text") {
	return (<Grid item md={width} xs={24} sx={{ padding: "0px" }}>
		<TextField
			variant="outlined"
			margin="normal"
			fullWidth
			id={name}
			label={title}
			autoComplete="off"
			name={name}
			type={type}
			onChange={onNewProductChange}
			value={(value == null && type === "date") ? "-1" : value}
			required={required}
			disabled={disabled}
			sx={{ margin: "0px" }}
		/>
	</Grid>)
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toolService from '../../services/ToolService';
import ToolEditorTemplate from './ToolEditorTemplate';
import { useTitle } from '../dashboard/TitleContext';
import { useSnackbar } from '../../util/SnackbarProvider';
import { API_URL } from '../../App';
import axios from 'axios';
import authService from '../../services/AuthService';
import { saveAs } from 'file-saver';

const ToolEditor = () => {

  const id = window.location.pathname.split('/')[3] === 'create' ? undefined : window.location.pathname.split('/')[3]

  useTitle([
    { name: 'Eszköz Lista', url: '/dashboard/tools' },
    { name: 'Eszköz', url: '/dashboard/tools/' + (id || 'create') },
  ])

  const [tool, setTool] = useState({});
  const [message, setMessage] = useState(null);
  const [waitingForServer, setWaitingForServer] = useState(false)
  const { sendAlertMessage } = useSnackbar()
  const navigate = useNavigate()
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeDocumentName = (nameToRemove) => {
    setTool((prevTool) => ({
      ...prevTool,
      documentNames: prevTool.documentNames.filter(name => name !== nameToRemove)
    }));
  };

  useEffect(() => {
    if (id) {
      toolService.findById(id, (response, error) => {
        if (response) setTool(response);
        else sendAlertMessage('Eszköz lekérése sikertelen', 'error')
      });
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setTool((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const create = async (e) => {
    e.preventDefault();
    setWaitingForServer(true)
    const formData = new FormData();
    formData.append('request', JSON.stringify(tool));
    files.forEach((file) => {
      formData.append('files', file);
    });

    const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
    try {
      const response = await axios.post(API_URL + '/tools', formData, { headers: headers });
      if (response) {
        navigate('/dashboard/tools');
        sendAlertMessage('Eszköz sikeresen hozzáadva')
      }
    } catch (error) {
      sendAlertMessage('Eszköz létrehozása sikertelen', 'error')
    }
    setWaitingForServer(false)
  };

  const update = async (e) => {
    e.preventDefault();
    setWaitingForServer(true)
    const formData = new FormData();
    formData.append('request', JSON.stringify(tool));
    files.forEach((file) => {
      formData.append('files', file);
    });

    const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
    try {
      const response = await axios.patch(API_URL + '/tools/' + id, formData, { headers: headers });
      if (response) {
        navigate('/dashboard/tools');
        sendAlertMessage('Eszköz sikeresen mentve')
      }
    } catch (error) {
      sendAlertMessage('Eszköz mentése sikertelen', 'error')
    }
    setWaitingForServer(false)
  };

  const download = async (fileName) => {
    const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
    axios({
      url: API_URL + "/tools/" + id + "/document",
      params: { fileName: fileName },
      method: 'GET',
      responseType: 'blob',
      headers: headers,
    })
      .then(response => {
        saveAs(response.data, fileName)
        sendAlertMessage('Fájl sikeresen letöltve')
      })
      .catch(error => {
        console.error(error)
        sendAlertMessage('Fájl letöltése sikertelen', 'error')
      });
  }

  return (
    <ToolEditorTemplate
      {...{ id, tool, message, onChange, create, update, navigate, waitingForServer, files, handleFileChange, handleRemoveFile, download, removeDocumentName }}
    />
  );
};

export default ToolEditor;

import React, { useState, useEffect } from 'react';
import TaskLogEditorTemplate from './TaskLogEditorTemplate';
import tasklogService from '../../services/TaskLogService';
import { useNavigate } from 'react-router-dom';
import userService from '../../services/UserService';
import taskTypeService from '../../services/TaskTypeService';
import projectService from '../../services/ProjectService';
import authService from '../../services/AuthService';
import vehicleService from '../../services/VehicleService';
import { travellingTasks } from '../../util/Const';
import { useTitle } from '../dashboard/TitleContext';

const TaskLogEditor = () => {

    const [message, setMessage] = useState('');
    const [waitingForServer, setWaitingForServer] = useState(false)

    const navigate = useNavigate()

    const [id] = useState(
        window.location.pathname.split('/')[3] === 'create'
            ? undefined
            : window.location.pathname.split('/')[3]
    );

    useTitle([
        {name: 'Munkaidő', url: '/dashboard/task-logs'},
        {name: 'Bejegyzés', url: '/dashboard/task-logs/' + (id || 'create') }
    ])

    const [tasklog, setTaskLog] = useState(id !== undefined ? {}
        :
        {
            user: {
                id: authService.getCurrentUser().userId,
                name: authService.getCurrentUser().username
            },
            date: new Date().toISOString().split('T')[0],
            taskLogItems: [{ startTime: "06:00" }]
        });

    const [users, setUsers] = useState([]);
    useEffect(() => {
        userService.search({}, (response, error) => {
            if (response) setUsers(response.content);
            else {
                console.log(error);
            }
        });
    }, []);

    const [taskTypes, setTaskTypes] = useState([]);
    useEffect(() => {
        taskTypeService.search(
            (response, error) => {
                if (response) setTaskTypes(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    const [projects, setProjects] = useState([]);
    useEffect(() => {
        projectService.getSelectors(
            (response, error) => {
                if (response) setProjects(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    const [vehicles, setVehicles] = useState([]);
    useEffect(() => {
        vehicleService.search(
            (response, error) => {
                if (response) setVehicles(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);

    useEffect(() => {
        if (id) tasklogService.findById(id, (response, error) => {
            if (response) setTaskLog((prevTaskLog) => ({
                ...prevTaskLog,
                ...response
            }))
            else console.log(error);
        });
    }, [id, users, projects, vehicles, taskTypes]);


    const onChange = (event) => {
        const { name, value } = event.target;
        setTaskLog((prevTaskLog) => ({
            ...prevTaskLog,
            [name]: value,
        }));
    };

    const onChangeCheckbox = (event) => {
        const { checked, name } = event.target;
        setTaskLog((prevTaskLog) => ({
            ...prevTaskLog,
            [name]: checked,
        }));
    };

    const onUserChange = (newValue) => {
        setTaskLog((prevTaskLog) => ({
            ...prevTaskLog,
            user: newValue,
        }));
    };

    const addRow = (lastRow) => {
        setTaskLog((prevTaskLog) => {
            return {
                ...prevTaskLog,
                taskLogItems: [...prevTaskLog.taskLogItems, {
                    startTime: lastRow ? getNextTime(lastRow.startTime) : "06:00",
                    project: lastRow ? lastRow.project : undefined
                }]
            };
        });
    };

    const getNextTime = (time) => {
        let hours = parseInt(time.split(':')[0]) + 3
        hours = hours >= 23 ? 23 : hours
        return (hours < 10 ? "0" + hours : "" + hours) + ":00"
    }

    const removeLastRow = () => {
        setTaskLog((prevTaskLog) => {
            const updatedTaskLogItems = [...prevTaskLog.taskLogItems.slice(0, -1)];
            return {
                ...prevTaskLog,
                taskLogItems: updatedTaskLogItems
            };
        });
    };

    const onChangeItem = (event, itemIndex) => {
        const { value, name } = event.target;

        setTaskLog((prevTaskLog) => {
            const updatedTaskLogItems = prevTaskLog.taskLogItems.map((item, index) => {
                if (index === itemIndex) {
                    return { ...item, [name]: value };
                }
                return item;
            });

            return {
                ...prevTaskLog,
                taskLogItems: updatedTaskLogItems,
            };
        });
    };

    const onChangeCheckboxItem = (event, itemIndex) => {
        const { checked, name } = event.target;

        setTaskLog((prevTaskLog) => {
            const updatedTaskLogItems = prevTaskLog.taskLogItems.map((item, index) => {
                if (index === itemIndex) {
                    return { ...item, [name]: checked };
                }
                return item;
            });

            return {
                ...prevTaskLog,
                taskLogItems: updatedTaskLogItems,
            };
        });
    };

    const onChangeSelectItem = (itemIndex, newValue, field) => {
        console.log(itemIndex, newValue, field)
        if (field === 'taskType' &&
            itemIndex === tasklog.taskLogItems.length - 1 &&
            newValue !== null && newValue !== undefined &&
            newValue.name !== "Szállás") {
            addRow(tasklog.taskLogItems[tasklog.taskLogItems.length - 1])
        }

        if (field === 'taskType' && travellingTasks.includes(newValue.name)) {
            const firstTaskLogWithVehicle = tasklog.taskLogItems.find(item => item.vehicle !== undefined);
            tasklog.taskLogItems[itemIndex].vehicle = firstTaskLogWithVehicle?.vehicle
        }

        setTaskLog((prevTaskLog) => {
            const updatedTaskLogItems = prevTaskLog.taskLogItems.map((item, index) => {
                if (index === itemIndex) {
                    return { ...item, [field]: newValue };
                }
                return item;
            });

            return {
                ...prevTaskLog,
                taskLogItems: updatedTaskLogItems,
            };
        });
    };

    const create = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        prepareRequest();
        tasklogService.createTaskLog(tasklog, (response, error) => {
            if (!error) {
                navigate('/dashboard/task-logs');
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const update = (e) => {
        e.preventDefault();
        setWaitingForServer(true)
        prepareRequest();
        console.log(tasklog)
        tasklogService.updateTaskLog(id, tasklog, (response, error) => {
            if (!error) {
                navigate('/dashboard/task-logs');
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
            setWaitingForServer(false)
        });
    };

    const prepareRequest = () => {
        tasklog.userId = tasklog.user.id
        tasklog.taskLogItems.forEach(taskLogItem => {
            taskLogItem.projectId = taskLogItem.project.id
            taskLogItem.taskTypeId = taskLogItem.taskType?.id ?? null;
            taskLogItem.vehicleId = taskLogItem.vehicle?.id ?? null;
        });
    }

    const swapRecords = (index, otherIndex) => {
        setTaskLog(prevTasklog => {
            const newTaskLogItems = prevTasklog.taskLogItems;
            const temp = newTaskLogItems[index];
            newTaskLogItems[index] = newTaskLogItems[otherIndex];
            newTaskLogItems[otherIndex] = temp;

            return {
                ...prevTasklog,
                taskLogItems: newTaskLogItems
            };
        });
    };

    return (
        <TaskLogEditorTemplate
            data={{
                id,
                tasklog,
                message,
                users,
                projects,
                taskTypes,
                vehicles,
                addRow,
                removeLastRow,
                onChange,
                onChangeItem,
                onChangeCheckbox,
                onChangeCheckboxItem,
                onChangeSelectItem,
                onUserChange,
                swapRecords,
                create,
                update,
                waitingForServer
            }}
        />
    );
};

export default TaskLogEditor;

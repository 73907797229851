import Service from "./Service"

class InvoiceService extends Service {

    getAllInvoices = (callback) => this.get("/invoices", callback)

    getInvoices = (taskLogItemId, queryParams, callback) => this.getWithParams("/task-log-items/" + taskLogItemId + "/invoices", queryParams, callback)

    createInvoice = (taskLogItemId, invoiceFile, callback) => this.postFile("/task-log-items/" + taskLogItemId + "/invoices", invoiceFile, callback)

    archiveInvoice = (invoiceId, archive, callback) => this.patch("/invoices/" + invoiceId, { archive: archive }, callback)

    deleteInvoice = (invoiceId, callback) => this.del("/invoices/" + invoiceId, callback)
}
const invoiceService = new InvoiceService()
export default invoiceService
import Service from "./Service"

class SimpleInvoiceService extends Service {

    findById = (id, callback) => this.get("/simple-invoices/" + id, callback)

    findAll = (queryParams, callback) => this.getWithParams("/simple-invoices", queryParams, callback)

    getSenderOptions = (callback) => this.get("/simple-invoices/senders", callback)

    getReceiverOptions = (callback) => this.get("/simple-invoices/receivers", callback)

    createSimpleInvoice = (simpleInvoiceRequest, callback) => this.post("/simple-invoices", simpleInvoiceRequest, callback)

    updateSimpleInvoice = (simpleInvoiceId, simpleInvoiceRequest, callback) => this.patch("/simple-invoices/" + simpleInvoiceId, simpleInvoiceRequest, callback)

    togglePaid = (id, request, callback) => this.patch("/simple-invoices/" + id + "/payment", request, callback)

    deleteSimpleInvoice = (simpleInvoiceId, callback) => this.del("/simple-invoices/" + simpleInvoiceId, callback)
}
const simpleInvoiceService = new SimpleInvoiceService()
export default simpleInvoiceService
import { Button, Container, TextField, Alert, Grid, Divider, CircularProgress } from "@mui/material"
import React from "react"

export default function GlobalPaymentModifierEditorTemplate(props) {

	const createInputField = ({ name, label, required = false, autoFocus = false, disabled = false }) =>
		<Grid item lg={5} md={10} xs={20}>
			<TextField
				variant="outlined"
				margin="normal"
				required={required}
				fullWidth
				id={name}
				label={label}
				name={name}
				type="text"
				autoFocus={autoFocus}
				autoComplete="off"
				onChange={props.onChange}
				value={props.globalPaymentModifier[name] || ""}
				disabled={disabled}
			/>
		</Grid>

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={props.id ? props.update : props.create}>
						<Grid container spacing={2} columns={20}>
							{props.id != null && props.globalPaymentModifier == null ? <Grid item xs={20}><CircularProgress sx={{ margin: "100px" }} /> </Grid> :
								<>
									{createInputField({
										name: "name",
										label: "Szöveges Azonosító",
										disabled: true
									})}
									{createInputField({
										name: "label",
										label: "Megnevezés",
										autoFocus: true
									})}
									{createInputField({
										name: "value",
										label: "Érték",
									})}

									{props.id !== undefined &&
										<>
											<Grid item lg={20} md={20} xs={20}>
												<Divider />
											</Grid>

											{createInputField({
												name: "createdAt",
												label: "Létrehozva",
												disabled: true
											})}
											{createInputField({
												name: "updatedAt",
												label: "Módosítva",
												disabled: true
											})}

											<Grid item lg={10} xs={20}></Grid>
										</>
									}

									{props.message &&
										<Grid item lg={20} xs={20}>
											<Alert severity="error" variant="standard" style={{ color: "red" }}>{props.message}</Alert>
										</Grid>
									}

									<Grid item lg={2} md={5} xs={9}>
										<Button type="button" fullWidth variant="contained" color="error" onClick={() => props.navigate(-1)}>
											Vissza
										</Button>
									</Grid>

									<Grid item lg={16} md={10} xs={2}></Grid>

									<Grid item lg={2} md={5} xs={9}>
										{props.waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
											Mentés
										</Button>}
									</Grid>
								</>}
						</Grid>
					</form>
				</div>
			</Container>
		</div>
	)
}